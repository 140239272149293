import { Loader, Skeleton } from "@progress/kendo-react-indicators";
import React, { useRef } from "react";
import { useEffect, useState } from "react";
import ContentPlaceholder from "../../components/ContentPlaceholder";
import { RecordingTranscription } from "../../types/recording";
import { SpeakerTranscriptInfo } from "../../types/transcription";
import { CombinedTranscription } from "../../types/transcription/CombinedTranscription";
import { TinyUser } from "../../types/user";
import { formatDateTime, formatSecondsToTime } from "../../utils/dateTimeUtils";
import { processRawTranscript } from "../../utils/transcriptUtils";
import { RecordingTrackSegment } from "../../types/player-track";
import { combineTranscriptSentiment } from "../../utils/transcriptSentimentUtil";

interface ITranscriptionProps {
  rawTranscript?: RecordingTranscription;
  rawTranscriptError?: string;
  sentimentsTrackInsight?: RecordingTrackSegment[];
  playbackProgress: number;
  secondParty?: TinyUser;
  callDatetime?: string;
  isAIAnalysed?: boolean;
  shared?: boolean;
  fetchLabelKeyTranslation: (key: string, defaultVal: string) => string;
  translationsLoading: boolean;
}

const Transcription: React.FC<ITranscriptionProps> = ({
  rawTranscript,
  rawTranscriptError,
  sentimentsTrackInsight,
  playbackProgress,
  secondParty,
  callDatetime,
  isAIAnalysed,
  shared,
  translationsLoading,
  fetchLabelKeyTranslation,
}) => {
  const secondPartyContact = secondParty
    ? secondParty.phoneNo
      ? secondParty.phoneNo
      : secondParty.email
    : "";
  const [transcript, setTranscript] = useState<
    CombinedTranscription[] | undefined
  >(undefined);
  const [activeSpeakerEndtime, setActiveSpeakerEndtime] = useState<number>(0);
  const [activeSpeakerId, setActiveSpeakerId] = useState<number>(-1);
  let refs = useRef<any>(null);

  useEffect(() => {
    if (rawTranscript) {
      const processedTranscript = processRawTranscript(rawTranscript);
      const combinedTranscript = combineTranscriptSentiment(
        processedTranscript,
        sentimentsTrackInsight
      );
      console.log(combinedTranscript);
      setTranscript(combinedTranscript);
      if (combinedTranscript) {
        if (combinedTranscript.length > 0) {
          //setActiveSpeakerEndtime(processedTranscript[0].end);
          refs.current = Array.from(
            { length: combinedTranscript.length },
            (a) => React.createRef()
          );
        }
      }
    }
  }, [rawTranscript]);

  useEffect(() => {
    if (activeSpeakerId > 0)
      refs.current[activeSpeakerId].current?.scrollIntoView();

    const activateSpeaker = () => {
      if (transcript && transcript.length > 0) {
        if (activeSpeakerId >= 0) {
          setTranscript((prev) => {
            return prev?.map((s) => {
              s.active = false;
              return s;
            });
          });
        }

        let activeId = 0;
        let end = transcript[activeId].end;
        let start = transcript[activeId].start;
        for (let i = 0; i < transcript.length; i++) {
          if (transcript[i].end > playbackProgress) {
            activeId = transcript[i].id;
            end = transcript[i].end;
            start = transcript[i].start;
            break;
          }
        }

        if (playbackProgress > start && activeSpeakerId !== activeId) {
          setTranscript((prev) => {
            if (prev) {
              prev[activeId].active = true;
            }
            return prev;
          });
          setActiveSpeakerId(activeId);
          setActiveSpeakerEndtime(end);
        }
      }
    };

    if (playbackProgress > activeSpeakerEndtime) {
      if (
        transcript &&
        transcript.length > 0 &&
        activeSpeakerId === transcript[transcript.length - 1].id
      ) {
        setActiveSpeakerEndtime(Number.MAX_SAFE_INTEGER);
      } else {
        activateSpeaker();
      }
    } else if (
      transcript &&
      activeSpeakerId > 0 &&
      !transcript[activeSpeakerId].active
    ) {
      setTranscript((prev) => {
        if (prev) {
          prev[activeSpeakerId].active = true;
        }
        return prev;
      });
    } else if (
      transcript &&
      activeSpeakerId > 0 &&
      playbackProgress < transcript[activeSpeakerId].start
    ) {
      activateSpeaker();
    }
  }, [playbackProgress, transcript, activeSpeakerId, activeSpeakerEndtime]);

  return isAIAnalysed ? (
    <div className="row">
      <div className="col-md-12">
        <div className="call-transcript">
          <div className="d-flex ">
            {secondParty && (
              <span className="header d-flex align-items-center">
                <span className="fs-15 font-weight-bold">
                  {`${
                    translationsLoading
                      ? "Remote Party"
                      : fetchLabelKeyTranslation(
                          "TextRemoteParty",
                          "Remote Party"
                        )
                  }`}
                  :&nbsp;
                </span>
                <span className="fs-15">{secondPartyContact}</span>{" "}
                <small className="ml-2 fs-13 text-black-8">
                  {callDatetime
                    ? formatDateTime(callDatetime, "MMM DD, YYYY, HH:mm")
                    : ""}
                </small>
              </span>
            )}
            {!secondParty && (
              <Skeleton shape={"text"} style={{ width: 246, height: 22 }} />
            )}
          </div>
          <div className="text-center p-t-10">
            <div className="row user-view">
              <div className="col-md-12">
                <div
                  className="transcript scroller"
                  style={{
                    maxHeight: "40vh",
                    overflow: "auto",
                  }}
                >
                  <div className="rec-comments p-r-20">
                    {!rawTranscript && !rawTranscriptError && (
                      <ContentPlaceholder>
                        <Loader type={"infinite-spinner"} />
                      </ContentPlaceholder>
                    )}
                    {rawTranscriptError ? (
                      <ContentPlaceholder>
                        {
                          <span>
                            <i className="bi bi-exclamation-triangle-fill tx-amber"></i>{" "}
                            {rawTranscriptError}
                          </span>
                        }
                      </ContentPlaceholder>
                    ) : (
                      <>
                        {!transcript || transcript.length === 0 ? (
                          <ContentPlaceholder>
                            {
                              <span>
                                <i className="bi bi-exclamation-triangle-fill tx-amber"></i>{" "}
                                {`${
                                  translationsLoading
                                    ? "NO TRANSCRIPT AVAILABLE"
                                    : fetchLabelKeyTranslation(
                                        "NoTranscriptMssg",
                                        "NO TRANSCRIPT AVAILABLE"
                                      )
                                }`}
                              </span>
                            }
                          </ContentPlaceholder>
                        ) : (
                          <>
                            {transcript &&
                              transcript.map((t) => {
                                return (
                                  <div
                                    key={t.id}
                                    ref={refs && refs.current[t.id]}
                                    className={`spkTrk spk1 ${
                                      t.active && "active"
                                    }`}
                                  >
                                    <div
                                      className={t.sentimentCss}
                                      style={{
                                        minWidth: "4px",
                                        marginRight: "10px",
                                      }}
                                    ></div>
                                    <div className="spkTrk-h text-black-12">
                                      {`${
                                        translationsLoading
                                          ? "Participant"
                                          : fetchLabelKeyTranslation(
                                              "TextParticipants",
                                              "Participant"
                                            )
                                      } ${t.speaker + 1}`}
                                    </div>
                                    <div className="spkTrk-txt">
                                      <div className="trans-list p-t-3">
                                        <div className="trans-group d-flex">
                                          <div className="spkTrk-time p-r-20 text-black-8">
                                            {formatSecondsToTime(t.start)}
                                          </div>
                                          <div className="spkTrk-para m-b-15">
                                            {t.text}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="blr-msg">
      <div className="blurMsgInr fs-18 font-weight-semi">
        {`${
          translationsLoading
            ? "Unavailable: AI License Required"
            : fetchLabelKeyTranslation(
                "UpdateLicenseText",
                "Unavailable: AI License Required"
              )
        }`}
      </div>
    </div>
  );
};

export default Transcription;
