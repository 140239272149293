import { useState, useRef, useEffect } from "react";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Popup } from "@progress/kendo-react-popup";
import { TinyUser } from "../../types/user";
import {
  formatUsernameToPascalCase,
  getRandomProfileColor,
  getUserInitials,
} from "../../utils/profileUtils";

interface ICustomUserList {
  userList: TinyUser[];
  maxDisplay?: number | undefined;
}

const CustomUserList: React.FC<ICustomUserList> = ({
  userList,
  maxDisplay,
}) => {
  const [show, setShow] = useState(false);
  const anchor = useRef<any>(null);
  const endIndex = maxDisplay !== undefined && maxDisplay > 0 ? maxDisplay : 4;

  useEffect(() => {
    if (show) {
      const body = document.getElementById("root");
      const bodyEventHandler = () => {
        setShow(false);
      };
      if (body != null) {
        body.addEventListener("click", bodyEventHandler);
      }
      return () => body?.removeEventListener("click", bodyEventHandler);
    }
  }, [show]);

  const handleExpandClick = () => {
    setShow(true);
  };

  return (
    <div className="tblUsr moreUsr users-small mb-2">
      <div className="tblUserRow">
        {userList &&
          userList.length > 0 &&
          userList?.slice(0, endIndex).map((user) => {
            const profileColor = getRandomProfileColor(user?.id);
            const userName = formatUsernameToPascalCase(
              user?.firstName,
              user?.lastName
            ).trim();
            let initials = getUserInitials(user?.firstName, user?.lastName);
            initials =
              initials !== "" ? initials : getUserInitials(user?.email, "");
            return (
              <div className="topUsrAreaPic zoomPic" key={user?.id}>
                <div className="topUsrAreaPic-i">
                  <div
                    className={`contact-list-icon cursor-pointer ${profileColor}`}
                    title={userName === "" ? user.email : userName}
                  >
                    <div className="contact-list-icon-txt">{initials}</div>
                  </div>
                </div>
              </div>
            );
          })}
        {userList && userList.length > 0 && userList.length > endIndex && (
          <div
            className="topUsrAreaPic zoomPic"
            onClick={handleExpandClick}
            ref={anchor}
          >
            <div className="topUsrAreaPic-i">
              <div className="contact-list-icon cursor-pointer bg-darksteelblue">
                <div className="contact-list-icon-txt">
                  +{userList.length - endIndex}
                </div>
              </div>
            </div>
            <Popup
              anchor={anchor.current}
              show={show}
              popupClass={"popup-content"}
            >
              <div
                className="tblUsr moreUsr"
                style={{
                  minHeight: "100px",
                  maxHeight: "130px",
                  overflowY: "scroll",
                  width: "135px",
                  padding: "10px 10px 15px 20px",
                }}
              >
                <div className="tblUserRow">
                  {userList.slice(endIndex, undefined).map((user) => {
                    const profileColor = getRandomProfileColor(user?.id);
                    const userName = formatUsernameToPascalCase(
                      user?.firstName,
                      user?.lastName
                    ).trim();
                    let initials = getUserInitials(
                      user?.firstName,
                      user?.lastName
                    );
                    initials =
                      initials !== ""
                        ? initials
                        : getUserInitials(user?.email, "");
                    return (
                      <div className="topUsrAreaPic zoomPic" key={user?.id}>
                        <div className="topUsrAreaPic-i">
                          <div
                            className={`contact-list-icon cursor-pointer ${profileColor}`}
                            title={userName === "" ? user.email : userName}
                          >
                            <div className="contact-list-icon-txt">
                              {initials}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Popup>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomUserList;
