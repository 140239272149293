import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Loader } from "@progress/kendo-react-indicators";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { AxiosError } from "axios";
import { useRef, useState, useEffect } from "react";
import CustomInput from "../../../../components/custom/form/CustomInput";
import { StorageModes } from "../../../../enums";
import storageService from "../../../../services/storage.service";
import {
  DonutStorageVisualization,
  StorageLocation,
} from "../../../../types/storage";
import useSwal from "../../../../hooks/useSwal";
import { Dictionary } from "../../../../types/Dictionary";
import useLocale from "../../../../hooks/useLocale";
import useBranding from "../../../../hooks/useBranding";
import StorageVisualizationChart from "../StorageVisualizationChart";
import { storageSizetoKB } from "../StorageSettings";

interface SFTPStorageLocationProps {
  data?: StorageLocation;
  configuring: boolean;
  onUserAddStorageToggle: () => void;
  onStorageLocationUpserted: (
    upsertedStorageLocation: StorageLocation,
    updated: boolean
  ) => void;
  onActivateStorageLocation?: (storageLocationId: number) => void;
}

const SFTPStorageLocation: React.FC<SFTPStorageLocationProps> = ({
  data,
  configuring,
  onUserAddStorageToggle,
  onStorageLocationUpserted,
  onActivateStorageLocation,
}) => {
  const brandingCtx = useBranding();
  const formRef = useRef<any>();
  const localeCtx = useLocale();
  const Swal = useSwal();
  const [editing, setEditing] = useState<boolean>(false);
  const [upserting, setUpserting] = useState<boolean>(false);
  const [donutStorage, setDonutStorage] = useState<DonutStorageVisualization[]>(
    [
      {
        field: "Free",
        spacePercentage: undefined,
        spaceInGB: undefined,
        color: "grey",
        legend: "",
      },
      {
        field: "Used",
        spacePercentage: undefined,
        spaceInGB: undefined,
        color: brandingCtx?.branding?.themeColors[0].colorValue ?? "red",
        legend: "",
      },
    ]
  );

  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(
    localeCtx?.selectedLocale?.current.componentTranslations[
      "SFTPStorageLocation"
    ]
  );
  const [buttonStateTracker, setButtonStateTracker] = useState<boolean>(true);
  const [testingConnection, setTestingConnection] = useState<boolean>(false);

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
        "SFTPStorageLocation"
      ]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations(
        "SFTPStorageLocation"
      );
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations[
          "SFTPStorageLocation"
        ]
      );
      localeCtx?.setPreviousAppLocale("SFTPStorageLocation");
      if (localeCtx?.localeSwitchFailed) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        });
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const onUserEditingToggle = () => {
    setEditing((prevState) => !prevState);
    if (configuring) {
      onUserAddStorageToggle();
    }
    const form = formRef.current as Form;
    form.resetForm();
  };

  const onTestConnection = async () => {
    try{
      const form = formRef.current as Form;
      setTestingConnection(true)
      const storageLocation : StorageLocation = {
        id: data?.id ?? 0,
        name: form.valueGetter("name"),
        mode: StorageModes.SFTP,
        isActive: data?.isActive ?? false,
        detail: {
          id: data?.detail.id ?? 0,
          keys: {
            Server: form.valueGetter("server"),
            Port: form.valueGetter("port").toString(),
            Directory: form.valueGetter("directory"),
            UserName: form.valueGetter("username"),
            Password: form.valueGetter("password"),
          },
        },
        totalSizeInBytes: form.valueGetter("totalSizeInBytes") * storageSizetoKB,
      }

      const isSuccessful: boolean = await storageService.testStorageLocation(storageLocation);
      if(isSuccessful){
        Swal.fire({
          icon: "success",
          confirmButtonText: `OK`,
          title: 'Connection successful.'
        });
      }else{
        Swal.fire({
          icon: "error",
          title: "Error",
          text: 'Connection not successful.'
        });
      }
    }catch(error){
      if (error instanceof AxiosError) {
        console.error(error);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: 'Connection not successful due to server error.',
            // text: `${err.message}`,
          });
      }
    }
    finally{
      setTestingConnection(false);
    }
  }

  const onSubmitHandler = async (dataItem: { [name: string]: any }) => {
    try {
      setUpserting(true);
      let upsertedStorageLocation: StorageLocation = {
        id: data?.id ?? 0,
        name: dataItem.name,
        mode: StorageModes.SFTP,
        isActive: false,
        detail: {
          id: data?.detail.id ?? 0,
          keys: {
            Server: dataItem.server,
            Port: dataItem.port.toString(),
            UserName: dataItem.username,
            Password: dataItem.password,
            Directory: dataItem.directory,
          },
        },
        totalSizeInBytes: dataItem.totalSizeInBytes * storageSizetoKB,
      };
      upsertedStorageLocation = await storageService.upsertStorageLocation(
        upsertedStorageLocation
      );
      onStorageLocationUpserted(upsertedStorageLocation, data !== undefined);
      setEditing(false);
      Swal.fire({
        icon: "success",
        confirmButtonText: `OK`,
        title: `SFTP Storage Location ${data ? "Updated" : "Added"}`,
      });
    } catch (err) {
      if (err instanceof AxiosError) {
        console.error(err);
        if(err.response?.status === 406){
          Swal.fire({
            icon: "error",
            title: "Error",
            text: 'Storage with similar details already exists.',
          });
        }
        else{
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${err.message}`,
          });
        }
      }
    } finally {
      setUpserting(false);
    }
  };

  const onUserActivateStorage = () => {
    if (onActivateStorageLocation && data) {
      onActivateStorageLocation(data.id);
    }
  };

  const inputFieldValidator = (value: string | number) => {
    if (!value || typeof value === "string" && value.trim().length === 0) {
      return `${
        translationsLoading
        ? "This field is mandatory."
        : fetchLabelKeyTranslation(
          "EmailServerSettingsValidationMssg",
          "This field is mandatory."
          )
      }`;
    }
    return "";
  };

  const compareState = () => {
    const form = formRef.current as Form;
    if (
      data?.name == form.values.name &&
      data?.totalSizeInBytes ==
        form.values.totalSizeInBytes * storageSizetoKB &&
      data?.detail.keys.Server == form.values.server &&
      data?.detail.keys.Port == form.values.port &&
      data?.detail.keys.UserName == form.values.username &&
      data?.detail.keys.Password == form.values.password &&
      data?.detail.keys.Directory == form.values.directory
    ) {
      setButtonStateTracker(true);
    } else {
      setButtonStateTracker(false);
    }
  };

  return (
    <div className="float-left w-100 p-t-5 p-l-5 p-r-5">
      <div className="visibleDataDiv float-left w-100">
        {data?.id && !editing && (
          <div className="row">
            <div className="col-md-12">
              <div className="float-left w-100 text-right p-t-5">
                {!data.isActive && (
                  <Button
                    className="btn bg-transparent text-black-12 border-primary text-primary m-r-5 p-r-7 p-l-7"
                    style={{ height: "26px" }}
                    onClick={onUserActivateStorage}
                  >
                    <span className="p-r-5">
                      <>
                        {translationsLoading
                          ? "Activate Storage"
                          : fetchLabelKeyTranslation(
                              "ActivateStorage",
                              "Activate Storage"
                            )}
                      </>
                    </span>
                    <i className="bi bi-check2-circle"></i>
                  </Button>
                )}
                <Button
                  className="btn bg-transparent text-black-12 border-primary text-primary m-r-5 p-r-7 p-l-7"
                  style={{ height: "26px" }}
                  onClick={onUserEditingToggle}
                >
                  <span className="p-r-5">
                    <>
                      {translationsLoading
                        ? "Edit"
                        : fetchLabelKeyTranslation("EditButton", "Edit")}
                    </>
                  </span>
                  <i className="bi bi-pencil"></i>
                </Button>
              </div>
            </div>
          </div>
        )}
        <Form
          onSubmit={onSubmitHandler}
          ref={formRef}
          initialValues={{
            name: data?.name,
            server: data?.detail.keys.Server,
            port: data?.detail.keys.Port ? Number(data?.detail.keys.Port) : 0,
            username: data?.detail.keys.UserName,
            password: data?.detail.keys.Password,
            directory: data?.detail.keys.Directory,
            totalSizeInBytes: data?.totalSizeInBytes
              ? (data.totalSizeInBytes / storageSizetoKB).toFixed(2)
              : 0,
          }}
          render={(formRenderProps: FormRenderProps) => (
            <FormElement>
              <div className="row">
                <div className="col-md-5">
                  <div className="trRowData float-left w-100 p-b-5">
                    {(configuring || editing) && (
                      <div className="trk-row">
                        <div className="trkCol d-flex">
                          <div
                            className="trkCol-h font-weight-semi"
                            style={{ minWidth: "150px" }}
                          >
                            <>
                              {translationsLoading
                                ? "Storage Name"
                                : fetchLabelKeyTranslation(
                                    "StorageName",
                                    "Storage Name"
                                  )}
                            </>
                          </div>
                          <div className="trkCol-dot">:</div>
                          <div className="trkCol-p border-none">
                            {!configuring && !editing ? (
                              <span>{data?.name}</span>
                            ) : (
                              <Field
                                className="textInput"
                                id={"name"}
                                name={"name"}
                                value={formRenderProps.valueGetter("name")}
                                required={true}
                                placeholder={"Storage Name..."}
                                component={CustomInput}
                                validator={inputFieldValidator}
                                onChange={compareState}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="trk-row">
                      <div className="trkCol d-flex">
                        <div
                          className="trkCol-h font-weight-semi"
                          style={{ minWidth: "150px" }}
                        >
                          <>
                            {translationsLoading
                              ? "Server"
                              : fetchLabelKeyTranslation("Server", "Server")}
                          </>
                        </div>
                        <div className="trkCol-dot">:</div>
                        <div className="trkCol-p border-none">
                          {!configuring && !editing ? (
                            <span>{data?.detail.keys.Server}</span>
                          ) : (
                            <Field
                              className="textInput"
                              id={"server"}
                              name={"server"}
                              value={formRenderProps.valueGetter("server")}
                              required={true}
                              placeholder={"Server..."}
                              component={CustomInput}
                              validator={inputFieldValidator}
                              onChange={compareState}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="trk-row">
                      <div className="trkCol d-flex">
                        <div
                          className="trkCol-h font-weight-semi"
                          style={{ minWidth: "150px" }}
                        >
                          <>
                            {translationsLoading
                              ? "Port"
                              : fetchLabelKeyTranslation("Port", "Port")}
                          </>
                        </div>
                        <div className="trkCol-dot">:</div>
                        <div className="trkCol-p border-none">
                          {!configuring && !editing ? (
                            <span>{data?.detail.keys.Port}</span>
                          ) : (
                            <Field
                              className="textInput"
                              id={"port"}
                              name={"port"}
                              value={formRenderProps.valueGetter("port")}
                              spinners={false}
                              min={0}
                              required={true}
                              placeholder={"Port..."}
                              component={NumericTextBox}
                              format=""
                              validator={inputFieldValidator}
                              onChange={compareState}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="trk-row">
                      <div className="trkCol d-flex">
                        <div
                          className="trkCol-h font-weight-semi"
                          style={{ minWidth: "150px" }}
                        >
                          <>
                            {translationsLoading
                              ? "Username"
                              : fetchLabelKeyTranslation(
                                  "Username",
                                  "Username"
                                )}
                          </>
                        </div>
                        <div className="trkCol-dot">:</div>
                        <div className="trkCol-p border-none">
                          {!configuring && !editing ? (
                            <span>{data?.detail.keys.UserName}</span>
                          ) : (
                            <Field
                              className="textInput"
                              id={"username"}
                              name={"username"}
                              value={formRenderProps.valueGetter("username")}
                              required={true}
                              placeholder={"Username..."}
                              component={CustomInput}
                              validator={inputFieldValidator}
                              onChange={compareState}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="trk-row">
                      <div className="trkCol d-flex">
                        <div
                          className="trkCol-h font-weight-semi"
                          style={{ minWidth: "150px" }}
                        >
                          <>
                            {translationsLoading
                              ? "Password"
                              : fetchLabelKeyTranslation(
                                  "Password",
                                  "Password"
                                )}
                          </>
                        </div>
                        <div className="trkCol-dot">:</div>
                        <div className="trkCol-p border-none">
                          {!configuring && !editing ? (
                            <span>{`\u25CF`.repeat(20)}</span>
                          ) : (
                            <Field
                              className="textInput"
                              id={"password"}
                              name={"password"}
                              type={"password"}
                              value={formRenderProps.valueGetter("password")}
                              required={true}
                              placeholder={"Password..."}
                              component={CustomInput}
                              validator={inputFieldValidator}
                              onChange={compareState}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="trk-row">
                      <div className="trkCol d-flex">
                        <div
                          className="trkCol-h font-weight-semi"
                          style={{ minWidth: "150px" }}
                        >
                          <>
                            {translationsLoading
                              ? "Directory"
                              : fetchLabelKeyTranslation(
                                  "Directory",
                                  "Directory"
                                )}
                          </>
                        </div>
                        <div className="trkCol-dot">:</div>
                        <div className="trkCol-p border-none">
                          {!configuring && !editing ? (
                            <span>{data?.detail.keys.Directory}</span>
                          ) : (
                            <Field
                              className="textInput"
                              id={"directory"}
                              name={"directory"}
                              value={formRenderProps.valueGetter("directory")}
                              required={true}
                              placeholder={"Directory..."}
                              component={CustomInput}
                              validator={inputFieldValidator}
                              onChange={compareState}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="trk-row">
                      <div className="trkCol d-flex">
                        <div
                          className="trkCol-h font-weight-semi"
                          style={{ minWidth: "150px" }}
                        >
                          <>
                            {translationsLoading
                              ? "Storage Size (GB)"
                              : fetchLabelKeyTranslation(
                                  "StorageSizeTitle",
                                  "Storage Size (GB)"
                                )}
                          </>
                        </div>
                        <div className="trkCol-dot">:</div>
                        <div className="trkCol-p border-none">
                          {!configuring && !editing ? (
                            <span>
                              {!donutStorage.find(
                                (donut: DonutStorageVisualization) =>
                                  donut.field === "Free" &&
                                  donut.spaceInGB === undefined
                              )
                                ? `${
                                    data?.totalSizeInBytes &&
                                    (
                                      data.totalSizeInBytes / storageSizetoKB
                                    ).toFixed(2)
                                  }`
                                : "No storage defined"}
                            </span>
                          ) : (
                            <Field
                              type="number"
                              className="textInput"
                              id={"totalSizeInBytes"}
                              name={"totalSizeInBytes"}
                              value={formRenderProps.valueGetter(
                                "totalSizeInBytes"
                              )}
                              placeholder={"Storage in GB"}
                              component={NumericTextBox}
                              onKeyDown={(evt: any) => {
                                if (
                                  evt.key === "e" ||
                                  evt.key === "+" ||
                                  evt.key === "-"
                                ) {
                                  evt.preventDefault();
                                }
                              }}
                              onChange={compareState}
                              format={"n2"}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <StorageVisualizationChart
                  editing={editing}
                  data={data}
                  donutStorage={donutStorage}
                  setDonutStorage={setDonutStorage}
                />
              </div>
              {(configuring || editing) && (
                    <div className="row">
                      <div className="col-md-12 text-right p-b-5">
                        <div className="btnRightDiv">
                          <Button
                            className="btn bg-black-5 m-r-5"
                            onClick={onUserEditingToggle}
                          >
                            <>
                              {translationsLoading
                                ? "Cancel"
                                : fetchLabelKeyTranslation(
                                    "CancelButton",
                                    "Cancel"
                                  )}
                            </>
                          </Button>
                          <Button
                            type={"button"}
                            className={`btn bg-primary text-white m-r-5 ${
                              !formRenderProps.valid ||
                              testingConnection ? "disabledBtn" : ""
                            }`}
                            disabled={!formRenderProps.valid || testingConnection}
                            onClick={onTestConnection}
                          >
                            {testingConnection ? (
                              <Loader
                                size={"small"}
                                type={"infinite-spinner"}
                                themeColor={"light"}
                              />
                            ) : (
                              <>
                                {translationsLoading
                                  ? "Test Connection"
                                  : fetchLabelKeyTranslation(
                                      "TestConnectionButton",
                                      "Test Connection"
                                  )}
                                </> 
                            )}
                          </Button>
                          <Button
                            className={`btn bg-primary text-white ${
                              !formRenderProps.allowSubmit ||
                              !formRenderProps.modified ||
                              !formRenderProps.valid ||
                              buttonStateTracker
                                ? "disabledBtn"
                                : ""
                            }`}
                            disabled={
                              !formRenderProps.allowSubmit ||
                              !formRenderProps.modified ||
                              !formRenderProps.valid ||
                              buttonStateTracker
                            }
                          >
                            {upserting ? (
                              <Loader
                                size={"small"}
                                type={"infinite-spinner"}
                                themeColor={"light"}
                              />
                            ) : (
                              <>
                                {translationsLoading
                                  ? "Update"
                                  : fetchLabelKeyTranslation(
                                      "UpdateButton",
                                      "Update"
                                    )}
                              </>
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
            </FormElement>
          )}
        />
      </div>
    </div>
  );
};

export default SFTPStorageLocation;
