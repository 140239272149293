import { RecordingTranscription } from "../types/recording";
import { SpeakerTranscriptInfo } from "../types/transcription";

export const processRawTranscript = (
  rawTranscript: RecordingTranscription
): SpeakerTranscriptInfo[] => {
  const processedTranscript: SpeakerTranscriptInfo[] = [];
  const transcriptWords = rawTranscript.words;

  if (transcriptWords.length === 0) {
    return [];
  }

  let speaker = transcriptWords[0].speaker;
  let speakerStart = transcriptWords[0].start;
  let speakerText =
    (transcriptWords[0].punctuated_word
      ? transcriptWords[0].punctuated_word
      : transcriptWords[0].word) + " ";
  let cntId = 0;
  for (let i = 1; i < transcriptWords.length; i++) {
    if (speaker === transcriptWords[i].speaker) {
      // break paragraphs into chunks if there is silence of greater than 2s.
      if (transcriptWords[i].start - transcriptWords[i - 1].end > 2) {
        processedTranscript.push({
          id: cntId,
          speaker,
          text: speakerText,
          start: speakerStart,
          end: transcriptWords[i].start,
          active: false,
        });
        speakerText = "";
        speaker = transcriptWords[i].speaker;
        speakerStart = transcriptWords[i].start;
        cntId += 1;
      }
    }
    if (speaker !== transcriptWords[i].speaker) {
      processedTranscript.push({
        id: cntId,
        speaker,
        text: speakerText,
        start: speakerStart,
        end: transcriptWords[i].start,
        active: false,
      });
      speakerText = "";
      speaker = transcriptWords[i].speaker;
      speakerStart = transcriptWords[i].start;
      cntId += 1;
    }
    speakerText =
      speakerText +
      (transcriptWords[i].punctuated_word
        ? transcriptWords[i].punctuated_word
        : transcriptWords[i].word) +
      " ";
  }

  // if no. of speakers = 1
  if (processedTranscript.length === 0 || speakerText.length > 0) {
    processedTranscript.push({
      id: cntId,
      speaker,
      text: speakerText,
      start: speakerStart,
      end: transcriptWords[transcriptWords.length - 1].end,
      active: false,
    });
  }

  return processedTranscript;
};
