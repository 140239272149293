import { SMTPServerDetail } from "../types/SMTPServerDetail";
import { PlatformUserDetail } from "../types/user";
import { axiosWithAuth } from "../utils/customAxios";

export interface TestEmailRequest {
  host: string;
  port: number;
  username: string;
  password: string;
  notificationDisplayName: string;
  notificationEmailAddress: string;
  receipientEmailAddress: string;
}

const emailService = {
  sendTestCustomerEmail: async (
    customerId: number,
    requestDetails: TestEmailRequest
  ): Promise<string> => {
    const response = await axiosWithAuth.post(
      `/customer/${customerId}/testemail`,
      requestDetails
    );
    const successMessage = response.data;

    return successMessage.message;
  },
};

export default emailService;
