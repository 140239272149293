import { ApiResponse } from "../types/ApiResponse";
import { Page } from "../types/Page";
import { CallDetail, CallDetailPropUpdateInput } from "../types/call";
import { CallDetailColorCategory } from "../types/call/CallDetailColorCategory";
import { CallFilter } from "../types/filters";
import { ColorCategory } from "../types/master-data";
import { axiosForShare, axiosWithAuth } from "../utils/customAxios";

const callsService = {
  fetchCalls: async (
    filter?: CallFilter,
    shared: boolean = false
  ): Promise<ApiResponse<CallDetail>> => {
    let requestQuery = "";
    if (filter) {
      requestQuery += "?";
      const queryFilters: string[] = [];
      const startDateFilter = filter.startDate ? `startdate=${filter.startDate}` : "";
      if (startDateFilter.trim() !== "") queryFilters.push(startDateFilter);
      const endDateFilter = filter.endDate ? `enddate=${filter.endDate}` : "";
      if (endDateFilter.trim() !== "") queryFilters.push(endDateFilter);
      const startTimeFilter = filter.startTime ? `starttime=${filter.startTime}` : "";
      if (startTimeFilter.trim() !== "") queryFilters.push(startTimeFilter);
      const endTimeFilter = filter.endTime ? `endtime=${filter.endTime}` : "";
      if (endTimeFilter.trim() !== "") queryFilters.push(endTimeFilter);
      const minDurationFilter = filter.minDuration ? `minduration=${filter.minDuration}` : "";
      if (minDurationFilter.trim() !== "") queryFilters.push(minDurationFilter);
      const maxDurationFilter = filter.maxDuration ? `maxduration=${filter.maxDuration}` : "";
      if (maxDurationFilter.trim() !== "") queryFilters.push(maxDurationFilter);
      const usersFilter = filter.users
        ? filter.users.map((id) => `users=${encodeURIComponent(id)}`).join("&")
        : "";
      if (usersFilter.trim() !== "") queryFilters.push(usersFilter);
      const otherPartiesFilter = filter.otherParties
        ? filter.otherParties.map((id) => `otherparties=${encodeURIComponent(id)}`).join("&")
        : "";
      if (otherPartiesFilter.trim() !== "") queryFilters.push(otherPartiesFilter);
      const calldirectionFilter = filter.callDirections
        ? filter.callDirections.map((cd) => `direction=${cd}`).join("&")
        : "";
      if (calldirectionFilter.trim() !== "") queryFilters.push(calldirectionFilter);
      const calltypeFilter = filter.callTypes
        ? filter.callTypes.map((ct) => `calltype=${ct}`).join("&")
        : "";
      if (calltypeFilter.trim() !== "") queryFilters.push(calltypeFilter);
      const platformsFilter = filter.platforms
        ? filter.platforms.map((p) => `platforms=${p}`).join("&")
        : "";
      if (platformsFilter.trim() !== "") queryFilters.push(platformsFilter);
      const categoriesFilter = filter.categories
        ? filter.categories.map((c) => `categories=${c}`).join("&")
        : "";
      if (categoriesFilter.trim() !== "") queryFilters.push(categoriesFilter);
      const topicsFilter = filter.topics
        ? filter.topics.map((t) => `topics=${t}`).join("&")
        : "";
      if (topicsFilter.trim() !== "") queryFilters.push(topicsFilter);
      const sentimentsFilter = filter.sentiments
        ? filter.sentiments.map((s) => `sentiments=${s}`).join("&")
        : ""; 
      if (sentimentsFilter.trim() !== "") queryFilters.push(sentimentsFilter);

      const evaluatedFilter = filter.evaluations
      ? filter.evaluations
          .map((id) => `evaluations=${encodeURIComponent(id)}`)
          .join("&")
      : "";
      if (evaluatedFilter.trim() !== "") queryFilters.push(evaluatedFilter);

      const pageFilter = (filter.page)
      ? `skip=${filter.page.skip}&take=${filter.page.take}` : "";
      if (pageFilter.trim() !== "") queryFilters.push(pageFilter);

      const sortFilter = (filter.page?.sort)
      ? `sortField=${filter.page.sort.sortField}&sortDirection=${filter.page.sort.sortDirection}` : "";
      if (sortFilter.trim() !== "") queryFilters.push(sortFilter);

      const searchFilter = filter.searchTerm ? `searchTerm=${encodeURIComponent(filter.searchTerm)}` : "";
      if (searchFilter.trim() !== "") queryFilters.push(searchFilter);
      requestQuery += queryFilters.join("&");
    }

    try {
      const axiosInstance = shared === true ? axiosForShare : axiosWithAuth;
      const response = await axiosInstance.get(`/calldetail${requestQuery}`);
      const callDetails: ApiResponse<CallDetail> = response.data;
      return callDetails;
    } catch (err) {
      throw err;
    }
  },

  fetchCallsByPlatformCallId: async (
    platformCallId: string,
    shared: boolean = false
  ): Promise<CallDetail[]> => {
    try {
      const axiosInstance = shared === true ? axiosForShare : axiosWithAuth;
      const response = await axiosInstance.get(
        `/call/${platformCallId}/calldetail`
      );
      const callDetails: CallDetail[] = response.data;

      return callDetails;
    } catch (err) {
      throw err;
    }
  },

  fetchCallByPlatformCallDetailId: async (
    platformCallDetailId: string,
    shared: boolean = false
  ): Promise<CallDetail> => {
    try {
      const axiosInstance = shared === true ? axiosForShare : axiosWithAuth;
      const response = await axiosInstance.get(
        `/calldetail/${platformCallDetailId}`
      );
      const callDetail: CallDetail = response.data;
      return callDetail;
    } catch (err) {
      throw err;
    }
  },

  updateCallDetailCustDefinedProperties: async (
    platformCallDetailId: string,
    input: CallDetailPropUpdateInput,
    shared: boolean = false
  ): Promise<CallDetail> => {
    try {
      const axiosInstance = shared === true ? axiosForShare : axiosWithAuth;
      const response = await axiosInstance.patch(
        `/calldetail/${platformCallDetailId}`,
        input
      );
      const updatedCallDetail: CallDetail = response.data;
      return updatedCallDetail;
    } catch (err) {
      throw err;
    }
  },

  createCallDetailCategory: async (
    platformCallDetailId: string, 
    colorCategory: ColorCategory,
    shared: boolean = false
    ): Promise<CallDetail> => 
    {
      try{
        const axiosInstance = shared === true ? axiosForShare : axiosWithAuth;
        const response = await axiosInstance.post(
          `CallDetail/${platformCallDetailId}/category`,
          colorCategory
        );
        const updatedCallDetail: CallDetail = response.data;
        return updatedCallDetail;
      }catch (err) {
      throw err;
      }
  },

  fetchParticipantsCallHistory:async(platformCallDetailId: string, page: Page, searchTerm: string, shared:boolean )=>{
    try {
      let requestQuery = "";
      if (page) {
        requestQuery += "?";
        const queryFilters: string[] = [];
        
        const pageFilter = (page)
        ? `skip=${page.skip}&take=${page.take}` : "";
        if (pageFilter.trim() !== "") queryFilters.push(pageFilter);

        const sortFilter = (page?.sort)
        ? `sortField=${page.sort.sortField}&sortDirection=${page.sort.sortDirection}` : "";
        if (sortFilter.trim() !== "") queryFilters.push(sortFilter);

        const searchFilter = searchTerm ? `searchTerm=${searchTerm}` : "";
        if (searchFilter.trim() !== "") queryFilters.push(searchFilter);
        requestQuery += queryFilters.join("&");
      }

      const axiosInstance = shared === true ? axiosForShare : axiosWithAuth;
      const response = await axiosInstance.get(`/calldetail/${platformCallDetailId}/participanthistory${requestQuery}`);
      const callDetails: ApiResponse<CallDetail> = response.data;
      return callDetails;
    } catch (err) {
      throw err;
    }
  }
};

export default callsService;
