import React, { useEffect, useRef } from "react";

interface InputFields {
  className?: string;
  placeholder?: string;
  value: string;
  onEscapeKeyFunc: boolean;
  searchTextChangeHandler: (event: any) => void;
  handleOnKeyDown?: (event: any) => void;
  updateValue: (value: string) => void;
}

const CustomSearchFieldTextInput: React.FC<InputFields> = ({
  className,
  placeholder,
  value,
  onEscapeKeyFunc,
  searchTextChangeHandler,
  handleOnKeyDown,
  updateValue,
}) => {
  const searchField = useRef<HTMLInputElement>(null);
  useEffect(() => {
    const keyDownFunction = (event: KeyboardEvent) => {
      const key = event.key;
      if (key === "Escape" && onEscapeKeyFunc) {
        updateValue("");
        searchField?.current?.blur();
      }
    };
    document.addEventListener("keydown", keyDownFunction);
    return () => {
      document.removeEventListener("keydown", keyDownFunction);
    };
  }, []);

  return (
    <>
      <input
        className={className}
        placeholder={placeholder}
        value={value}
        onChange={searchTextChangeHandler}
        onKeyDown={handleOnKeyDown}
        ref={onEscapeKeyFunc ? searchField : null}
      />
      <span className="searchIconBtn">
        <i className="bi bi-search"></i>
      </span>
    </>
  );
};

export default CustomSearchFieldTextInput;
