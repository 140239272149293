import React, { useEffect, useRef, useState } from "react";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { DatePicker, TimePicker } from "@progress/kendo-react-dateinputs";
import { Input } from "@progress/kendo-react-inputs";
import { Error, Hint } from "@progress/kendo-react-labels";
import {
  ListItemProps,
  MultiSelectChangeEvent,
  DropDownListChangeEvent,
  DropDownList,
  ComboBoxChangeEvent,
} from "@progress/kendo-react-dropdowns";
import moment from "moment";
import {
  CallFilter,
  CallPeriodFilterObj,
  FilterObject,
} from "../types/filters";
import { Topic } from "../types/topic";
import { TinyUser, User } from "../types/user";
import {
  ColorCategory,
  Platform,
  Sentiment,
  CallDirection,
  CallType,
} from "../types/master-data";
import { Popup } from "@progress/kendo-react-popup";
import { Link } from "react-router-dom";
import useLocale from "../hooks/useLocale";
import { Dictionary } from "../types/Dictionary";
import useMasterData from "../hooks/useMasterData";
import useAuth from "../hooks/useAuth";
import usersService from "../services/users.service";
import CustomUserGridInfo from "./custom/grid/CustomUserGridInfo";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { Loader } from "@progress/kendo-react-indicators";
import { Dates, getDateBasedOnFilterType } from "../utils/FilterDrawerUtil";
import { EvaluationRange } from "../types/master-data/EvaluationRange";
import CustomComboBox from "./custom/form/CustomComboBox";
import CustomMultiSelect from "./custom/form/CustomMultiSelect";

interface CallDurationType {
  id: number;
  title: string;
  field: string;
}

type CallFilterProps = {
  userMasterData?: User;
  callFilterData?: CallFilter;
  handleCallDataFilter: (callFilterData: CallFilter) => void;
  handleFilterDialog: () => void;
};

const CallFilterDrawer: React.FC<CallFilterProps> = (props) => {
  const masterData = useMasterData();
  const localeCtx = useLocale();
  const auth = useAuth();
  const periodFilterMenu = [
    {
      id: 1,
      title: "Day",
      displayTitle: "Day",
      translationLabelKey: "DayFilterLabel",
      filters: [
        {
          id: 1,
          title: "Today",
          translationLabelKey: "TodayFilterLabel",
          active: true,
        },
        {
          id: 2,
          title: "Yesterday",
          translationLabelKey: "YesterdayFilterLabel",
          active: false,
        },
        {
          id: 3,
          title: "Last 7 Days",
          translationLabelKey: "Last7DaysFilterLabel",
          active: false,
        },
        {
          id: 4,
          title: "Last 30 Days",
          translationLabelKey: "Last30DaysFilterLabel",
          active: false,
        },
      ],
      refObj: useRef<any>(),
      showFilters: false,
    },
    {
      id: 2,
      title: "Week",
      displayTitle: "Week",
      translationLabelKey: "WeekFilterLabel",
      filters: [
        {
          id: 1,
          title: "This Week",
          translationLabelKey: "ThisWeekFilterLabel",
          active: false,
        },
        {
          id: 2,
          title: "Last Week",
          translationLabelKey: "LastWeekFilterLabel",
          active: false,
        },
      ],
      refObj: useRef<any>(),
      showFilters: false,
    },
    {
      id: 3,
      title: "Month",
      displayTitle: "Month",
      translationLabelKey: "MonthFilterLabel",
      filters: [
        {
          id: 1,
          title: "This Month",
          translationLabelKey: "ThisMonthFilterLabel",
          active: false,
        },
        {
          id: 2,
          title: "Last Month",
          translationLabelKey: "LastMonthFilterLabel",
          active: false,
        },
      ],
      refObj: useRef<any>(),
      showFilters: false,
    },
    {
      id: 4,
      title: "Custom",
      displayTitle: "Custom",
      translationLabelKey: "CustomFilterLabel",
      showFilters: false,
    },
  ];
  const durationTypes: CallDurationType[] = [
    { id: 1, title: "ss", field: "sec" },
    { id: 2, title: "mm", field: "min" },
    { id: 3, title: "hh", field: "hr" },
  ];
  const [periodFilters, setPeriodFilters] =
    useState<CallPeriodFilterObj[]>(periodFilterMenu);
  const [periodType, setPeriodType] = useState<CallPeriodFilterObj>(
    periodFilters[0]
  );
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [startTime, setStartTime] = useState<Date | null>(
    new Date(2000, 1, 1, 0, 0, 0, 0)
  );
  const [endTime, setEndTime] = useState<Date | null>(
    new Date(2000, 1, 1, 23, 59, 59, 0)
  );
  const [firstparty, setFirstParty] = useState<TinyUser[]>([]);
  const [otherParty, setOtherParty] = useState<string | undefined>(undefined);
  const [platform, setPlatform] = useState<Platform[] | undefined>(undefined);
  const [topics, setTopics] = useState<Topic[]>([]);
  const [flag, setFlag] = useState<ColorCategory[] | undefined>(undefined);
  const [evaluated, setEvaluated] = useState<EvaluationRange[]>([]);
  const [callDirection, setCallDirection] = useState<
    CallDirection[] | undefined
  >(undefined);
  const [callType, setCallType] = useState<CallType[] | undefined>(undefined);
  const [sentiment, setSentiment] = useState<Sentiment[] | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(localeCtx?.selectedLocale?.current.componentTranslations["callsFilter"]);
  const [minDuration, setMinDuration] = useState<number | undefined>(undefined);
  const [maxDuration, setMaxDuration] = useState<number | undefined>(undefined);
  const [error, setError] = useState<string>("");
  const [durationTypeValueForMin, setDurationTypeValueForMin] =
    useState<CallDurationType>({
      id: 1,
      title: "ss",
      field: "sec",
    });
  const [durationTypeValueForMax, setDurationTypeValueForMax] =
    useState<CallDurationType>({
      id: 1,
      title: "ss",
      field: "sec",
    });

  const translatePeriodFiltersMenu = (
    updatedTranslations: Dictionary<string> | undefined
  ): CallPeriodFilterObj[] => {
    const updatedMenu = periodFilters;
    if (updatedTranslations) {
      updatedMenu.forEach((p) => {
        p.displayTitle = updatedTranslations[p.translationLabelKey] ?? p.title;
        if (p.filters) {
          p.filters.forEach((f) => {
            f.title = updatedTranslations[f.translationLabelKey] ?? f.title;
          });
        }
      });
    }
    updatedMenu.forEach((p) => {
      if (p.id === props.callFilterData?.periodType) {
        if (p.filters) {
          p.filters.forEach((f) => {
            if (f.id === props.callFilterData?.filterType) {
              p.displayTitle = f.title;
              f.active = true;
            } else {
              f.active = false;
            }
          });
        }
      } else {
        p.filters = p.filters?.map((f) => {
          f.active = false;
          return f;
        });
      }
    });
    return updatedMenu;
  };

  const fetchTranslations = async () => {
    try {
      setLoading(true);
      const resp = await localeCtx?.setComponentTranslations("callsFilter");
      setTranslations(resp);
      setPeriodFilters(translatePeriodFiltersMenu(resp));
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations["callsFilter"]
      );
      localeCtx?.setPreviousAppLocale("callsFilter");
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 50);
    }
  };

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations["callsFilter"]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  const setFilterValues = () => {
    if (props.callFilterData) {
      if (props.callFilterData.periodType && props.callFilterData.filterType) {
        // Reset and Activate Select Period Filter
        const initalPeriodFilterMenu: CallPeriodFilterObj[] =
          translatePeriodFiltersMenu(translations);
        const updatedPeriodFilters: CallPeriodFilterObj[] = [];
        initalPeriodFilterMenu.forEach((p) => {
          if (p.id === props.callFilterData?.periodType) {
            if (p.filters) {
              p.filters.forEach((f) => {
                if (f.id === props.callFilterData?.filterType) {
                  p.displayTitle = f.title;
                  f.active = true;
                } else {
                  f.active = false;
                }
              });
            }
          } else {
            p.filters = p.filters?.map((f) => {
              f.active = false;
              return f;
            });
          }
          updatedPeriodFilters.push(p);
        });
        setPeriodFilters(updatedPeriodFilters);
        const period =
          periodFilters[
            periodFilters.findIndex(
              (el) => el.id === props.callFilterData?.periodType
            )
          ];
        period.filters?.forEach((f) => {
          if (f.id === props.callFilterData?.filterType) {
            f.active = true;
            period.displayTitle = f.title;
          }
        });
        setPeriodType(period);
      } else if (props.callFilterData.periodType) {
        // Reset the period filters
        const initialFilters = translatePeriodFiltersMenu(translations);
        setPeriodFilters(
          initialFilters.map((p) => {
            p.filters?.forEach((f) => {
              f.active = false;
            });
            return p;
          })
        );

        setPeriodType(
          periodFilters[
            periodFilters.findIndex(
              (el) => el.id === props.callFilterData?.periodType
            )
          ]
        );
      }

      if (props.callFilterData?.startDate !== undefined) {
        setStartDate(new Date(props.callFilterData?.startDate));
      }
      if (props.callFilterData?.endDate !== undefined) {
        setEndDate(new Date(props.callFilterData?.endDate));
      }
      if (props.callFilterData?.startTime !== undefined) {
        setStartTime(timeStringToDate(props.callFilterData?.startTime));
      }
      if (props.callFilterData?.endTime !== undefined) {
        var et = timeStringToDate(props.callFilterData?.endTime);
        setEndTime(et);
      }
      if (masterData) {
        setFirstParty(
          masterData?.users?.filter((u) =>
            props.callFilterData?.users?.includes(u.id)
          )
        );
      }

      setOtherParty(props.callFilterData?.otherParties?.join(";"));

      if (props.callFilterData.durationTypeForMin === "min") {
        setMinDuration(
          props.callFilterData?.minDuration
            ? Number(props.callFilterData.minDuration) / 60
            : undefined
        );
      } else if (props.callFilterData.durationTypeForMin === "hr") {
        setMinDuration(
          props.callFilterData?.minDuration
            ? Number(props.callFilterData.minDuration) / 3600
            : undefined
        );
      } else {
        setMinDuration(
          props.callFilterData?.minDuration
            ? Number(props.callFilterData.minDuration)
            : undefined
        );
      }

      if (props.callFilterData.durationTypeForMax === "min") {
        setMaxDuration(
          props.callFilterData?.maxDuration
            ? Number(props.callFilterData.maxDuration) / 60
            : undefined
        );
      } else if (props.callFilterData.durationTypeForMax === "hr") {
        setMaxDuration(
          props.callFilterData?.maxDuration
            ? Number(props.callFilterData.maxDuration) / 3600
            : undefined
        );
      } else {
        setMaxDuration(
          props.callFilterData?.maxDuration
            ? Number(props.callFilterData.maxDuration)
            : undefined
        );
      }

      if (props?.callFilterData?.durationTypeForMin) {
        const dType = durationTypes.find(
          (d: CallDurationType) =>
            d.field === props.callFilterData?.durationTypeForMin
        );
        if (dType) {
          setDurationTypeValueForMin(dType);
        }
      }

      if (props?.callFilterData?.durationTypeForMax) {
        const dType = durationTypes.find(
          (d: CallDurationType) =>
            d.field === props.callFilterData?.durationTypeForMax
        );
        if (dType) {
          setDurationTypeValueForMax(dType);
        }
      }

      setPlatform(
        masterData?.data?.platforms?.filter((p) =>
          props.callFilterData?.platforms?.includes(p.name)
        )
      );
      setFlag(
        masterData?.data?.colorCategories?.filter((c) =>
          props.callFilterData?.categories?.includes(c.name)
        )
      );
      if (props.userMasterData?.topics) {
        setTopics(
          props.userMasterData?.topics?.filter((t) =>
            props.callFilterData?.topics?.includes(t.topicName)
          )
        );
      }
      setSentiment(
        masterData?.data?.sentiments?.filter((s) =>
          props.callFilterData?.sentiments?.includes(s.name)
        )
      );

      const evals: EvaluationRange[] = [];
      masterData?.data?.evaluationRange.forEach((fEval: EvaluationRange) => {
        if (props.callFilterData?.evaluations?.includes(fEval.name)) {
          evals.push(fEval);
        }
      });
      setEvaluated(evals);

      setCallDirection(
        masterData?.data?.callDirections.filter((c) =>
          props.callFilterData?.callDirections?.includes(c.name)
        )
      );

      setCallType(
        masterData?.data?.callTypes.filter((c) =>
          props.callFilterData?.callTypes?.includes(c.name)
        )
      );
    }
  };

  useEffect(() => {
    setFilterValues();
  }, [props.callFilterData]);

  useEffect(() => {
    setPeriodFilters((prev) => {
      return prev.map((p) => {
        if (p.id === periodType.id) {
          if (p.filters) {
            const activeFilter = p.filters.find((f) => f.active === true);
            p.displayTitle = activeFilter ? activeFilter.title : p.displayTitle;
          }
        }
        return p;
      });
    });
  }, []);

  const handlePeriodSelection = (
    period: CallPeriodFilterObj,
    filter: { id: number; title: string; active: boolean }
  ) => {
    // Reset and Activate Select Period Filter
    const updatedPeriodFilters: CallPeriodFilterObj[] =
      translatePeriodFiltersMenu(translations);
    updatedPeriodFilters.forEach((p) => {
      p.displayTitle =
        translations && translations[p.translationLabelKey]
          ? translations[p.translationLabelKey]
          : p.title;
      if (p.id === period.id) {
        if (p.filters) {
          p.filters.forEach((f) => {
            if (f.id === filter.id) {
              p.displayTitle = f.title;
              f.active = true;
            }
          });
          p.showFilters = false;
        }
      }
    });
    setPeriodFilters(updatedPeriodFilters);
    const getFilterBasesDates: Dates = getDateBasedOnFilterType(
      period.id ?? 0,
      filter.id ?? 0
    );
    setStartDate(getFilterBasesDates.tempStartDate);
    setEndDate(getFilterBasesDates.tempEndDate);
    // Set current period type
    period.filters?.forEach((f) => {
      if (f.id === filter.id) {
        f.active = true;
        period.displayTitle = f.title;
      } else {
        f.active = false;
      }
    });
    setPeriodType(period);
  };

  const timeStringToDate = (time: string) => {
    let _time = time.split(":").map((el) => parseInt(el));
    let dt = new Date(2000, 1, 1, _time[0], _time[1], _time[2]);
    return dt;
  };

  const handleCallDataFilter = () => {
    let tempMinDuration, tempMaxDuration;
    if (minDuration === undefined) tempMinDuration = undefined;
    if (maxDuration === undefined) tempMaxDuration = undefined;
    if (minDuration !== undefined) {
      if (durationTypeValueForMin.field === "min") {
        tempMinDuration = (Number(minDuration) * 60).toString();
      } else if (durationTypeValueForMin.field === "hr") {
        tempMinDuration = (Number(minDuration) * 60 * 60).toString();
      } else {
        tempMinDuration = minDuration.toString();
      }
    }
    if (maxDuration !== undefined) {
      if (durationTypeValueForMax.field === "min") {
        tempMaxDuration = (Number(maxDuration) * 60).toString();
      } else if (durationTypeValueForMax.field === "hr") {
        tempMaxDuration = (Number(maxDuration) * 60 * 60).toString();
      } else {
        tempMaxDuration = maxDuration.toString();
      }
    }
    var tempStartDate = startDate;
    var tempEndDate = endDate;
    tempStartDate?.setHours(0, 0, 0);
    tempEndDate?.setHours(23, 59, 59);

    const filterType = periodType.filters?.find((f) => f.active === true);

    var filterValues: CallFilter = {
      startDate: moment(tempStartDate).format("YYYY-MM-DD HH:mm:ss"),
      endDate: moment(tempEndDate).format("YYYY-MM-DD HH:mm:ss"),
      startTime: moment(startTime).format("HH:mm:ss"),
      endTime: moment(endTime).format("HH:mm:ss"),
      periodType: periodType?.id,
      filterType: filterType?.id,
      users: firstparty?.map((u) => u.id),
      otherParties: otherParty?.split(";"),
      platforms: platform?.map((p) => p.name),
      categories: flag?.map((f) => f.name),
      topics: topics?.map((t) => t.topicName),
      sentiments: sentiment?.map((s) => s.name),
      evaluations: evaluated?.map((e: EvaluationRange) => e.name),
      callDirections:
        callDirection !== undefined
          ? callDirection?.map((cd) => cd.name)
          : undefined,
      callTypes:
        callType !== undefined ? callType?.map((ct) => ct.name) : undefined,
      minDuration: tempMinDuration,
      maxDuration: tempMaxDuration,
      durationTypeForMin: durationTypeValueForMin.field,
      durationTypeForMax: durationTypeValueForMax.field,
    };
    props.handleCallDataFilter(filterValues);
  };

  const periodFilterClickHandler = (period: CallPeriodFilterObj) => {
    if (
      period.title.toLocaleLowerCase() === "custom" &&
      periodType?.title.toLocaleLowerCase() !== "custom"
    ) {
      setPeriodType(period);
      setStartDate(new Date());
      setEndDate(new Date());
      setPeriodFilters(translatePeriodFiltersMenu(translations));
    } else {
      setPeriodFilters((prev) => {
        return prev.map((p) => {
          if (p.id === period.id) {
            p.showFilters = !p.showFilters;
          } else {
            p.showFilters = false;
          }
          return p;
        });
      });
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const itemrenderTopics = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const checkedclass = itemProps.selected ? "liChecked" : "";
    const itemChildren = (
      <span>
        <i
          className={`bi-${
            itemProps.dataItem.isPublic ? "people" : "lock-fill"
          } m-r-6`}
        ></i>
        <span>{itemProps.dataItem.topicName}</span>
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  const itemRenderUser = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const userInfo: TinyUser = {
      id: itemProps.dataItem.id,
      loginUserId: 0,
      firstName: itemProps.dataItem.firstName,
      lastName: itemProps.dataItem.lastName,
      email: itemProps.dataItem.email,
      phoneNo: itemProps.dataItem.phoneNo,
      image: itemProps.dataItem.image,
      isArchived: itemProps.dataItem.isArchived,
    };
    const checkedclass = itemProps.selected ? "liChecked" : "";
    const itemChildren = (
      <span className={checkedclass}>
        <CustomUserGridInfo userInfo={userInfo} selected={itemProps.selected} />
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  const handleClearFilter = () => {
    setPeriodFilters(periodFilterMenu);
    setPeriodType(periodFilterMenu[0]);
    setPeriodFilters((prev) => {
      return prev.map((p) => {
        if (p.id === periodFilterMenu[0].id) {
          if (p.filters) {
            const activeFilter = p.filters.find((f) => f.active === true);
            p.displayTitle = activeFilter ? activeFilter.title : p.displayTitle;
          }
        }
        return p;
      });
    });
    setStartDate(new Date());
    setEndDate(new Date());
    setStartTime(new Date(2000, 1, 1, 0, 0, 0, 0));
    setEndTime(new Date(2000, 1, 1, 23, 59, 59, 0));
    setFirstParty([]);
    setOtherParty("");
    setPlatform([]);
    setTopics([]);
    setFlag([]);
    setEvaluated([]);
    setCallDirection(undefined);
    setCallType(undefined);
    setSentiment([]);
    setDurationTypeValueForMin({
      id: 1,
      title: "ss",
      field: "sec",
    });
    setDurationTypeValueForMax({
      id: 1,
      title: "ss",
      field: "sec",
    });
    setMinDuration(undefined);
    setMaxDuration(undefined);
    setError("");
  };

  const handleDurationType = (type: string, event: DropDownListChangeEvent) => {
    if (type === "min") {
      setDurationTypeValueForMin(event.target.value);
    } else if (type === "max") {
      setDurationTypeValueForMax(event.target.value);
    }
  };

  const maxDurationOnBlurEvent = () => {
    if (maxDuration !== undefined && minDuration !== undefined) {
      let maxVal = maxDuration,
        minVal = minDuration;
      if (durationTypeValueForMin.field === "min") {
        minVal = minDuration * 60;
      } else if (durationTypeValueForMin.field === "hr") {
        minVal = minDuration * 3600;
      }
      if (durationTypeValueForMax.field === "min") {
        maxVal = maxDuration * 60;
      } else if (durationTypeValueForMax.field === "hr") {
        maxVal = maxDuration * 3600;
      }
      if (maxVal < minVal) {
        setError("Max Duration cannot be less than Min duration");
      } else {
        setError("");
      }
    } else {
      setError("");
    }
  };

  useEffect(() => {
    if (periodFilters.some((p) => p.showFilters === true)) {
      const body = document.getElementById("root");
      const eventHandler = () => {
        setPeriodFilters((prev) => {
          return prev.map((p) => {
            p.showFilters = false;
            return p;
          });
        });
      };
      if (body != null) {
        body.addEventListener("click", eventHandler);
      }
      return () => body?.removeEventListener("click", eventHandler);
    }
  }, [periodFilters]);

  return (
    <div className="float-left border-top-1 border-black-1 border-top-solid p-t-10 p-b-10 p-r-15 p-l-15 w-100">
      <div className="filterExpd w-100 float-left">
        <div className="filterMain m-b-5 w-100 float-left">
          <div className="filterTrak">
            <div className="row">
              <div className="col-md-12">
                <div className="filterTrak-h fs-15 font-weight-semi p-b-5">
                  {loading
                    ? "Date/Time"
                    : fetchLabelKeyTranslation(
                        "PeriodFilterTitle",
                        "Date/Time"
                      )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="filterTrak-btn">
                  <div className="filterTrakRow">
                    <ButtonGroup className="rdButtonFilter">
                      {periodFilters.map((period) => {
                        return (
                          <div key={period.id}>
                            <Button
                              togglable={true}
                              onClick={() => periodFilterClickHandler(period)}
                              selected={period.id === periodType.id}
                            >
                              <span ref={period.refObj}>
                                {period.displayTitle}
                              </span>
                              {period.filters && (
                                <i className="bi bi-chevron-down p-l-3 fs-14"></i>
                              )}
                            </Button>
                            {period.filters && (
                              <Popup
                                anchor={period.refObj?.current}
                                show={period.showFilters}
                                popupClass={"popup-content"}
                                style={{
                                  marginLeft: "-10px",
                                  marginTop: "10px",
                                }}
                              >
                                <ul
                                  className="dropMenu min-w-40 droplist-ul"
                                  style={{
                                    maxWidth: "180px",
                                    width: "120px",
                                    top: "0",
                                    maxHeight: "300px",
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                  }}
                                >
                                  {period.filters.map((filter) => {
                                    return (
                                      <li
                                        key={filter.id}
                                        className="droplist-li"
                                      >
                                        <Link
                                          to="#"
                                          className={`droplist-a ${
                                            filter.active ? "activeFilter" : ""
                                          }`}
                                          onClick={(e) =>
                                            handlePeriodSelection(
                                              period,
                                              filter
                                            )
                                          }
                                        >
                                          {filter.title}
                                        </Link>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </Popup>
                            )}
                          </div>
                        );
                      })}
                    </ButtonGroup>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="filterTrak-date p-t-1">
                  <div className="setSubData">
                    {periodType.title.toLocaleLowerCase() === "custom" && (
                      <div className="setSubTrk">
                        <div className="form-group">
                          <div className="rangeFld">
                            <div className="rangeFldTrk">
                              <div className="rangeInput">
                                <DatePicker
                                  label={
                                    loading
                                      ? "Start Date"
                                      : fetchLabelKeyTranslation(
                                          "StartDateFilterLabel",
                                          "Start Date"
                                        )
                                  }
                                  value={startDate}
                                  max={endDate !== null ? endDate : new Date()}
                                  onChange={(event) =>
                                    setStartDate(event.value)
                                  }
                                  format="dd/MM/yyyy"
                                  width="100%"
                                />
                              </div>
                            </div>
                            <div
                              className="rangeFldTrk"
                              style={{ paddingRight: "0" }}
                            >
                              <div className="rangeInput">
                                <DatePicker
                                  label={
                                    loading
                                      ? "End Date"
                                      : fetchLabelKeyTranslation(
                                          "EndDateFilterLabel",
                                          "End Date"
                                        )
                                  }
                                  value={endDate}
                                  min={
                                    startDate !== null ? startDate : new Date()
                                  }
                                  max={new Date()}
                                  onChange={(event) => setEndDate(event.value)}
                                  format="dd/MM/yyyy"
                                  width="100%"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="setSubTrk">
                      <div className="form-group">
                        <div className="rangeFld">
                          <div className="rangeFldTrk">
                            <div className="rangeInput">
                              <TimePicker
                                label={
                                  loading
                                    ? "Start Time"
                                    : fetchLabelKeyTranslation(
                                        "StartTimeFilterLabel",
                                        "Start Time"
                                      )
                                }
                                format="HH:mm:ss"
                                value={startTime}
                                onChange={(event) => setStartTime(event.value)}
                                width="100%"
                              />
                            </div>
                          </div>
                          <div
                            className="rangeFldTrk"
                            style={{ paddingRight: "0" }}
                          >
                            <div className="rangeInput">
                              <TimePicker
                                label={
                                  loading
                                    ? "End Time"
                                    : fetchLabelKeyTranslation(
                                        "EndTimeFilterLabel",
                                        "End Time"
                                      )
                                }
                                format="HH:mm:ss"
                                value={endTime}
                                onChange={(event) => setEndTime(event.value)}
                                width="100%"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row m-t-10">
              <div className="col-md-12">
                <div className="inrExpand bg-black-1">
                  <div className="expandableNav navTitleCenter expandBox">
                    <div className="panelbar-wrapper">
                      <PanelBar animation={true}>
                        <PanelBarItem
                          title={
                            loading
                              ? "Participants"
                              : fetchLabelKeyTranslation(
                                  "ParticipantsFilterLabel",
                                  "Participants"
                                )
                          }
                          expanded={false}
                        >
                          <div className="inrExpandList float-left w-100 p-t-7 p-r-10 p-b-7 p-l-10">
                            <div className="float-left w-100">
                              <div className="inputList">
                                <div className="inputRow p-b-10">
                                  <div className="inputLabel">
                                    {loading
                                      ? "Recorded User"
                                      : fetchLabelKeyTranslation(
                                          "FirstPartyFilterLabel",
                                          "Recorded User"
                                        )}
                                  </div>
                                  <CustomMultiSelect
                                    data={
                                      props?.userMasterData?.recordingNetwork
                                    }
                                    onChange={(event: MultiSelectChangeEvent) =>
                                      setFirstParty([...event.value])
                                    }
                                    value={firstparty}
                                    dataItemKey="id"
                                    itemRender={itemRenderUser}
                                    textField="email"
                                    filtering={true}
                                    sorting={true}
                                    sortField={"email"}
                                  />
                                </div>
                                <div className="inputRow p-b-10">
                                  <div className="inputLabel">
                                    {loading
                                      ? "Participants"
                                      : fetchLabelKeyTranslation(
                                          "RemotePartyFilterLabel",
                                          "Participants"
                                        )}
                                  </div>
                                  <div className="multiselectBox">
                                    <Input
                                      style={{ height: "31px" }}
                                      value={otherParty}
                                      onChange={(e) => setOtherParty(e.value)}
                                      ariaDescribedBy={"otherPartyHint"}
                                    />
                                    <Hint id={"otherPartyHint"}>
                                      {loading
                                        ? "Enter multiple other parties separated by Semicolon"
                                        : fetchLabelKeyTranslation(
                                            "RemotePartyFilterHint",
                                            "Enter multiple other parties separated by Semicolon"
                                          )}
                                      (;).
                                    </Hint>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </PanelBarItem>
                      </PanelBar>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row m-t-15">
              <div className="col-md-12">
                <div className="inrExpand bg-black-1">
                  <div className="expandableNav navTitleCenter expandBox">
                    <div className="panelbar-wrapper">
                      <PanelBar animation={true}>
                        <PanelBarItem
                          title={
                            loading
                              ? "Other"
                              : fetchLabelKeyTranslation(
                                  "OtherFiltersFilterLabel",
                                  "Other"
                                )
                          }
                          expanded={false}
                        >
                          <div className="inrExpandList float-left w-100 p-t-7 p-r-10 p-b-7 p-l-10">
                            <div className="float-left w-100">
                              <div className="inputList">
                                <div className="inputRow p-b-10">
                                  <div className="inputLabel">
                                    {loading
                                      ? "Call Direction"
                                      : fetchLabelKeyTranslation(
                                          "CallDirectionFilterLabel",
                                          "Call Direction"
                                        )}
                                  </div>
                                  <div className="multiselectBox">
                                    <CustomComboBox
                                      data={masterData?.data?.callDirections}
                                      onChange={(
                                        event: ComboBoxChangeEvent
                                      ) => {
                                        event.value !== null
                                          ? setCallDirection([event.value])
                                          : setCallDirection(undefined);
                                      }}
                                      value={
                                        callDirection !== undefined
                                          ? callDirection[0]
                                          : null
                                      }
                                      textField="localizationValue"
                                      dataItemKey="id"
                                      sorting={true}
                                      sortField={"localizationValue"}
                                    />
                                  </div>
                                </div>
                                <div className="inputRow p-b-10">
                                  <div className="inputLabel">
                                    {loading
                                      ? "Call Type"
                                      : fetchLabelKeyTranslation(
                                          "CallTypeFilterLabel",
                                          "Call Type"
                                        )}
                                  </div>
                                  <div className="multiselectBox">
                                    <CustomComboBox
                                      data={masterData?.data?.callTypes}
                                      onChange={(
                                        event: ComboBoxChangeEvent
                                      ) => {
                                        event.value !== null
                                          ? setCallType([event.value])
                                          : setCallType(undefined);
                                      }}
                                      value={
                                        callType !== undefined
                                          ? callType[0]
                                          : null
                                      }
                                      textField="localizationValue"
                                      dataItemKey="id"
                                      sorting={true}
                                      sortField={"localizationValue"}
                                    />
                                  </div>
                                </div>
                                <div className="inputRow p-b-10 remove-upperlower-spinner">
                                  <div className="inputLabel">
                                    {loading
                                      ? "Call Duration"
                                      : fetchLabelKeyTranslation(
                                          "DurationLabel",
                                          "Call Duration"
                                        )}
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-evenly",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        position: "relative",
                                      }}
                                    >
                                      <Input
                                        type="number"
                                        style={{
                                          height: "31px",
                                          marginRight: "2px",
                                          width: "64%",
                                        }}
                                        onBlur={maxDurationOnBlurEvent}
                                        value={
                                          minDuration !== undefined
                                            ? minDuration
                                            : ""
                                        }
                                        onKeyDown={(evt) =>
                                          (evt.key === "e" ||
                                            evt.key === ".") &&
                                          evt.preventDefault()
                                        }
                                        onChange={(e) => {
                                          if (Number(e.value) >= 0)
                                            setMinDuration(Number(e.value));
                                        }}
                                        ariaDescribedBy={"otherPartyHint"}
                                        placeholder={
                                          loading
                                            ? "Min Duration"
                                            : fetchLabelKeyTranslation(
                                                "MinDurationPlaceholder",
                                                "Min Duration"
                                              )
                                        }
                                      />
                                      {minDuration !== undefined && (
                                        <i
                                          onClick={() => {
                                            setMinDuration(undefined);
                                            setError("");
                                          }}
                                          style={{
                                            position: "absolute",
                                            left: "95px",
                                            top: "6px",
                                            cursor: "pointer",
                                          }}
                                          className="bi bi-x"
                                        ></i>
                                      )}
                                      <DropDownList
                                        data={durationTypes}
                                        value={durationTypeValueForMin}
                                        onChange={(
                                          e: DropDownListChangeEvent
                                        ) => handleDurationType("min", e)}
                                        onBlur={maxDurationOnBlurEvent}
                                        style={{
                                          width: "36%",
                                          marginRight: "2px",
                                          border: "none",
                                        }}
                                        textField="title"
                                        dataItemKey="id"
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        position: "relative",
                                      }}
                                    >
                                      <Input
                                        type="number"
                                        style={{
                                          width: "64%",
                                          height: "31px",
                                          margin: "0 2px",
                                        }}
                                        value={
                                          maxDuration !== undefined
                                            ? maxDuration
                                            : ""
                                        }
                                        onBlur={maxDurationOnBlurEvent}
                                        onChange={(e) => {
                                          if (Number(e.value) >= 0)
                                            setMaxDuration(Number(e.value));
                                        }}
                                        onKeyDown={(evt) =>
                                          (evt.key === "e" ||
                                            evt.key === ".") &&
                                          evt.preventDefault()
                                        }
                                        ariaDescribedBy={"otherPartyHint"}
                                        placeholder={
                                          loading
                                            ? "Max Duration"
                                            : fetchLabelKeyTranslation(
                                                "MaxDurationPlaceholder",
                                                "Max Duration"
                                              )
                                        }
                                      />
                                      {maxDuration !== undefined && (
                                        <i
                                          onClick={() => {
                                            setMaxDuration(undefined);
                                            setError("");
                                          }}
                                          style={{
                                            position: "absolute",
                                            left: "95px",
                                            top: "6px",
                                            cursor: "pointer",
                                          }}
                                          className="bi bi-x"
                                        ></i>
                                      )}
                                      <DropDownList
                                        data={durationTypes}
                                        value={durationTypeValueForMax}
                                        onChange={(
                                          e: DropDownListChangeEvent
                                        ) => handleDurationType("max", e)}
                                        onBlur={maxDurationOnBlurEvent}
                                        style={{
                                          width: "36%",
                                          border: "none",
                                        }}
                                        textField="title"
                                        dataItemKey="id"
                                      />
                                    </div>
                                  </div>
                                  <Error>{error}</Error>
                                </div>
                                <div className="inputRow p-b-10">
                                  <div className="inputLabel">
                                    {loading
                                      ? "Platform"
                                      : fetchLabelKeyTranslation(
                                          "PlatformFilterLabel",
                                          "Platform"
                                        )}
                                  </div>
                                  <CustomMultiSelect
                                    data={masterData?.data?.platforms}
                                    onChange={(event: MultiSelectChangeEvent) =>
                                      setPlatform([...event.value])
                                    }
                                    value={platform}
                                    textField="localizationValue"
                                    dataItemKey="id"
                                    sorting={true}
                                    sortField={"localizationValue"}
                                  />
                                </div>
                                {auth?.checkTopicAvailibility() && (
                                  <div className="inputRow p-b-10">
                                    <div className="inputLabel">
                                      {loading
                                        ? "Topics"
                                        : fetchLabelKeyTranslation(
                                            "TopicsFilterLabel",
                                            "Topics"
                                          )}
                                    </div>
                                    <CustomMultiSelect
                                      data={props.userMasterData?.topics ?? []}
                                      value={topics}
                                      onChange={(
                                        event: MultiSelectChangeEvent
                                      ) => setTopics([...event.value])}
                                      textField="topicName"
                                      itemRender={itemrenderTopics}
                                      dataItemKey="topicName"
                                      filtering={true}
                                      sorting={true}
                                      sortField={"topicName"}
                                    />
                                  </div>
                                )}
                                <div className="inputRow p-b-10">
                                  <div className="inputLabel">
                                    {loading
                                      ? "Flag"
                                      : fetchLabelKeyTranslation(
                                          "FlagFilterLabel",
                                          "Flag"
                                        )}
                                  </div>
                                  <CustomMultiSelect
                                    data={masterData?.data?.colorCategories}
                                    onChange={(event: MultiSelectChangeEvent) =>
                                      setFlag([...event.value])
                                    }
                                    value={flag}
                                    textField="localizationValue"
                                    dataItemKey="id"
                                    sorting={true}
                                    sortField={"localizationValue"}
                                  />
                                </div>
                                <div className="inputRow p-b-10">
                                  <div className="inputLabel">
                                    {loading
                                      ? "Evaluation Score"
                                      : fetchLabelKeyTranslation(
                                          "EvalFilterLabel",
                                          "Evaluation Score"
                                        )}
                                  </div>
                                  <CustomMultiSelect
                                    data={masterData?.data?.evaluationRange}
                                    value={evaluated}
                                    onChange={(event: MultiSelectChangeEvent) =>
                                      setEvaluated([...event.value])
                                    }
                                    textField="localizationValue"
                                    dataItemKey="id"
                                    sorting={true}
                                    sortField={"localizationValue"}
                                  />
                                </div>
                                <div className="inputRow p-b-10">
                                  <div className="inputLabel">
                                    {loading
                                      ? "Sentiment"
                                      : fetchLabelKeyTranslation(
                                          "SentimentFilterLabel",
                                          "Sentiment"
                                        )}
                                  </div>
                                  <CustomMultiSelect
                                    data={masterData?.data?.sentiments}
                                    onChange={(event: MultiSelectChangeEvent) =>
                                      setSentiment([...event.value])
                                    }
                                    value={sentiment}
                                    textField="localizationValue"
                                    dataItemKey="id"
                                    sorting={true}
                                    sortField={"localizationValue"}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </PanelBarItem>
                      </PanelBar>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row m-t-15">
              <div className="col-md-12">
                <div className="d-flex justify-content-end">
                  {/* <Button className="btn bg-black-5 m-r-3"
                    onClick={props.handleFilterDialog}
                  >
                    Close
                  </Button> */}
                  <Button
                    className="btn bg-primary text-white m-r-5"
                    onClick={handleClearFilter}
                  >
                    {loading
                      ? "Clear"
                      : fetchLabelKeyTranslation("ClearBtnLabel", "Clear")}
                  </Button>
                  <Button
                    disabled={error === "" ? false : true}
                    className="btn bg-primary text-white"
                    onClick={handleCallDataFilter}
                  >
                    {loading
                      ? "Filter"
                      : fetchLabelKeyTranslation("FilterBtnLabel", "Filter")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallFilterDrawer;
