import { profileColors } from "../data";

export const getUserInitials = (
  firstName: string,
  lastName: string
): string => {
  if (!firstName && !lastName) {
    return "";
  }
  const userIntitals = (firstName && firstName[0]) + (lastName && lastName[0]);
  return userIntitals.toUpperCase();
};

export const formatUsernameToPascalCase = (
  firstName: string,
  lastName: string
) => {
  if (!firstName && !lastName) {
    return "";
  }
  const formattedUsername =
   (firstName && (firstName[0].toUpperCase() +
    firstName.slice(1).toLocaleLowerCase())) +
    " " +
    (lastName && (lastName[0].toUpperCase() +
    lastName.slice(1).toLocaleLowerCase()));

  return formattedUsername;
};

export const getRandomProfileColor = (id: number): string => {
  // const idx = Math.floor(Math.random() * profileColors.length);
  const idx = id % profileColors.length;
  const color = profileColors[idx];
  return color;
};
