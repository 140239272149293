import React, { useEffect, useState } from "react";
import { Tooltip } from "@progress/kendo-react-tooltip";

type ICallDirectionProps = {
  callDirection: string;
  callType:string;
  iconSize? : number | undefined;
};

const CallDirection: React.FC<ICallDirectionProps> = ({ callDirection,callType, iconSize }) => {
  var fontSize = iconSize !== undefined && iconSize > 0 ? iconSize : 18;
  return (
    <>
      {callDirection === "Received" && callType === "External" && (
        <span className={`fs-${fontSize} tx-green`} title="Received">
          <i className="bi bi-arrow-left"></i>
        </span>
      )}
      {callDirection === "Dialled" &&  callType === "External" && (
        <span className={`fs-${fontSize} text-info`} title="Dialled">
          <i className="bi bi-arrow-right"></i>
        </span>
      )}
      {callDirection === "Received" && callType === "Internal" && (
        <span className={`fs-${fontSize} text-muted`} title="Internal Received">
          <i className="bi bi-arrow-left"></i>
        </span>
      )}
      {callDirection === "Dialled" && callType === "Internal" && (
        <span className={`fs-${fontSize} text-muted`} title="Internal Dialled">
          <i className="bi bi-arrow-right"></i>
        </span>
      )}
    </>
  );
};

export default CallDirection;
