import React from "react";
import { useEffect, useState } from "react";
import { CallDetail } from "../../types/call";
import { TinyUser } from "../../types/user";
import callsService from "../../services/calls.service";
import { kendoAlertData } from "./CallDetails";
import { SetValue } from "../../hooks/useLocalStorage";
import CallListing from "../../components/custom/CallListing";
import { Dictionary } from "../../types/Dictionary";
import useLocale from "../../hooks/useLocale";
import { SweetAlertOptions } from "sweetalert2";
import useSwal from "../../hooks/useSwal";
import { Page } from "../../types/Page";
import { ApiResponse } from "../../types/ApiResponse";
import { CallTableSortColumnEnum } from "../../enums/CallTableSortColumnEnum";
import { ColorCategory } from "../../types/master-data";

interface CallHistoryProps {
  secondParty?: TinyUser;
  shared?: boolean;
  callDetail: CallDetail | undefined;
  setAlertData: SetValue<kendoAlertData>;
}

const initialPageDetail: Page = {
  skip: 0,
  take: 10,
  totalRecords: 0,
  sort: {
    sortField: CallTableSortColumnEnum.DateTime,
    sortDirection: "desc",
  },
};

const CallHistory: React.FC<CallHistoryProps> = ({
  secondParty,
  shared,
  callDetail,
  setAlertData,
}) => {
  const localeCtx = useLocale();
  const swal = useSwal();
  const secondPartyContact = secondParty
    ? secondParty.phoneNo
      ? secondParty.phoneNo
      : secondParty.email
    : "";
  const [calls, setCalls] = useState<ApiResponse<CallDetail> | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>();
  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(localeCtx?.selectedLocale?.current.componentTranslations["CallHistory"]);

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations["CallHistory"]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {
    if (secondPartyContact) {
      sendFetchCallsRequest(initialPageDetail);
    } else {
      setLoading(false);
      setError(true);
      setCalls({ data: [], page: initialPageDetail });
    }
  }, [secondPartyContact]);

  const sendFetchCallsRequest = async (page: Page) => {
    try {
      setLoading(true);
      setError(false);
      const fetchedCalls: ApiResponse<CallDetail> =
        await callsService.fetchParticipantsCallHistory(
          callDetail?.platformCallDetailId ?? "",
          page,
          "",
          shared === true
        );
      setCalls({ data: fetchedCalls.data, page: fetchedCalls.page });
    } catch (err) {
      if (err instanceof Error) {
        setError(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePaginationChange = (pageDetail: Page) => {
    sendFetchCallsRequest(pageDetail);
  };

  const handleSortChange = (sortField: string, sortDirection: string) => {
    let _pageFilter: Page = { ...initialPageDetail };
    _pageFilter.sort = {
      sortDirection: sortDirection,
      sortField: sortField,
    };
    sendFetchCallsRequest(_pageFilter);
  };

  const handleCallFlagUpdate = async (
    selectedFlag: ColorCategory | null,
    callDetailData: CallDetail
  ) => {
    if (selectedFlag === null) {
      selectedFlag = { id: 0, name: "", hexCode: "" };
    }
    if (calls?.data) {
      var tempCalls = calls.data.map((cd) => {
        if (cd.platformCallDetailId === callDetailData.platformCallDetailId) {
          cd.colorCategory = selectedFlag !== null ? selectedFlag : undefined;
        }
        return cd;
      });
      setCalls({ ...calls, data: tempCalls });
    }
  };

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations("CallHistory");
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations["CallHistory"]
      );
      localeCtx?.setPreviousAppLocale("CallHistory");
      if (localeCtx?.localeSwitchFailed) {
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        };
        swal.fire(swalOptions);
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="table-container table-responsive table-overflow-hedden borderLeftSecondChild table-mobile">
          <CallListing
            isBasic={true}
            loading={loading}
            error={error}
            callData={calls?.data}
            handleCallFlagUpdate={handleCallFlagUpdate}
            enableServerSidePagination={true}
            searchTerm={""}
            pageDetail={calls?.page}
            handlePaginationChange={handlePaginationChange}
            handleSortingChange={handleSortChange}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(CallHistory);
