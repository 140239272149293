import React, { useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Drawer,
  DrawerItem,
  DrawerItemProps,
  DrawerSelectEvent,
} from "@progress/kendo-react-layout";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { AccessPermissionEnum } from "../../enums";

const drawerItems = [
  {
    id: 1,
    text: "Home",
    route: "/home",
    icon: "bi bi-house-door-fill",
  },
  {
    separator: true,
  },
  {
    id: 2,
    text: "Search",
    route: "/search",
    icon: "bi bi-search",
    selected: true,
  },
  {
    separator: true,
  },
  {
    id: 3,
    text: "Settings",
    ["data-expanded"]: false,
    icon: "k-i-gear k-i-cog k-i-custom",
  },
  {
    id: 4,
    parentId: 3,
    text: "User Management",
    route: "/settings/user-control",
  },
  // {
  //   id: 5,
  //   parentId: 3,
  //   text: "User Groups",
  //   route: "/settings/user-groups",
  // },
  // {
  //   id: 13,
  //   parentId: 3,
  //   text: "Permission Group",
  //   route: "/settings/permission-group",
  // },
  {
    id: 8,
    parentId: 3,
    text: "Topics",
    route: "/settings/topics",
  },
  // {
  //   id: 7,
  //   parentId: 3,
  //   text: "Moments",
  //   route: "/settings/moments",
  // },
  // {
  //   id: 9,
  //   parentId: 3,
  //   text: "Recording Policy",
  //   route: "/settings/recording-policy",
  // },
  // {
  //   id: 10,
  //   parentId: 3,
  //   text: "Storage Policy",
  //   route: "/settings/storage-policy",
  // },
  {
    id: 11,
    parentId: 3,
    text: "Call Outcomes",
    route: "/settings/outcome",
  },
  {
    id: 6,
    parentId: 3,
    text: "Scorecards",
    route: "/settings/scorecards",
  },
  {
    id: 12,
    parentId: 3,
    text: "System Settings",
    route: "/settings/system",
  },
];

const CustomDrawerItem = (props: DrawerItemProps) => {
  const { visible, ...others } = props;
  const arrowDir = props["data-expanded"]
    ? "k-i-arrow-chevron-down"
    : "k-i-arrow-chevron-right";

  return (
    <React.Fragment>
      {props.visible === false ? null : (
        <DrawerItem {...others}>
          <span className={"k-icon " + props.icon} />
          <span className={"k-item-text"}>{props.text}</span>
          {props["data-expanded"] !== undefined && (
            <span
              className={"k-icon " + arrowDir}
              style={{ position: "absolute", right: 10 }}
            />
          )}
        </DrawerItem>
      )}
    </React.Fragment>
  );
};

const DrawerOverlay: React.FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [drawerExpanded, setDrawerExpanded] = useState(false);
  const [items, setItems] = useState<Array<any>>(drawerItems);

  useEffect(() => {
    const newData = items.filter((item) => {
      if (
        item?.text === "User Control" ||
        item?.text === "Scorecards" ||
        item?.text === "Topics" ||
        item?.text === "Call Result" ||
        item?.text === "System Settings"
      ) {
        if (
          (item?.text === "User Control" &&
            (auth?.checkUserAccess(AccessPermissionEnum.ManageUsersAndGroups) ||
              auth?.checkUserAccess(
                AccessPermissionEnum.ManageSecurityControl
              ))) ||
          (item?.text === "Scorecards" &&
            auth?.checkUserAccess(AccessPermissionEnum.ManageScorecards)) ||
          (item?.text === "Topics" &&
            auth?.checkTopicAvailibility() &&
            auth?.checkUserAccess(AccessPermissionEnum.ManageTopics)) ||
          (item?.text === "Call Result" &&
            auth?.checkUserAccess(AccessPermissionEnum.ManageOutcomes)) ||
          (item?.text === "System Settings" &&
            auth?.checkUserAccess(AccessPermissionEnum.ManageAccount))
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    });
    setItems(newData);
  }, []);

  const toggleOverlayExpand = () => {
    setDrawerExpanded((prevState) => !prevState);
  };

  const onSelect = (event: DrawerSelectEvent) => {
    const currentItem = event.itemTarget.props;
    const isParent = currentItem["data-expanded"] !== undefined;
    const nextExpanded = !currentItem["data-expanded"];

    const newData = items.map((item) => {
      const {
        selected,
        ["data-expanded"]: currentExpanded,
        id,
        ...others
      } = item;
      const isCurrentItem = currentItem.id === id;
      return {
        selected: isCurrentItem,
        ["data-expanded"]:
          isCurrentItem && isParent ? nextExpanded : currentExpanded,
        id,
        ...others,
      };
    });
    navigate(event.itemTarget.props.route);
    setItems(newData);
    if (!isParent) {
      setDrawerExpanded(false);
    }
  };

  const data = items.map((item) => {
    const { parentId, ...others } = item;
    if (parentId !== undefined) {
      const parent = items.find((parent) => parent.id === parentId);
      return {
        ...others,
        visible: parent["data-expanded"],
      };
    }
    return item;
  });

  return (
    <div className="buttons-container hov-transparent d-flex align-items-center">
      <Button
        className="navbar-toggler pl-0 pt-1 m-r-5 border-0 collapsed buttons-container-button p-0"
        type="button"
        onClick={toggleOverlayExpand}
      >
        <i className="bi bi-list fs-28 line-height-1 text-primary"></i>
      </Button>
      <Drawer
        expanded={drawerExpanded}
        position={"end"}
        mode="overlay"
        animation={{ duration: 400 }}
        items={data}
        item={CustomDrawerItem}
        onOverlayClick={toggleOverlayExpand}
        onSelect={onSelect}
      ></Drawer>
    </div>
  );
};

export default DrawerOverlay;
