import { GridCellProps } from "@progress/kendo-react-grid";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { CallDetail } from "../../../types/call";
import { RecordingTopic } from "../../../types/recording";
import { useState } from "react";
import { Dictionary } from "../../../types/Dictionary";

interface CustomGridCellProps extends GridCellProps {
  dataItem: CallDetail;
}

interface ExactMatchTopicWithOccurence {
  name: string;
  occurences: number;
  visibility: boolean | undefined;
}

const topicRelevanceComparer = (t1: RecordingTopic, t2: RecordingTopic) => {
  if (t1.topicRelevance > t2.topicRelevance) {
    return -1;
  } else if (t1.topicRelevance < t2.topicRelevance) {
    return 1;
  } else {
    return 0;
  }
};

const topicRelevanceColor = (t: RecordingTopic) => {
  var topicColor = "";
  if (t.topicRelevance > 0.9) {
    topicColor = "bg-greenDLL";
  } else if (t.topicRelevance > 0.6) {
    topicColor = "bg-yellowDLL";
  }
  return topicColor;
};

const CustomGridCellForCallTopic = (props: CustomGridCellProps) => {
  const data1 =
    props.dataItem.recordings.length > 0
      ? props.dataItem.recordings[0].topics
      : [];
  const data = data1 === null ? [] : data1;
  const exactMatchTopic = data.filter((t) => t !== null && t.topicRelevance === null);
  let exactMatchTopicsWithOccurences: ExactMatchTopicWithOccurence[] = [];
  exactMatchTopic.forEach((t) => {
    var index = exactMatchTopicsWithOccurences.findIndex((e) => e.name === t.topicName.name);
    if (index < 0) {
      exactMatchTopicsWithOccurences.push({
        name: t.topicName.name,
        occurences: 1,
        visibility: t.topicName.isPublic,
      });
    } else {
      exactMatchTopicsWithOccurences[index].occurences += 1;
    }
  });
  const extendedSearchTopic = data
    .filter((t) => t !== null && t.topicRelevance !== null)
    .sort(topicRelevanceComparer);
  const relevantCallTopics = extendedSearchTopic;
  return (
    <td className="mx-th-tag">
      <div className="mx-td-spn">
        <div className="keywordRow">
          {!props.dataItem.recordings[0].isAIAnalysed ? (
            <div className="blurArea2">
              <div className="keywordTrk">Topic</div>
              <div className="keywordTrk">Topic</div>
            </div>
          ) : (
            <div>
              {exactMatchTopicsWithOccurences?.map(
                (
                  exactMatchTopic: ExactMatchTopicWithOccurence,
                  index: number
                ) => (
                  <div className="keywordTrk bg-greenDarkDLL" key={index}>
                    <Tooltip
                      anchorElement={"target"}
                      position={"right"}
                      parentTitle={true}
                    >
                      <i
                        className={`bi ${
                          exactMatchTopic.visibility
                            ? "bi-people"
                            : "bi-lock-fill"
                        } m-r-6`}
                      ></i>
                      <span title={`Occurences: ${exactMatchTopic.occurences}`}>
                        {exactMatchTopic.name}
                      </span>
                    </Tooltip>
                  </div>
                )
              )}
              {relevantCallTopics?.map(
                (topic: RecordingTopic, index: number) => (
                  <div
                    className={"keywordTrk " + topicRelevanceColor(topic)}
                    key={index}
                  >
                    <Tooltip
                      anchorElement={"target"}
                      position={"right"}
                      parentTitle={true}
                    >
                      <i
                        className={`bi ${
                          topic.topicName.isPublic
                            ? "bi-people"
                            : "bi-lock-fill"
                        } m-r-6`}
                      ></i>
                      <span
                        title={
                          topic.topicRelevance !== null
                            ? `Relevance: ${Math.round(
                                topic.topicRelevance * 100
                              )}%`
                            : ""
                        }
                      >
                        {topic.topicName.name}
                      </span>
                    </Tooltip>
                  </div>
                )
              )}
            </div>
          )}
        </div>
      </div>
    </td>
  );
};

export default CustomGridCellForCallTopic;
