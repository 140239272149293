import {
  Grid,
  GridCellProps,
  GridColumn,
  GridColumnProps,
  GridNoRecords,
  GridPageChangeEvent,
} from "@progress/kendo-react-grid";
import { Loader } from "@progress/kendo-react-indicators";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CustomUserGridInfo from "../../components/custom/grid/CustomUserGridInfo";
import useLocale from "../../hooks/useLocale";
import usersService from "../../services/users.service";
import { Dictionary } from "../../types/Dictionary";
import {
  InsightMetricCell,
  InsightMetricKeyLabel,
  InsightViewByTblData,
} from "../../types/insight";
import { TinyUser } from "../../types/user";
import {
  formatUsernameToPascalCase,
  getRandomProfileColor,
} from "../../utils/profileUtils";
import useAuth from "../../hooks/useAuth";
import useSwal from "../../hooks/useSwal";
import { SweetAlertOptions } from "sweetalert2";

interface UsersInsightMetricCell {
  columnName: string;
  metricKeyLabel: string;
  metricKeyCounts: { metricKey: TinyUser; count: number }[];
}

interface AggregateData {
  name: string;
  additionalProperties: Dictionary<string>;
  displayName: string;
  subKeyCallsCnt: { key: string; count: number }[];
  totalCallsCnt: number;
  sentiments?: InsightMetricCell;
  topTopics?: InsightMetricCell;
  topMoments?: InsightMetricCell;
  topUsers?: UsersInsightMetricCell;
}

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

interface InsightViewByGridProps {
  viewByFilterTblData?: InsightViewByTblData;
  viewBy: InsightMetricKeyLabel;
  error?: string;
  onViewByCallCountClickHandler: (
    metricKeyLabel: string,
    metricKey: string,
    metricSubKeyLabel?: string,
    displayName?: string
  ) => void;
}

interface CustomGridCellProps extends GridCellProps {
  dataItem: AggregateData;
}

const customCellMobile = (props: any) => {
  return (
    <td className="listMobile">
      <div className="listRowMobile float-left p-0">
        <div className="tblUsr float-left w-100 p-r-40 p-b-5">
          <div className="topUsrAreaPic">
            <div className="topUsrAreaPic-i p-r-12">
              <div className="contact-list-icon bg-darksteelblue">
                <div className="contact-list-icon-txt">M</div>
              </div>
            </div>
            <div className="topUsrAreaPic-tx">
              <span className="fs-14 font-weight-semi line-height-2">
                Topic
              </span>
              <span className="text-primary fs-15 font-weight-semi cursor-default text-decoration-none float-left w-100 line-height-2">
                Meeting Conversation
              </span>
            </div>
          </div>
        </div>
        <div className="topics float-left w-100 p-t-0 p-b-5 p-l-50">
          <div className="fs-14 font-weight-semi p-r-5">Description:</div>
          <div className="chipLable line-height-2">
            <span
              className="mx-th-tag"
              style={{ minWidth: "80px", maxWidth: "160px" }}
            >
              <div className="mx-td-spn" style={{ maxWidth: "60vw" }}>
                Client want to record call and for testing purpose. Lorem ipsum
                text this the copy text only for testing purpose
              </div>
            </span>
          </div>
        </div>
        <div className="topics float-left w-100 p-t-5 p-b-5 p-l-50">
          <div className="fs-14 font-weight-semi p-r-15">Keywords:</div>
          <div className="chipLable">
            <span
              className="mx-th-tag"
              style={{ minWidth: "80px", maxWidth: "160px" }}
            >
              <div className="mx-td-spn" style={{ maxWidth: "70vw" }}>
                <div className="keywordRow">
                  <div className="keywordTrk">extno 1350</div>
                  <div className="keywordTrk">extno 367</div>
                  <div className="keywordTrk">shiv.dhillon@4com.im</div>
                  <div className="keywordTrk">shiv.dhillon@tollring.com</div>
                  <div className="keywordTrk">extno 4535</div>
                  <div className="keywordTrk">extno 4530</div>
                </div>
              </div>
            </span>
          </div>
        </div>
        <div className="topics float-left w-100 p-t-5 p-l-50 d-flex align-items-center">
          <div className="fs-14 font-weight-semi p-r-10">Visibility:</div>
          <div className="chipLable">
            <span className="fs-16 text-black-10 m-r-5" title="Edit">
              <i className="bi bi-people"></i>
            </span>
            <span className="fs-14" title="Add">
              Public
            </span>
          </div>
        </div>
        <div className="iconMobileDetail p-r-40">
          <span className="fs-18 text-primary" title="View Detail">
            <Link
              to="#"
              className="btn-link text-primary p-r-4 p-l-4 m-0 fs-18 cursor-pointer"
            >
              <i className="bi bi-pencil"></i>
            </Link>
            <Link
              to="#"
              className="btn-link text-primary p-r-4 p-l-4 m-0 fs-18 cursor-pointer"
            >
              <i className="bi bi-trash3"></i>
            </Link>
          </span>
        </div>
      </div>
    </td>
  );
};

const processMetricKeyForDisplay = (
  metricKeyLabel: string,
  metricKey: string,
  metricSubKeyLabel?: string
) => {
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  if (metricKeyLabel === "Hour") {
    const hour = Number(metricKey);
    return `${
      hour > 9 ? `${hour}:00 - ${hour + 1}:00` : `${hour}:00 - ${hour + 1}:00`
    }`;
  } else if (metricKeyLabel === "Day") {
    return days[Number(metricKey) % days.length];
  } else if (metricKeyLabel === "Week") {
    return `Week ${metricKey}`;
  } else if (metricKeyLabel === "Month") {
    return months[Number(metricKey) % months.length];
  }

  return metricKey;
};

const InsightViewByGrid: React.FC<InsightViewByGridProps> = ({
  viewByFilterTblData,
  viewBy,
  error,
  onViewByCallCountClickHandler,
}) => {
  const localeCtx = useLocale();
  const auth = useAuth();
  const swal = useSwal()
  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(
    localeCtx?.selectedLocale?.current.componentTranslations[
      "InsightsViewByGrid"
    ]
  );
  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations("InsightsViewByGrid");
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations[
          "InsightsViewByGrid"
        ]
      );
      localeCtx?.setPreviousAppLocale("InsightsViewByGrid");
      if (localeCtx?.localeSwitchFailed) {
        const swalOptions : SweetAlertOptions<any, any> = {
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        }
        swal.fire(swalOptions);
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };
  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const CustomTopUsersPropCell = (props: CustomGridCellProps) => {
    const userMetrics = props.dataItem.topUsers;

    const UserBadge: React.FC<{ userInfo: TinyUser; cnt: number }> = ({
      userInfo,
      cnt,
    }) => {
      const name = formatUsernameToPascalCase(
        userInfo?.firstName ? userInfo.firstName : "",
        userInfo?.lastName ? userInfo?.lastName : ""
      ).trim();
      const color = getRandomProfileColor(userInfo?.id ?? 0);
      const userIcon = userInfo?.image ? (
        <div className="contact-list-icon-img">
          <img src={require("../../assets/images/user-img-2.jpg")} />
        </div>
      ) : name !== "" ? (
        <span>
          {name
            .split(" ")
            .map((n: string) => n[0])
            .join("")}
        </span>
      ) : (
        <span className="fs-10">UU</span>
      );

      return (
        <>
          {!userInfo ? (
            <span></span>
          ) : (
            <div
              className="keywordTrk"
              style={{
                border: "2px solid rgba(0,0,0,.08)",
                background: "transparent",
              }}
            >
              <div className="tblUsr float-left">
                <div className="topUsrAreaPic">
                  <div
                    className="topUsrAreaPic-i"
                    style={{ padding: "2px 1px 2px 0" }}
                  >
                    <div
                      className={`contact-list-icon ${color}`}
                      title={`${name}`}
                      style={{ height: "22px", width: "22px", border: "none" }}
                    >
                      <div className="contact-list-icon-txt fs-10">
                        {userIcon}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <span
                className="numCount"
                title={`${
                  translationsLoading
                    ? "Calls"
                    : fetchLabelKeyTranslation("TextCalls", "Calls")
                }`}
                style={{
                  color: "rgba(0,0,0,.4)",
                  padding: "0",
                  cursor: "default",
                }}
              >
                {cnt}
              </span>
            </div>
          )}
        </>
      );
    };

    return (
      <td className="mx-th-tag">
        <div className="mx-td-spn">
          <div className="keywordRow">
            {userMetrics &&
              userMetrics.metricKeyCounts.map((m, idx) => {
                return (
                  <UserBadge key={idx} userInfo={m.metricKey} cnt={m.count} />
                );
              })}
          </div>
        </div>
      </td>
    );
  };

  const CustomTopTopicsPropCell = (props: CustomGridCellProps) => {
    const topicMetrics = props.dataItem.topTopics;
    return (
      <td className="mx-th-tag">
        <div className="mx-td-spn">
          <div className="keywordRow">
            {topicMetrics &&
              topicMetrics.metricKeyCounts.map((m, idx) => {
                return (
                  <div
                    className={`keywordTrk ${
                      m.additionalProperties["IsExactMatch"] === "true"
                        ? "bg-greenDarkDLL"
                        : ""
                    }`}
                    key={idx}
                  >
                    <i
                      className={`bi ${
                        m.metricKey.substring(0, 3) === "PUB"
                          ? "bi-people"
                          : "bi-lock-fill"
                      } m-r-6`}
                    ></i>
                    {m.metricKey.substring(4)}

                    {m.subKeyCounts.map((c) => {
                      return (
                        <span
                          style={{ cursor: "default" }}
                          className={`numCount`}
                          title={`${
                            translationsLoading
                              ? "Calls"
                              : fetchLabelKeyTranslation("TextCalls", "Calls")
                          }`}
                        >
                          {c.count}
                        </span>
                      );
                    })}
                  </div>
                );
              })}
          </div>
        </div>
      </td>
    );
  };

  const CustomTopMomentsPropCell = (props: CustomGridCellProps) => {
    const momentMetrics = props.dataItem.topMoments;
    return (
      <td className="mx-th-tag">
        <div className="mx-td-spn">
          <div className="keywordRow">
            {momentMetrics &&
              momentMetrics.metricKeyCounts.map((m, idx) => {
                return (
                  <div className="keywordTrk" key={idx}>
                    <i
                      className={`bi ${
                        m.metricKey.substring(0, 3) === "PUB"
                          ? "bi-people"
                          : "bi-lock-fill"
                      } m-r-6`}
                    ></i>
                    {m.metricKey.substring(4)}
                    <span
                      className="numCount"
                      title={`${
                        translationsLoading
                          ? "Calls"
                          : fetchLabelKeyTranslation("TextCalls", "Calls")
                      }`}
                    >
                      {m.subKeyCounts.map((c) => {
                        return <span>{c.count}</span>;
                      })}
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
      </td>
    );
  };

  const CustomSentimentsCell = (props: CustomGridCellProps) => {
    const sentimentMetrics = props.dataItem.sentiments;

    const SentimentBadge: React.FC<{ sentiment: string; cnt: number }> = ({
      sentiment,
      cnt,
    }) => {
      const cls =
        sentiment === "Positive"
          ? "success"
          : sentiment === "Negative"
          ? "danger"
          : "warning";
      const emoji =
        sentiment === "Positive"
          ? "smile"
          : sentiment === "Negative"
          ? "frown"
          : "neutral";
      return (
        <div
          className="keywordTrk"
          style={{
            border: "2px solid rgba(0,0,0,.08)",
            background: "transparent",
          }}
        >
          <div className="tblUsr float-left">
            <div className="topUsrAreaPic">
              <div
                className="topUsrAreaPic-i"
                style={{ padding: "3px 1px 2px 0" }}
              >
                <div
                  className="contact-list-icon"
                  title={sentiment}
                  style={{ height: "22px", width: "22px", border: "none" }}
                >
                  <i
                    className={`bi bi-emoji-${emoji}-fill fs-20 text-${cls}`}
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <span
            className="numCount"
            title={`${
              translationsLoading
                ? "Calls"
                : fetchLabelKeyTranslation("TextCalls", "Calls")
            }`}
            style={{ color: "rgba(0,0,0,.4)", padding: "0", cursor: "default" }}
          >
            {cnt}
          </span>
        </div>
      );
    };

    return (
      <td className="mx-th-tag">
        <div className="mx-td-spn">
          <div className="keywordRow">
            {sentimentMetrics &&
              sentimentMetrics.metricKeyCounts.map((m, idx) => {
                return (
                  <SentimentBadge
                    key={idx}
                    sentiment={m.metricKey}
                    cnt={m.totalCount}
                  />
                );
              })}
          </div>
        </div>
      </td>
    );
  };

  const CustomViewByHeaderCell = (props: CustomGridCellProps) => {
    const data = props.dataItem;
    let tinyUser: TinyUser | undefined = undefined;
    if (viewBy.name === "Users") {
      try {
        tinyUser = JSON.parse(data.displayName);
      } catch (err) {}
    }

    console.log(data.additionalProperties);

    const label = tinyUser ? (
      <CustomUserGridInfo userInfo={tinyUser} />
    ) : viewBy.name === "Moments" || viewBy.name === "Topics" ? (
      <React.Fragment>
        {data.displayName.substring(0, 3) === "Pri" ? (
          <i className="bi-lock-fill m-r-6" />
        ) : (
          <i className="bi-people m-r-6" />
        )}
        <span>{`${data.displayName.substring(4)}`}</span>
        <span className="boxLabelAI m-l-4 bg-primary tx-white">
          {data.additionalProperties["IsExactMatch"] === "true"
            ? "EXACT"
            : "EXTENDED"}
        </span>
      </React.Fragment>
    ) : (
      <span>{`${data.displayName}`}</span>
    );

    return (
      <td className="mx-th-tag">
        <div>{label}</div>
      </td>
    );
  };
  const CustomTotalCallsPropCell = (props: CustomGridCellProps) => {
    const data = props.dataItem;

    const onCallsClick = (subKeyLabel?: string) => {
      let tinyUser: TinyUser | undefined = undefined;
      if (viewBy.name === "Users") {
        try {
          tinyUser = JSON.parse(data.displayName);
        } catch (err) {}
      }
      const name = formatUsernameToPascalCase(
        tinyUser?.firstName ? tinyUser.firstName : "",
        tinyUser?.lastName ? tinyUser?.lastName : ""
      ).trim();
      onViewByCallCountClickHandler(
        viewBy.name,
        data.name,
        subKeyLabel,
        tinyUser
          ? name.length > 0
            ? name
            : tinyUser.email
          : viewBy.name === "Topics"
          ? data.displayName.substring(4)
          : data.displayName
      );
    };

    return (
      <td className="mx-th-tag">
        <div className="mx-td-spn">
          {data.subKeyCallsCnt.length > 0 ? (
            data.subKeyCallsCnt.map((c) => {
              return (
                <div
                  className={`keywordTrk m-b-1 fs-15 cursor-pointer w-auto ${
                    c.key === "HighRelevanceTopic"
                      ? "bg-greenDLL"
                      : c.key === "LowRelevanceTopic"
                      ? "bg-yellowDLL"
                      : c.key === "ExactMatchTopic"
                      ? "bg-greenDarkDLL"
                      : ""
                  }`}
                  title={c.key}
                  style={{ color: "#1E6570" }}
                  onClick={(e) => onCallsClick(c.key)}
                >
                  {c.count}
                </div>
              );
            })
          ) : (
            <span
              className="cursor-pointer"
              style={{ color: "#259fb2" }}
              onClick={(e) => onCallsClick()}
            >
              {data.totalCallsCnt}
            </span>
          )}
        </div>
      </td>
    );
  };
  const defaultViewByCols: GridColumnProps[] = [
    {
      width: 0,
      field: "cellMobile",
      cell: customCellMobile,
    },
    {
      field: "displayName",
      title: `${viewBy.displayName}`,
      cell: CustomViewByHeaderCell,
    },
    {
      field: "totalCalls",
      title: `${
        translationsLoading
          ? "Total Calls"
          : fetchLabelKeyTranslation("TotalCallsText", "Total Calls")
      }`,
      cell: CustomTotalCallsPropCell,
    },
  ];
  const [columns, setColumns] = useState<GridColumnProps[]>(defaultViewByCols);
  const [page, setPage] = useState<PageState>(initialDataState);
  const onPageChange = (event: GridPageChangeEvent) => {
    setPage(event.page);
  };
  const [data, setData] = useState<AggregateData[]>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  useEffect(() => {
    const buildViewTblData = async () => {
      setDataLoading(true);
      if (
        viewByFilterTblData &&
        viewByFilterTblData.rows.length > 0 &&
        viewByFilterTblData.rows[0].cells.length > 0
      ) {
        // set columns
        let foundUsersColumn = false;
        const cols: GridColumnProps[] = [];
        const row = viewByFilterTblData.rows[0];
        row.cells.forEach((c, idx) => {
          if (idx > 0) {
            switch (c.metricKeyLabel) {
              case "Users": {
                cols.push({
                  field: "topUsers",
                  title: c.columnName,
                  cell: CustomTopUsersPropCell,
                });
                foundUsersColumn = true;
                break;
              }
              case "Sentiments": {
                cols.push({
                  field: "sentiments",
                  title: c.columnName,
                  cell: CustomSentimentsCell,
                });
                break;
              }
              case "Topics": {
                if (auth?.checkTopicAvailibility()) {
                  cols.push({
                    field: "topTopics",
                    title: c.columnName,
                    cell: CustomTopTopicsPropCell,
                  });
                }
                break;
              }
              case "Moments": {
                cols.push({
                  field: "topMoments",
                  title: c.columnName,
                  cell: CustomTopMomentsPropCell,
                });
                break;
              }
            }
          }
        });
        setColumns([
          defaultViewByCols[0],
          defaultViewByCols[1],
          defaultViewByCols[2],
          ...cols,
        ]);

        const uniqueUserIds: string[] = [];
        if (foundUsersColumn) {
          viewByFilterTblData.rows.forEach((r) => {
            r.cells.forEach((c) => {
              if (c.metricKeyLabel === "Users") {
                c.metricKeyCounts.forEach((kc) => {
                  if (!uniqueUserIds.includes(kc.metricKey)) {
                    uniqueUserIds.push(kc.metricKey);
                  }
                });
              }
            });
          });
        } else if (viewBy.name === "Users") {
          viewByFilterTblData.rows.forEach((r) => {
            const id = r.cells[0].metricKeyCounts[0].metricKey;
            if (!uniqueUserIds.includes(id)) {
              uniqueUserIds.push(id);
            }
          });
        }

        let tinyUsers: { [key: string]: TinyUser } = {};
        let error = false;
        try {
          const res = await usersService.getTinyUsers(uniqueUserIds);
          tinyUsers = res;
        } catch (err) {
          console.error(err);
          error = true;
        }

        // set data
        const updatedData: AggregateData[] = [];
        viewByFilterTblData.rows.map((r) => {
          const dataRow: AggregateData = {
            name: "",
            displayName: "N/A",
            totalCallsCnt: 0,
            subKeyCallsCnt: [],
            additionalProperties: {},
          };
          const cell = r.cells[0];
          dataRow.name = cell.metricKeyCounts[0].metricKey;
          if (viewBy.name === "Users") {
            dataRow.displayName = JSON.stringify(
              tinyUsers[cell.metricKeyCounts[0].metricKey]
            );
          } else {
            dataRow.displayName = processMetricKeyForDisplay(
              viewBy.name,
              cell.metricKeyCounts[0].metricKey
            );
          }

          Object.keys(cell.metricKeyCounts[0].additionalProperties).forEach(
            (a) => {
              dataRow.additionalProperties[a] =
                cell.metricKeyCounts[0].additionalProperties[a];
            }
          );

          dataRow.subKeyCallsCnt = [];
          if (cell.metricKeyCounts[0].subKeyCounts.length > 0) {
            cell.metricKeyCounts[0].subKeyCounts.forEach((c) => {
              dataRow.subKeyCallsCnt?.push(c);
            });
          }
          dataRow.totalCallsCnt = cell.metricKeyCounts[0].totalCount;
          if (dataRow.totalCallsCnt > 0) {
            for (let i = 1; i < r.cells.length; i++) {
              switch (r.cells[i].metricKeyLabel) {
                case "Users": {
                  const userCell: UsersInsightMetricCell = {
                    columnName: r.cells[i].columnName,
                    metricKeyLabel: r.cells[i].metricKeyLabel,
                    metricKeyCounts: [],
                  };
                  if (!error) {
                    userCell.metricKeyCounts = r.cells[i].metricKeyCounts.map(
                      (c) => {
                        return {
                          metricKey: tinyUsers[c.metricKey],
                          count: c.totalCount,
                        };
                      }
                    );
                  }
                  dataRow.topUsers = userCell;
                  break;
                }
                case "Sentiments": {
                  dataRow.sentiments = r.cells[i];
                  break;
                }
                case "Topics": {
                  dataRow.topTopics = r.cells[i];
                  break;
                }
                case "Moments": {
                  dataRow.topMoments = r.cells[i];
                  break;
                }
              }
            }
            updatedData.push(dataRow);
          }
        });
        setData(updatedData);
      }
      setDataLoading(false);
    };

    if (viewByFilterTblData) {
      buildViewTblData();
    }
  }, [viewByFilterTblData]);

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
        "InsightsViewByGrid"
      ]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  return (
    <React.Fragment>
      <div className="row p-t-10 p-r-20 p-l-20 p-b-1">
        <div className="col-md-12">
          <div className="bg-white d-flex justify-content-between">
            <span className="font-weight-semi d-flex align-items-center">
              {`${
                translationsLoading
                  ? "View By"
                  : fetchLabelKeyTranslation("ViewByText", "View By")
              } ${viewBy.displayName}`}
            </span>
          </div>
        </div>
      </div>
      <div className="row p-t-10">
        <div className="col-md-12">
          <div className="maxTableCol tableList">
            <div className="table-container table-responsive table-overflow-hedden borderLeftSecondChild table-mobile">
              <Grid
                data={data?.slice(page.skip, page.take + page.skip)}
                skip={page.skip}
                take={page.take}
                total={data?.length}
                pageable={{ buttonCount: 4, pageSizes: true }}
                onPageChange={onPageChange}
                //resizable={true}
              >
                <GridNoRecords>
                  {!viewByFilterTblData && dataLoading && (
                    <Loader type={"infinite-spinner"} />
                  )}
                  {viewByFilterTblData &&
                    !dataLoading &&
                    !error &&
                    data &&
                    data.length === 0 && (
                      <span className="fs-15">
                        {`${
                          translationsLoading
                            ? "No records matching with this filter!"
                            : fetchLabelKeyTranslation(
                                "No records matching with this filter!",
                                "No records matching with this filter!"
                              )
                        }`}
                      </span>
                    )}
                  {error && (
                    <span className="fs-15">
                      <i className="bi bi-exclamation-triangle-fill tx-amber"></i>{" "}
                      {`${
                        translationsLoading
                          ? "Uh Oh! Something Went Wrong. Please Try Again!"
                          : fetchLabelKeyTranslation(
                              "FailedTextMsg",
                              "Uh Oh! Something Went Wrong. Please Try Again!"
                            )
                      }`}
                    </span>
                  )}
                </GridNoRecords>
                {columns.map((column, idx) => {
                  return <GridColumn key={idx} {...column} />;
                })}
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default InsightViewByGrid;
