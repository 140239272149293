import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { BrandProvider } from "./contexts/BrandProvider";
import { LocaleProvider } from "./contexts/LocaleProvider";
import { AuthProvider } from "./contexts/AuthProvider";
import { MasterDataProvider } from "./contexts/MasterDataProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const hostname = window.location.hostname;
export const appUrl =
  hostname === "localhost" ? `http://${hostname}:3000` : `https://${hostname}`;
const clientInstance = new PublicClientApplication({
  auth: {
    clientId: `${process.env.REACT_APP_CLIENTID}`,
    redirectUri: `${appUrl}/`,
    postLogoutRedirectUri: appUrl,
  },
});

root.render(
  <Router>
    <BrandProvider>
      <LocaleProvider>
        <AuthProvider>
          <MsalProvider instance={clientInstance}>
            <MasterDataProvider>
              <Routes>
                <Route path="/*" element={<App />} />
              </Routes>
            </MasterDataProvider>
          </MsalProvider>
        </AuthProvider>
      </LocaleProvider>
    </BrandProvider>
  </Router>
);
