import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import CustomUserGridInfo from "../../../../components/custom/grid/CustomUserGridInfo";
import { TinyUser, User } from "../../../../types/user";
import { AccessPermissionEnum } from "../../../../enums/accessPermissionEnum";
import AddUserDialog from "./AddUserDialog";
import useLocale from "../../../../hooks/useLocale";
import { Dictionary } from "../../../../types/Dictionary";
import usersService from "../../../../services/users.service";
import useAuth from "../../../../hooks/useAuth";
import useLocalStorage from "../../../../hooks/useLocalStorage";
import { navData } from "../../../../data/NavigationData";
import NotFoundError from "../../../error/NotFoundError";
import CustomSearchFieldTextInput from "../../../../components/custom/form/CustomSearchFieldTextInput";
import { Tooltip } from "@progress/kendo-react-tooltip";
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { Switch, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { CardsPageFilter } from "../../../../types/localstorage-filters/CardsPageFilter";
import React from "react";
import UnauthorizedAccess from "../../../error/UnauthorizedAccess";
import useSwal from "../../../../hooks/useSwal";
import useMasterData from "../../../../hooks/useMasterData";

const alphabetFilter: string[] = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

const apLocaleKeyName: { [key: string]: string } = {
  "Super Admin": "SuperAdmin",
  "Recording Admin": "RecordingAdmin",
  "Playback Supervisor": "PlaybackSupervisor",
  "Recording Agent": "RecordingAgent",
};

const initialDataState: CardsPageFilter = {
  searchTerm: "",
  selectedTab: 0,
  selectedAlphabet: "a",
};

interface CustomTabTitleProp {
  name: string;
  count: number;
}

interface CustomTabStripTabProps {
  name: string;
}

interface CustomUserCardDetailProps {
  user: User;
}

interface UserManagementProps {}

const CustomTabTitle = (props: CustomTabTitleProp) => {
  return (
    <span>
      <span>{props.name}</span>
      <span className="tabBadge m-l-2">{props.count}</span>
    </span>
  );
};

const UserManagement: React.FC<UserManagementProps> = () => {
  const master = useMasterData();
  const auth = useAuth();
  const Swal = useSwal();
  const localeCtx = useLocale();
  const location = useLocation();
  const backActive = location?.state?.backActive === true;
  const [localStorageFilter, setLocalStorageFilter] =
    useLocalStorage<CardsPageFilter>(
      "localStorageUserFilterData",
      initialDataState
    );
  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(localeCtx?.selectedLocale?.current.componentTranslations["UserManagement"]);
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>();
  const [selectedAlphabet, setSelectedAlphabet] = useState<string>(
    backActive ? localStorageFilter.selectedAlphabet : "A"
  );
  const [selectedTab, setSelectedTab] = useState<number>(
    backActive ? localStorageFilter.selectedTab : 0
  );
  const [searchTerm, setSearchTerm] = useState<string>(
    backActive ? localStorageFilter.searchTerm : ""
  );
  const [addUserDialogVisible, setAddUserDialogVisible] =
    useState<boolean>(false);

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations("UserManagement");
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations[
          "UserManagement"
        ]
      );
      localeCtx?.setPreviousAppLocale("UserManagement");
      if (localeCtx?.localeSwitchFailed) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        });
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  useEffect(() => {
    const sendRequest = async () => {
      try {
        setLoading(true);
        const data = await usersService.fetchUsers();
        setUsers(data);
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        }
      } finally {
        setLoading(false);
      }
    };
    sendRequest();
  }, []);

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
        "UserManagement"
      ]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  const handleTabSelection = (e: TabStripSelectEventArguments) => {
    setSelectedTab(e.selected);
  };

  const handleFilterData = () => {
    const data: CardsPageFilter = {
      searchTerm: searchTerm,
      selectedTab: selectedTab,
      selectedAlphabet: selectedAlphabet,
    };
    setLocalStorageFilter(data);
  };

  const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchTerm(e.currentTarget.value);
  };

  const handleAlphabetFilterChange = (value: string) => {
    setSelectedAlphabet(value);
    scrollToUserCard(value);
  };

  const scrollToUserCard = (value: string) => {
    if (value.trim() !== "") {
      let idValue = "userCardId_" + value.trim().toLocaleLowerCase();
      var element = document.getElementById(idValue);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const searchUsers = (user: User) => {
    const searchVal = searchTerm.trim().toLocaleLowerCase();
    if (
      user.firstName &&
      user.firstName.toLocaleLowerCase().includes(searchVal)
    )
      return true;
    if (user.lastName && user.lastName.toLocaleLowerCase().includes(searchVal))
      return true;
    if (user.email?.toLocaleLowerCase().includes(searchVal)) return true;

    return false;
  };

  const toggleAddUserDialog = () => {
    setAddUserDialogVisible(!addUserDialogVisible);
  };

  const addNewUser = (newUser: User) => {
    const updatedUsers = [...users, newUser];
    setUsers(updatedUsers);
    master?.fetchUpdatedUsers();
  };

  const deleteUser = (user: User) => {
    if (user.id === auth?.user?.id) {
      return;
    }
    Swal.fire({
      title: `${
        translationsLoading
          ? "Are you sure?"
          : fetchLabelKeyTranslation("SwtAltChangeTitle", "Are you sure?")
      }`,
      text: `${
        translationsLoading
          ? "You want to delete this User."
          : fetchLabelKeyTranslation(
              "SwtAltDeleteUserText",
              "You want to delete this User."
            )
      }`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `${
        translationsLoading
          ? "Continue"
          : fetchLabelKeyTranslation("ContinueButton", "Continue")
      }`,
      cancelButtonText: `${
        translationsLoading
          ? "Cancel"
          : fetchLabelKeyTranslation("CancelButton", "Cancel")
      }`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await usersService
          .deleteUser(user.id)
          .then(async () => {
            setUsers([...users.filter((ele) => ele.id !== user.id)]);
            master?.fetchUpdatedUsers();
            Swal.fire({
              icon: "success",
              title: `${
                translationsLoading
                  ? "User Deleted"
                  : fetchLabelKeyTranslation("SwtAltUserAdded", "User Deleted")
              }`,
              confirmButtonText: `${
                translationsLoading
                  ? "OK"
                  : fetchLabelKeyTranslation("OKText", "OK")
              }`,
            });
          })
          .catch((err) => {
            if (err instanceof Error) {
              Swal.fire({
                icon: "error",
                title: `${
                  translationsLoading
                    ? "Oops…"
                    : fetchLabelKeyTranslation("SwtAltTitle", "Oops…")
                }`,
                text: `${
                  translationsLoading
                    ? "Something went wrong! Please Try again…"
                    : fetchLabelKeyTranslation(
                        "SwtAltWrong",
                        "Something went wrong! Please Try again…"
                      )
                }`,
                confirmButtonText: `${
                  translationsLoading
                    ? "OK"
                    : fetchLabelKeyTranslation("OKText", "OK")
                }`,
              });
            }
          });
      }
    });
  };

  const updateUserLogin = async (user: User, loginEnabled: boolean) => {
    await usersService
      .updateUserLogin(user.id, loginEnabled)
      .then(async () => {
        let _users = users.map((u) => {
          if (u.id === user.id) {
            return { ...u, loginEnabled: loginEnabled };
          } else {
            return { ...u };
          }
        });
        setUsers(_users);
        Swal.fire({
          icon: "success",
          title: `${
            translationsLoading
              ? "User Login Updated"
              : fetchLabelKeyTranslation(
                  "SwtAltUserAdded",
                  "User Login Updated"
                )
          }`,
          confirmButtonText: `${
            translationsLoading
              ? "OK"
              : fetchLabelKeyTranslation("OKText", "OK")
          }`,
        });
      })
      .catch((err) => {
        if (err instanceof Error) {
          Swal.fire({
            icon: "error",
            title: `${
              translationsLoading
                ? "Oops…"
                : fetchLabelKeyTranslation("SwtAltTitle", "Oops…")
            }`,
            text: `${
              translationsLoading
                ? "Something went wrong! Please Try again…"
                : fetchLabelKeyTranslation(
                    "SwtAltWrong",
                    "Something went wrong! Please Try again…"
                  )
            }`,
            confirmButtonText: `${
              translationsLoading
                ? "OK"
                : fetchLabelKeyTranslation("OKText", "OK")
            }`,
          });
        }
      });
  };

  const GetFilteredUsers = (profileName: string) => {
    let _filteredUsers = users
      ?.filter(
        (u) =>
          searchUsers(u) &&
          (profileName === "All" ||
            (profileName === "Other"
              ? u.authorizationProfile?.isSystemDefined === false
              : u.authorizationProfile?.name === profileName))
      )
      .sort((a, b) => {
        let a_name = (
          (a.firstName ? a.firstName : "") +
          " " +
          (a.lastName ? a.lastName : "")
        )
          .trim()
          .toLocaleLowerCase();
        let b_name = (
          (b.firstName ? b.firstName : "") +
          " " +
          (b.lastName ? b.lastName : "")
        )
          .trim()
          .toLocaleLowerCase();
        return a_name > b_name ? 1 : -1;
      });
    return _filteredUsers.filter((u) => !u.isArchived);
  };

  const CustomUserCardDetail = (
    userCardDetailProps: CustomUserCardDetailProps
  ) => {
    const user = userCardDetailProps.user;
    const [loginLoading, setLoginLoading] = useState<boolean>(false);
    const _userName = `${user.firstName ? user.firstName : ""} ${
      user.lastName ? user.lastName : ""
    }`.trim();
    let idValue;
    if (_userName !== "" && _userName.length > 0) {
      idValue = "userCardId_" + _userName.charAt(0).toLocaleLowerCase();
    }
    const handleLoginClick = async (event: SwitchChangeEvent) => {
      setLoginLoading(true);
      await updateUserLogin(user, event.value);
      setLoginLoading(false);
    };

    return (
      <div className="col col-md-3" key={user.id} id={idValue}>
        <div className="cardListBlock">
          <div className="cardRows">
            <div className="cardColumn">
              <div className="colUser">
                <CustomUserGridInfo
                  userInfo={{
                    id: user.id,
                    loginUserId: user.loginUserId,
                    firstName: user.firstName ? user.firstName : "",
                    lastName: user.lastName ? user.lastName : "",
                    image: user.image ? user.image : "",
                    email: user.email,
                    phoneNo: "",
                    isArchived: user.isArchived,
                  }}
                />
              </div>
              <div className="lblGroup float-left w-100">
                <div className="fs-12 font-weight-semi p-t-7 p-b-7 text-truncate">
                  <span>
                    {user.authorizationProfile?.name !== undefined
                      ? translationsLoading
                        ? user.authorizationProfile?.name
                        : fetchLabelKeyTranslation(
                            apLocaleKeyName[user.authorizationProfile?.name] ??
                              "",
                            user.authorizationProfile?.name
                          )
                      : ""}
                  </span>
                </div>
                <div className="fs-12 font-weight-semi p-t-7 p-b-7 text-truncate">
                  <span className="float-left text-black-9 p-b-2">
                    {translationsLoading
                      ? "License"
                      : fetchLabelKeyTranslation(
                          "UserManagementColumnLicense",
                          "License"
                        )}
                    : &nbsp;
                  </span>
                  <span>
                    {user.license
                      ? user.license?.name
                      : translationsLoading
                      ? "None"
                      : fetchLabelKeyTranslation("NoneText", "None")}
                  </span>
                </div>
                <div className="minTextLabel p-t-1">
                  <span
                    className="float-left text-black-9 p-b-2"
                    style={{ minWidth: "77px" }}
                  >
                    {translationsLoading
                      ? "Recorded Device"
                      : fetchLabelKeyTranslation(
                          "UserManagementColumnRecordedDevice",
                          "Recorded Device"
                        )}
                    :
                  </span>
                  <span className="p-l-3">
                    <span className="badgeList">
                      <div className="keywordRow">
                        {user.devices.length > 0 ? (
                          user.devices.map((device) => {
                            return (
                              <div
                                className="keywordTrk"
                                key={device.deviceIdentifier}
                              >
                                {device.deviceIdentifier}
                              </div>
                            );
                          })
                        ) : (
                          <div className="keywordTrk">None</div>
                        )}
                      </div>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="blockIconButton">
            <span className="btnBlocks">
              <div
                className="switchButton"
                title={user.loginEnabled ? "Login Enabled" : "Login Disabled"}
              >
                {loginLoading ? (
                  <Loader />
                ) : (
                  <Switch
                    checked={user.loginEnabled}
                    onChange={(event) => handleLoginClick(event)}
                  />
                )}
              </div>
            </span>
            <span className="btnBlocks">
              <Link
                to={`user/${user.id}`}
                className="btnBlock-a"
                onClick={handleFilterData}
                state={{ ...navData, from: location.pathname }}
              >
                <i className="bi bi-pencil fs-17"></i>
              </Link>
            </span>
            {user.id !== auth?.user?.id && (
              <span className="btnBlocks">
                <a
                  className="btnBlock-a cursor-pointer"
                  title="Delete"
                  onClick={() => deleteUser(user)}
                >
                  <i className="bi bi-trash3 fs-17"></i>
                </a>
              </span>
            )}
          </div>
        </div>
      </div>
    );
  };

  const CustomTabStripTab = (tabStripProps: CustomTabStripTabProps) => {
    let _users = GetFilteredUsers(tabStripProps.name);

    return (
      <div className="cardBrand">
        <div className="cardListArea">
          <div className="row">
            {loading ? (
              <div className="col-md-12 d-flex justify-content-center align-items-center">
                <Loader type={"infinite-spinner"} />
              </div>
            ) : _users && _users.length > 0 ? (
              _users.map((user) => {
                return <CustomUserCardDetail user={user} />;
              })
            ) : (
              <div className="col-md-12 p-l-5">
                <div className="dashedBox p-10 m-b-20 float-left w-100 radius-6 border-black-3 border-w-2 border-dashed d-flex align-items-center justify-content-center">
                  <span className="fs-13 text-black-9 p-t-20 p-b-20">
                    {translationsLoading
                      ? "No Users Available"
                      : fetchLabelKeyTranslation(
                          "UserManagementInfoMsgNoUsers",
                          "No Users Available"
                        )}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {!auth?.checkUserAccess(AccessPermissionEnum.ManageUsersAndGroups) ? (
        <UnauthorizedAccess />
      ) : (
        <Tooltip anchorElement={"target"} parentTitle={true} position={"top"}>
          <div className="contentPushArea boxUsers h-100">
            <div className="contentHead h-100">
              <div className="contentHeadTop d-flex justify-content-between align-items-center">
                <div className="contentHeadTitle fs-18">
                  {translationsLoading
                    ? "Users"
                    : fetchLabelKeyTranslation("UserManagementTitle", "Users")}
                </div>
                <div className="contentHeadAction">
                  <div className="buttons-container hov-transparent d-flex align-itmes-center p-0">
                    <div className="searchCol searchBox m-r-10">
                      <CustomSearchFieldTextInput
                        className="input-search"
                        placeholder={`${
                          translationsLoading
                            ? "Search..."
                            : fetchLabelKeyTranslation(
                                "UserManagementSearchBoxPlaceHolder",
                                "Search..."
                              )
                        }`}
                        value={searchTerm}
                        onEscapeKeyFunc={true}
                        searchTextChangeHandler={handleSearchChange}
                        updateValue={setSearchTerm}
                      />
                    </div>
                    <Button
                      onClick={toggleAddUserDialog}
                      className={`btn bg-primary text-white fs-13 fw-normal ${
                        !auth?.checkUserAccess(
                          AccessPermissionEnum.ManageUsersAndGroups
                        )
                          ? "disabledBtn"
                          : ""
                      }`}
                      style={{ height: "29px", margin: "-1px 0 0 0" }}
                      disabled={
                        !auth?.checkUserAccess(
                          AccessPermissionEnum.ManageUsersAndGroups
                        )
                      }
                      title={`${
                        translationsLoading
                          ? "Add"
                          : fetchLabelKeyTranslation(
                              "UserManagementAddButton",
                              "Add"
                            )
                      }`}
                    >
                      {translationsLoading
                        ? "Add"
                        : fetchLabelKeyTranslation(
                            "UserManagementAddButton",
                            "Add"
                          )}
                    </Button>
                    {addUserDialogVisible && (
                      <AddUserDialog
                        toggleDialog={toggleAddUserDialog}
                        addNewUser={addNewUser}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="contentHeadTab h-100">
                <div className="tabLabel tabLeftPadding-0 h-100">
                  <TabStrip
                    selected={selectedTab}
                    onSelect={handleTabSelection}
                  >
                    <TabStripTab
                      title={
                        <CustomTabTitle
                          name={
                            translationsLoading
                              ? "All"
                              : fetchLabelKeyTranslation("AllTabTxt", "All")
                          }
                          count={loading ? 0 : GetFilteredUsers("All").length}
                        />
                      }
                    >
                      <CustomTabStripTab name="All" />
                    </TabStripTab>
                    <TabStripTab
                      title={
                        <CustomTabTitle
                          name={
                            translationsLoading
                              ? "Super Admin"
                              : fetchLabelKeyTranslation(
                                  "SuperAdmin",
                                  "Super Admin"
                                )
                          }
                          count={
                            loading ? 0 : GetFilteredUsers("Super Admin").length
                          }
                        />
                      }
                    >
                      <CustomTabStripTab name="Super Admin" />
                    </TabStripTab>
                    <TabStripTab
                      title={
                        <CustomTabTitle
                          name={
                            translationsLoading
                              ? "Recording Admin"
                              : fetchLabelKeyTranslation(
                                  "RecordingAdmin",
                                  "Recording Admin"
                                )
                          }
                          count={
                            loading
                              ? 0
                              : GetFilteredUsers("Recording Admin").length
                          }
                        />
                      }
                    >
                      <CustomTabStripTab name="Recording Admin" />
                    </TabStripTab>
                    <TabStripTab
                      title={
                        <CustomTabTitle
                          name={
                            translationsLoading
                              ? "Playback Supervisor"
                              : fetchLabelKeyTranslation(
                                  "PlaybackSupervisor",
                                  "Playback Supervisor"
                                )
                          }
                          count={
                            loading
                              ? 0
                              : GetFilteredUsers("Playback Supervisor").length
                          }
                        />
                      }
                    >
                      <CustomTabStripTab name="Playback Supervisor" />
                    </TabStripTab>
                    <TabStripTab
                      title={
                        <CustomTabTitle
                          name={
                            translationsLoading
                              ? "Recording Agent"
                              : fetchLabelKeyTranslation(
                                  "RecordingAgent",
                                  "Recording Agent"
                                )
                          }
                          count={
                            loading
                              ? 0
                              : GetFilteredUsers("Recording Agent").length
                          }
                        />
                      }
                    >
                      <CustomTabStripTab name="Recording Agent" />
                    </TabStripTab>
                    <TabStripTab
                      title={
                        <CustomTabTitle
                          name={
                            translationsLoading
                              ? "Other"
                              : fetchLabelKeyTranslation("OtherTabTxt", "Other")
                          }
                          count={loading ? 0 : GetFilteredUsers("Other").length}
                        />
                      }
                    >
                      <CustomTabStripTab name="Other" />
                    </TabStripTab>
                  </TabStrip>
                </div>
                <div className="cardWordFilter">
                  <div className="wordFilterTitle">All</div>
                  <div className="wordFilterBox">
                    {alphabetFilter.map((e) => {
                      const isSeleted =
                        selectedAlphabet.toLocaleLowerCase() ===
                        e.toLocaleLowerCase();
                      return (
                        <div
                          className={`wordFilterLetter ${
                            isSeleted && "activeLetter"
                          }`}
                          onClick={() => handleAlphabetFilterChange(e)}
                          key={e}
                        >
                          {e}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default UserManagement;
