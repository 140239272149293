import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { useEffect, useRef, useState } from "react";
import CustomInput from "../../../components/custom/form/CustomInput";
import useLocale from "../../../hooks/useLocale";
import { Dictionary } from "../../../types/Dictionary";
import {
  InsertScorecardQuestionGroup,
  Scorecard,
  ScorecardQuestionGroup,
  UpdateScorecardQuestionGroup,
} from "../../../types/scorecard";
import { axiosWithAuth } from "../../../utils/customAxios";
import useSwal from "../../../hooks/useSwal";
import { SweetAlertOptions } from "sweetalert2";

interface IUpsertQuestionDialogBox {
  toggleDialog: (questionGroup: ScorecardQuestionGroup | undefined) => void;
  questionGroup?: ScorecardQuestionGroup;
  scorecardId?: String;
  upsertSC: () => void;
  totalQuestionGroups: number;
  setSelected: React.Dispatch<React.SetStateAction<number>>;
}

const UpsertQuestionGroupDialog: React.FC<IUpsertQuestionDialogBox> = ({
  toggleDialog,
  questionGroup,
  scorecardId,
  upsertSC,
  totalQuestionGroups,
  setSelected,
}) => {
  const localeCtx = useLocale();
  const swal = useSwal();
  const formRef = useRef<any>();

  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(
    localeCtx?.selectedLocale?.current.componentTranslations[
      "UpsertQuestionGroupDialogBox"
    ]
  );

  useEffect(() => {
    fetchTranslations();
  }, [localeCtx?.selectedLocale]);

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations(
        "UpsertQuestionGroupDialogBox"
      );
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations[
          "UpsertQuestionGroupDialogBox"
        ]
      );
      localeCtx?.setPreviousAppLocale("UpsertQuestionGroupDialogBox");
      if (localeCtx?.localeSwitchFailed) {
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        };
        swal.fire(swalOptions);
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const nameValidator = (value: string) => {
    if (!value) {
      return `${
        translationsLoading
          ? "Scorecard question group name cannot be empty"
          : fetchLabelKeyTranslation(
              "SCQuestionGrpNameValidationEmptyMsg",
              "Scorecard question group name cannot be empty"
            )
      }`;
    }
    if (value.length > 100) {
      return `${
        translationsLoading
          ? "Scorecard question group name length cannot be more than 100!"
          : fetchLabelKeyTranslation(
              "SCQuestionGrpNameValidationLengthMsg",
              "Scorecard question group name length cannot be more than 100!"
            )
      }`;
    }

    return "";
  };

  const addHandler = async (newQuestionGroup: InsertScorecardQuestionGroup) => {
    await axiosWithAuth
      .post(`scorecard/${scorecardId}/scorecardquestiongroup`, newQuestionGroup)
      .then((response) => {
        setSelected(totalQuestionGroups);
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "success",
          title: `${
            translationsLoading
              ? "Scorecard question group added."
              : fetchLabelKeyTranslation(
                  "SwtAltQuestionGrpAddedTitle",
                  "Scorecard question group added."
                )
          }`,
          confirmButtonText: `${
            translationsLoading
              ? "OK"
              : fetchLabelKeyTranslation("OKText", "OK")
          }`,
        };
        swal.fire(swalOptions);
      })
      .catch((err) => {
        if (err.response.status === 405) {
          const swalOptions: SweetAlertOptions<any, any> = {
            icon: "info",
            title: `${
              translationsLoading
                ? "Scorecard cannot be updated"
                : fetchLabelKeyTranslation(
                    "SwtAltAddFailedTitle",
                    "Scorecard cannot be updated"
                  )
            }`,
            text: `${
              translationsLoading
                ? "You can archive the Scorecard"
                : fetchLabelKeyTranslation(
                    "SwtAltAddFailedText",
                    "You can archive the Scorecard"
                  )
            }`,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: `${
              translationsLoading
                ? "Yes, Archive it!"
                : fetchLabelKeyTranslation(
                    "SwtAltArchiveCfnBtnText",
                    "Yes, Archive it!"
                  )
            }`,
            cancelButtonText: `${
              translationsLoading
                ? "Cancel"
                : fetchLabelKeyTranslation("CancelText", "Cancel")
            }`,
          };
          swal.fire(swalOptions).then(async (result) => {
            if (result.isConfirmed) {
              const updatedScorecard: Scorecard = {
                id: scorecardId ? Number(scorecardId) : 0,
                scorecardName: "",
                description: "",
                isArchived: true,
              };
              await axiosWithAuth
                .put(`/scorecard/${scorecardId}`, updatedScorecard)
                .then(async (response) => {
                  const swalOptions: SweetAlertOptions = {
                    icon: "success",
                    title: `${
                      translationsLoading
                        ? "Scorecard Archived"
                        : fetchLabelKeyTranslation(
                            "SwtAltArchiveSuccessTitle",
                            "Scorecard Archived"
                          )
                    }`,
                    text: `${
                      translationsLoading
                        ? "Scorecard has been archived Sucessfully"
                        : fetchLabelKeyTranslation(
                            "SwtAltArchiveSuccessText",
                            "Scorecard has been archived Sucessfully"
                          )
                    }`,
                    confirmButtonText: `${
                      translationsLoading
                        ? "OK"
                        : fetchLabelKeyTranslation("OKText", "OK")
                    }`,
                  };
                  swal.fire(swalOptions);
                  await upsertSC();
                });
            }
          });
        } else if (err.response.status === 400) {
          const swalOptions: SweetAlertOptions<any, any> = {
            icon: "warning",
            title: `${
              translationsLoading
                ? "Something went wrong!"
                : fetchLabelKeyTranslation(
                    "SwtAltFailedTxt",
                    "Something went wrong!"
                  )
            }`,
            text: `${
              translationsLoading
                ? "Scorecard can not have 2 question Group with same name."
                : fetchLabelKeyTranslation(
                    "InvalidSCGroupNameText",
                    "Scorecard can not have 2 question Group with same name."
                  )
            }`,
            confirmButtonText: `${
              translationsLoading
                ? "OK"
                : fetchLabelKeyTranslation("OKText", "OK")
            }`,
          };
          swal.fire(swalOptions);
        } else {
          const swalOptions: SweetAlertOptions<any, any> = {
            icon: "warning",
            title: `${
              translationsLoading
                ? "Something went wrong!"
                : fetchLabelKeyTranslation(
                    "SwtAltFailedTxt",
                    "Something went wrong!"
                  )
            }`,
            text: `${
              translationsLoading
                ? "Scorecard question group updation has been failed"
                : fetchLabelKeyTranslation(
                    "SwtAltFailedText",
                    "Scorecard question group updation has been failed"
                  )
            }`,
            confirmButtonText: `${
              translationsLoading
                ? "OK"
                : fetchLabelKeyTranslation("OKText", "OK")
            }`,
          };
          swal.fire(swalOptions);
        }
      });
    toggleDialog(undefined);
    await upsertSC();
  };

  const editHandler = async (
    newQuestionGroup: UpdateScorecardQuestionGroup
  ) => {
    await axiosWithAuth
      .put(
        `scorecard/${scorecardId}/scorecardquestiongroup/${questionGroup?.id}`,
        newQuestionGroup
      )
      .then((response) => {
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "success",
          title: `${
            translationsLoading
              ? "Scorecard question Group is Updated"
              : fetchLabelKeyTranslation(
                  "SwtAltQuestionGrpUpdatedTitle",
                  "Scorecard question Group is Updated"
                )
          }`,
          text: `${
            translationsLoading
              ? "Scorecard question group has been updated"
              : fetchLabelKeyTranslation(
                  "SwtAltQuestionGrpUpdatedText",
                  "Scorecard question group has been updated"
                )
          }`,
          confirmButtonText: `${
            translationsLoading
              ? "OK"
              : fetchLabelKeyTranslation("OKText", "OK")
          }`,
        };
        swal.fire(swalOptions);
      })
      .catch((err) => {
        if (err.response.status === 405) {
          const swalOptions: SweetAlertOptions<any, any> = {
            icon: "info",
            title: `${
              translationsLoading
                ? "Scorecard cannot be updated"
                : fetchLabelKeyTranslation(
                    "SwtAltAddFailedTitle",
                    "Scorecard cannot be updated"
                  )
            }`,
            text: `${
              translationsLoading
                ? "You can archive the Scorecard"
                : fetchLabelKeyTranslation(
                    "SwtAltAddFailedText",
                    "You can archive the Scorecard"
                  )
            }`,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: `${
              translationsLoading
                ? "Yes, Archive it!"
                : fetchLabelKeyTranslation(
                    "SwtAltArchiveCfnBtnText",
                    "Yes, Archive it!"
                  )
            }`,
            cancelButtonText: `${
              translationsLoading
                ? "Cancel"
                : fetchLabelKeyTranslation("CancelText", "Cancel")
            }`,
          };
          swal.fire(swalOptions).then(async (result) => {
            if (result.isConfirmed) {
              const updatedScorecard: Scorecard = {
                id: scorecardId ? Number(scorecardId) : 0,
                scorecardName: "",
                description: "",
                isArchived: true,
              };
              await axiosWithAuth
                .put(`/scorecard/${scorecardId}`, updatedScorecard)
                .then(async (response) => {
                  const swalOptions: SweetAlertOptions = {
                    icon: "success",
                    title: `${
                      translationsLoading
                        ? "Scorecard Archived"
                        : fetchLabelKeyTranslation(
                            "SwtAltArchiveSuccessTitle",
                            "Scorecard Archived"
                          )
                    }`,
                    text: `${
                      translationsLoading
                        ? "Scorecard has been archived Sucessfully"
                        : fetchLabelKeyTranslation(
                            "SwtAltArchiveSuccessText",
                            "Scorecard has been archived Sucessfully"
                          )
                    }`,
                    confirmButtonText: `${
                      translationsLoading
                        ? "OK"
                        : fetchLabelKeyTranslation("OKText", "OK")
                    }`,
                  };
                  swal.fire(swalOptions);
                });
            }
          });
        } else {
          swal.fire({
            icon: "warning",
            title: `${
              translationsLoading
                ? "Something went wrong!"
                : fetchLabelKeyTranslation(
                    "SwtAltFailedTxt",
                    "Something went wrong!"
                  )
            }`,
            text: `${
              translationsLoading
                ? "Scorecard question group updation has been failed"
                : fetchLabelKeyTranslation(
                    "SwtAltFailedText",
                    "Scorecard question group updation has been failed"
                  )
            }`,
            confirmButtonText: `${
              translationsLoading
                ? "OK"
                : fetchLabelKeyTranslation("OKText", "OK")
            }`,
          });
        }
      });
    toggleDialog(undefined);
    await upsertSC();
  };

  const handleUpsert = async () => {
    const form = formRef.current as Form;
    const insertNewquestionGroup: InsertScorecardQuestionGroup = {
      scorecardQuestionGroupName: form.values.questionGroupName,
    };
    const updateQuestionGroup: UpdateScorecardQuestionGroup = {
      id: questionGroup?.id ?? 0,
      scorecardQuestionGroupName: form.values.questionGroupName,
      description: questionGroup?.description,
      questions: questionGroup?.questions,
    };
    questionGroup
      ? await editHandler(updateQuestionGroup)
      : await addHandler(insertNewquestionGroup);
  };

  return (
    <Form
      ref={formRef}
      initialValues={{
        questionGroupName: questionGroup?.scorecardQuestionGroupName,
      }}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ maxWidth: 650 }}>
          <Dialog
            title={
              questionGroup
                ? `${
                    translationsLoading
                      ? "Edit Question Group"
                      : fetchLabelKeyTranslation(
                          "TitleEditQuestionGroup",
                          "Edit Question Group"
                        )
                  }`
                : `${
                    translationsLoading
                      ? "Add Question Group"
                      : fetchLabelKeyTranslation(
                          "TitleAddQuestionGroup",
                          "Add Question Group"
                        )
                  }`
            }
            onClose={() => toggleDialog(undefined)}
          >
            <div className="formAdd" style={{ marginBottom: "0px" }}>
              <div className="formBoxRow p-t-5 p-b-5">
                <div className="formBoxLabel fs-14">
                  {`${
                    translationsLoading
                      ? "Group Name"
                      : fetchLabelKeyTranslation(
                          "FieldQuesGroupText",
                          "Group Name"
                        )
                  }`}
                </div>
                <div className="formBoxAction">
                  <div className="formInput">
                    <Field
                      id="questionGroupName"
                      name="questionGroupName"
                      style={{ height: "32px" }}
                      value={formRenderProps.valueGetter("questionGroupName")}
                      placeholder={`${
                        translationsLoading
                          ? "Enter question group name..."
                          : fetchLabelKeyTranslation(
                              "FieldQuesGrpTextPlaceholder",
                              "Enter question group name..."
                            )
                      }`}
                      component={CustomInput}
                      validator={nameValidator}
                    />
                  </div>
                </div>
              </div>
            </div>
            <DialogActionsBar>
              <Button
                className={`btn bg-black-5`}
                onClick={() => toggleDialog(undefined)}
              >
                {`${
                  translationsLoading
                    ? "Cancel"
                    : fetchLabelKeyTranslation("CancelText", "Cancel")
                }`}
              </Button>
              <Button
                className={`btn bg-primary text-white ${
                  !formRenderProps.valid ? "disabledBtn" : ""
                }`}
                disabled={!formRenderProps.valid}
                onClick={handleUpsert}
              >
                {`${
                      translationsLoading
                        ? "Save"
                        : fetchLabelKeyTranslation("SaveBtnText", "Save")
                    }`}
              </Button>
            </DialogActionsBar>
          </Dialog>
        </FormElement>
      )}
    />
  );
};

export default UpsertQuestionGroupDialog;
