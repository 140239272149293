import jwt_decode from "jwt-decode";
import { SharedResourceToken } from "../types/shared-resource/SharedResourceToken";
import { TinyUser } from "../types/user";
import useAuth from "../hooks/useAuth";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import LoadingOverlay from "../components/LoadingOverlay";
import useSessionStorage from "../hooks/useSessionStorage";
import { TokenPayload } from "../types/context";
import sharedResourceService from "../services/sharedResource.service";
import { SweetAlertOptions } from "sweetalert2";
import useSwal from "../hooks/useSwal";

const SharedResourceRedirect: React.FC = () => {
  const swal = useSwal()
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const [shareToken, setShareToken] = useSessionStorage<string | undefined>(
    "ShareToken",
    undefined
  );

  useEffect(() => {
    console.log(searchParams.get("token"));
    console.log("Shared Route");
  }, []);

  useEffect(() => {
    const fetchShareAuthToken = async () => {
      const token = searchParams.get("token");
      console.log(token);
      if (token) {
        try {
          const decodedToken = jwt_decode<SharedResourceToken>(token);
          const sharedWithUserInfo: {
            IsExternalUser: boolean;
            SharedUserIdentifier: string;
          } = JSON.parse(decodedToken.SharedWith);

          const idx = decodedToken.ResourceUrl.indexOf("/shared");
          const url = decodedToken.ResourceUrl.substring(idx);
          const redirectURL = url.substring(7);

          const sharedByUser: TinyUser = JSON.parse(decodedToken.SharedBy);
          let shareUserAuthToken: string | undefined = undefined;
          if (sharedWithUserInfo.IsExternalUser) {
            try {
              shareUserAuthToken =
                await sharedResourceService.createGuestSharedResourceToken(
                  decodedToken.Id,
                  sharedByUser,
                  sharedWithUserInfo.SharedUserIdentifier,
                  token
                );
              console.log(shareUserAuthToken);
              setShareToken(shareUserAuthToken);
              navigate(`${url}`, { replace: true });
            } catch (err) {
              console.log(err);
              const swalOptions: SweetAlertOptions<any, any> = {
                icon: "error",
                title: "Invalid Token",
                text: "Share Token is either invalid/expired",
              }
              swal.fire(swalOptions);
              navigate("/login", { replace: true });
            } finally {
              setLoading(false);
            }
          } else {
            if (auth?.accessToken) {
              console.log("User Logged In");
              const sharedWithUserId = Number(
                sharedWithUserInfo.SharedUserIdentifier
              );
              console.log(sharedWithUserId);
              console.log(auth);
              const tokenPayload = jwt_decode<TokenPayload>(auth?.accessToken);
              if (sharedWithUserId !== tokenPayload?.UserId) {
                const swalOptions : SweetAlertOptions<any, any> = {
                  icon: "error",
                  title: "Invalid Token",
                  text: "Share Token is either invalid/expired",
                }
                swal.fire(swalOptions);
                navigate("/unauthorized", { replace: true });
              } else {
                console.log("Getting Internal Shared Resource Token");
                try {
                  console.log("Getting Internal Shared Resource Token");
                  shareUserAuthToken =
                    await sharedResourceService.createInternalSharedResourceToken(
                      decodedToken.Id,
                      sharedByUser,
                      token
                    );
                  console.log(shareUserAuthToken);
                  setShareToken(shareUserAuthToken);
                  console.log(url);
                  navigate(`${url}`, { replace: true });
                } catch (err) {
                  console.log(err);
                  const swalOptions : SweetAlertOptions<any, any> = {
                    icon: "error",
                    title: "Error",
                    text: "Invalid Share Token",
                  }
                  swal.fire(swalOptions);
                  navigate("/login", { replace: true });
                } finally {
                  setLoading(false);
                }
              }
            } else {
              console.log("Internal User and not logged In!!");
              navigate("/login?originalRedirectUri="+redirectURL, {
                state: { from: location },
                replace: true,
              });
              setLoading(false);
            }
          }
        } catch (err) {
          setLoading(false);
          navigate("/not-found", { replace: true });
        }
      } else {
        setLoading(false);
        navigate("/not-found", { replace: true });
      }
    };

    if (!shareToken) {
      fetchShareAuthToken();
    }
  }, [shareToken]);

  return (
    <div>
      {loading && (
        <LoadingOverlay
          customStyle={{ position: "fixed", marginTop: "55px" }}
          themeColor={"light"}
          size={"medium"}
          loadingText={"Redirecting..."}
        />
      )}
    </div>
  );
};

export default SharedResourceRedirect;
