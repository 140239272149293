import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Loader } from "@progress/kendo-react-indicators";
import { Input, NumericTextBox, Switch } from "@progress/kendo-react-inputs";
import {
  PanelBar,
  PanelBarItem,
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { useEffect, useState } from "react";
import CustomFormTextArea from "../../../components/custom/form/CustomFormTextArea";
import CustomInput from "../../../components/custom/form/CustomInput";
import { StorageModes } from "../../../enums";
import storageService from "../../../services/storage.service";
import { StorageLocation } from "../../../types/storage";
import AWSStorageLocation from "./storage-modes/AWSStorageLocation";
import AzureStorageLocation from "./storage-modes/AzureStorageLocation";
import FTPStorageLocation from "./storage-modes/FTPStorageLocation";
import SFTPStorageLocation from "./storage-modes/SFTPStorageLocation";
import CephStorageLocation from "./storage-modes/CephStorageLocation";
import "./StorageSettings.scss";
import { AxiosError } from "axios";
import { Dictionary } from "../../../types/Dictionary";
import useLocale from "../../../hooks/useLocale";
import useSwal from "../../../hooks/useSwal";

export const storageSizetoKB: number = 1073741824;

const StorageSettings = () => {
  const localeCtx = useLocale();
  const Swal = useSwal();
  const [storageLocations, setStorageLocations] = useState<StorageLocation[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [addingStorage, setAddingStorage] = useState<boolean>(false);
  const [selected, setSelected] = useState<number>(0);
  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(
    localeCtx?.selectedLocale?.current.componentTranslations["StorageSettings"]
  );

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
        "StorageSettings"
      ]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {
    const fetchStorages = async () => {
      try {
        setLoading(true);
        const locations = await storageService.getStorageLocations();
        setStorageLocations(locations);
      } catch (err) {
        console.error(err);
        if (err instanceof AxiosError) {
          setError(err.response?.data?.error ?? err.message);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchStorages();
  }, []);

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations("StorageSettings");
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations[
          "StorageSettings"
        ]
      );
      localeCtx?.setPreviousAppLocale("StorageSettings");
      if (localeCtx?.localeSwitchFailed) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        });
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected);
  };

  const onUserAddStorageToggle = () => {
    setAddingStorage((prevState) => !prevState);
  };

  const onStorageLocationUpserted = (
    upsertedStorageLocation: StorageLocation,
    updated: boolean = false
  ) => {
    if (updated) {
      setStorageLocations((prev) => {
        return prev.map((s) => {
          if (s.id === upsertedStorageLocation.id) {
            return upsertedStorageLocation;
          } else {
            return s;
          }
        });
      });
    } else {
      setStorageLocations((prev) => [...prev, upsertedStorageLocation]);
    }
    setAddingStorage(false);
  };

  const onActivateStorageLocation = async (storageLocationId: number) => {
    try {
      const resp = await storageService.activateStorageLocation(
        storageLocationId
      );
      setStorageLocations((prevState) => {
        return prevState.map((s) => {
          if (s.id === storageLocationId) {
            s.isActive = true;
          } else {
            s.isActive = false;
          }
          return s;
        });
      });
      Swal.fire({
        icon: "success",
        confirmButtonText: translationsLoading
          ? "Ok"
          : fetchLabelKeyTranslation("SwtAltOkText", "Ok"),
        title: translationsLoading
          ? "Storage Location Activated"
          : fetchLabelKeyTranslation(
              "SwtAltSuccessTitleOne",
              "Storage Location Activated"
            ),
      });
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: translationsLoading
          ? "Error"
          : fetchLabelKeyTranslation("SwtAltErrorTitle", "Error"),
        text: translationsLoading
          ? "Storage Location could not be activated"
          : fetchLabelKeyTranslation(
              "SwtAltErrorTextOne",
              "Storage Location could not be activated"
            ),
      });
    }
  };

  const CustomStorageLocationTitle = (props: {
    storageLocation: StorageLocation;
  }) => {
    return (
      <span>
        {props.storageLocation.name}
        {props.storageLocation.isActive && (
          <span className="bg-primary text-white fs-14 p-1 border-radius-5 m-l-4">
            {translationsLoading
              ? "ACTIVE"
              : fetchLabelKeyTranslation("StorageSettingsActiveText", "ACTIVE")}
          </span>
        )}
      </span>
    );
  };

  const onUserSwitchToServiceProviderStorage = async () => {
    try {
      const success = await storageService.deactivateStorageLocations();
      setStorageLocations((prevState) => {
        return prevState.map((s) => {
          s.isActive = false;
          return s;
        });
      });
      if (success) {
        Swal.fire({
          icon: "success",
          confirmButtonText: translationsLoading
            ? "Ok"
            : fetchLabelKeyTranslation("SwtAltOkText", "Ok"),
          title: translationsLoading
            ? "Service Provider Storage Location Activated"
            : fetchLabelKeyTranslation(
                "SwtAltSuccessTitleTwo",
                "Service Provider Storage Location Activated"
              ),
        });
      } else {
        throw "Error";
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: translationsLoading
          ? "Error"
          : fetchLabelKeyTranslation("SwtAltErrorTitle", "Error"),
        text: translationsLoading
          ? "Service Provider Storage Location could not be activated"
          : fetchLabelKeyTranslation(
              "SwtAltErrorTextTwo",
              "Service Provider Storage Location could not be activated"
            ),
      });
    }
  };

  return (
    <div className="m-b-10">
      <div className="row">
        <div className="col-md-12">
          <div className="trk-container">
            <div className="trk-inr p-b-30">
              <div className="trk-t text-black-14 border-bottom-solid border-w-1 border-black-1">
                <span className="text-primary">
                  {translationsLoading
                    ? "Storage Details"
                    : fetchLabelKeyTranslation(
                        "StorageSettingsTitle",
                        "Storage Details"
                      )}
                </span>
                <span className="float-right">
                  {storageLocations.filter((s) => s.isActive).length > 0 && (
                    <Button
                      className="btn bg-transparent text-black-12 border-primary text-primary m-r-5 p-r-7 p-l-7"
                      style={{ height: "26px" }}
                      onClick={onUserSwitchToServiceProviderStorage}
                    >
                      <span className="p-r-5">
                        {translationsLoading
                          ? "Activate Service Provider Storage"
                          : fetchLabelKeyTranslation(
                              "StorageSettingsBtnOneText",
                              "Activate Service Provider Storage"
                            )}
                      </span>
                      <i className="bi bi-check2-circle"></i>
                    </Button>
                  )}
                  {storageLocations.length > 0 && !addingStorage && (
                    <Button
                      className="btn bg-transparent text-black-12 border-primary text-primary m-r-5 p-r-7 p-l-7"
                      style={{ height: "26px" }}
                      onClick={onUserAddStorageToggle}
                    >
                      <span className="p-r-5">
                        {translationsLoading
                          ? "Add"
                          : fetchLabelKeyTranslation(
                              "StorageSettingsBtnTwoText",
                              "Add"
                            )}
                      </span>
                      <i className="bi bi-plus-lg"></i>
                    </Button>
                  )}
                </span>
              </div>
              {loading && (
                <div className="m-t-30" style={{ textAlign: "center" }}>
                  <Loader type={"infinite-spinner"} />
                </div>
              )}
              {!loading && error && (
                <div className="float-left w-100 m-t-10">
                  <div className="selectScorecard">
                    <div>
                      <span className="tx-red fs-14">{error}</span>
                    </div>
                  </div>
                </div>
              )}
              {!loading && !error && (
                <div>
                  {(storageLocations.filter((s) => s.isActive).length === 0 ||
                    addingStorage) && (
                    <div className="row">
                      <div className="col-md-12">
                        <div className="float-left w-100 p-t-20 p-b-20">
                          <div className="border-w-2 border-c-2 border-black-4 border-dashed radius-5 p-15 p-l-20 d-flex align-items-center">
                            <div className="itmeBoxBtn float-left w-100">
                              {addingStorage && (
                                <TabStrip
                                  selected={selected}
                                  onSelect={handleSelect}
                                >
                                  <TabStripTab
                                    title={
                                      translationsLoading
                                        ? "AWS"
                                        : fetchLabelKeyTranslation(
                                            "StorageSettingsAwsTab",
                                            "AWS"
                                          )
                                    }
                                  >
                                    <AWSStorageLocation
                                      configuring={true}
                                      onUserAddStorageToggle={
                                        onUserAddStorageToggle
                                      }
                                      onStorageLocationUpserted={
                                        onStorageLocationUpserted
                                      }
                                    />
                                  </TabStripTab>
                                  <TabStripTab
                                    title={
                                      translationsLoading
                                        ? "Azure"
                                        : fetchLabelKeyTranslation(
                                            "StorageSettingsAzureTab",
                                            "Azure"
                                          )
                                    }
                                  >
                                    <AzureStorageLocation
                                      configuring={true}
                                      onUserAddStorageToggle={
                                        onUserAddStorageToggle
                                      }
                                      onStorageLocationUpserted={
                                        onStorageLocationUpserted
                                      }
                                    />
                                  </TabStripTab>
                                  <TabStripTab
                                    title={
                                      translationsLoading
                                        ? "FTP"
                                        : fetchLabelKeyTranslation(
                                            "StorageSettingsFtpTab",
                                            "FTP"
                                          )
                                    }
                                  >
                                    <FTPStorageLocation
                                      configuring={true}
                                      onUserAddStorageToggle={
                                        onUserAddStorageToggle
                                      }
                                      onStorageLocationUpserted={
                                        onStorageLocationUpserted
                                      }
                                    />
                                  </TabStripTab>
                                  <TabStripTab
                                    title={
                                      translationsLoading
                                        ? "SFTP"
                                        : fetchLabelKeyTranslation(
                                            "StorageSettingsSftpTab",
                                            "SFTP"
                                          )
                                    }
                                  >
                                    <SFTPStorageLocation
                                      configuring={true}
                                      onUserAddStorageToggle={
                                        onUserAddStorageToggle
                                      }
                                      onStorageLocationUpserted={
                                        onStorageLocationUpserted
                                      }
                                    />
                                  </TabStripTab>
                                  <TabStripTab
                                    title={
                                      translationsLoading
                                        ? "Ceph"
                                        : fetchLabelKeyTranslation(
                                            "StorageSettingsCephTab",
                                            "Ceph"
                                          )
                                    }
                                  >
                                    <CephStorageLocation
                                      configuring={true}
                                      onUserAddStorageToggle={
                                        onUserAddStorageToggle
                                      }
                                      onStorageLocationUpserted={
                                        onStorageLocationUpserted
                                      }
                                    />
                                  </TabStripTab>
                                </TabStrip>
                              )}
                              {!addingStorage && (
                                <div className="storageListPlaceholder p-20">
                                  <div className="text-center p-b-5">
                                    <span className="tx-red">
                                      {translationsLoading
                                        ? "Your storage location and settings are being managed by your service provider. Select ‘configure’ to set and activate your own storage location and settings."
                                        : fetchLabelKeyTranslation(
                                            "StorageSettingsInfoText",
                                            "Your storage location and settings are being managed by your service provider. Select ‘configure’ to set and activate your own storage location and settings."
                                          )}
                                    </span>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-center">
                                    <Button
                                      onClick={onUserAddStorageToggle}
                                      className="btn bg-primary text-white"
                                      style={{ height: "40px", width: "150px" }}
                                    >
                                      {translationsLoading
                                        ? "Configure"
                                        : fetchLabelKeyTranslation(
                                            "StorageSettingsBtnThreeText",
                                            "Configure"
                                          )}
                                      <i className="bi bi-gear-wide-connected fs-20 m-l-8"></i>
                                    </Button>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="accordionData float-left w-100">
                    <div className="panelbar-wrapper">
                      <PanelBar animation={true}>
                        {storageLocations.map((s, idx) => {
                          return (
                            <PanelBarItem
                              key={s.id}
                              title={
                                <CustomStorageLocationTitle
                                  storageLocation={s}
                                />
                              }
                              expanded={idx === 0}
                              icon="folder"
                            >
                              {s.mode === StorageModes.AzureBlob && (
                                <AzureStorageLocation
                                  data={s}
                                  configuring={false}
                                  onUserAddStorageToggle={
                                    onUserAddStorageToggle
                                  }
                                  onStorageLocationUpserted={
                                    onStorageLocationUpserted
                                  }
                                  onActivateStorageLocation={
                                    onActivateStorageLocation
                                  }
                                />
                              )}
                              {s.mode === StorageModes.AWSS3 && (
                                <AWSStorageLocation
                                  data={s}
                                  configuring={false}
                                  onUserAddStorageToggle={
                                    onUserAddStorageToggle
                                  }
                                  onStorageLocationUpserted={
                                    onStorageLocationUpserted
                                  }
                                  onActivateStorageLocation={
                                    onActivateStorageLocation
                                  }
                                />
                              )}
                              {s.mode === StorageModes.FTP && (
                                <FTPStorageLocation
                                  data={s}
                                  configuring={false}
                                  onUserAddStorageToggle={
                                    onUserAddStorageToggle
                                  }
                                  onStorageLocationUpserted={
                                    onStorageLocationUpserted
                                  }
                                  onActivateStorageLocation={
                                    onActivateStorageLocation
                                  }
                                />
                              )}
                              {s.mode === StorageModes.SFTP && (
                                <SFTPStorageLocation
                                  data={s}
                                  configuring={false}
                                  onUserAddStorageToggle={
                                    onUserAddStorageToggle
                                  }
                                  onStorageLocationUpserted={
                                    onStorageLocationUpserted
                                  }
                                  onActivateStorageLocation={
                                    onActivateStorageLocation
                                  }
                                />
                              )}
                              {s.mode === StorageModes.Ceph && (
                                <CephStorageLocation
                                  data={s}
                                  configuring={false}
                                  onUserAddStorageToggle={
                                    onUserAddStorageToggle
                                  }
                                  onStorageLocationUpserted={
                                    onStorageLocationUpserted
                                  }
                                  onActivateStorageLocation={
                                    onActivateStorageLocation
                                  }
                                />
                              )}
                            </PanelBarItem>
                          );
                        })}
                      </PanelBar>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StorageSettings;
