import React, { useContext, useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import CustomUserList from "../../../../components/custom/CustomUserList";
import UpsertUserGroupsDialog from "./UpsertUserGroupsDialog";
import { Group } from "../../../../types/user-groups/Group";
import { AccessPermissionEnum } from "../../../../enums/accessPermissionEnum";
import userGroupsService from "../../../../services/usergroups.service";
import useAuth from "../../../../hooks/useAuth";
import { process } from "@progress/kendo-data-query";
import useLocale from "../../../../hooks/useLocale";
import { Dictionary } from "../../../../types/Dictionary";
import { Tooltip } from "@progress/kendo-react-tooltip";
import NotFoundError from "../../../error/NotFoundError";
import CustomSearchFieldTextInput from "../../../../components/custom/form/CustomSearchFieldTextInput";
import UnauthorizedAccess from "../../../error/UnauthorizedAccess";
import useSwal from "../../../../hooks/useSwal";

const initialDataState = {
  skip: 0,
  take: 10,
};

const alphabetFilter: string[] = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

const UserGroups: React.FC = () => {
  const auth = useAuth();
  const Swal = useSwal();
  const localeCtx = useLocale();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedAlphabet, setSelectedAlphabet] = useState<string>("A");
  const [searchValue, setSearchValue] = useState<string>("");
  const [groups, setGroups] = useState<Group[]>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [editGroup, setEditGroup] = useState<Group | undefined>(undefined);
  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(localeCtx?.selectedLocale?.current.componentTranslations["UserGroups"]);

  useEffect(() => {
    LoadUserGroups();
  }, []);

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations["UserGroups"]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations("UserGroups");
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations["UserGroups"]
      );
      localeCtx?.setPreviousAppLocale("UserGroups");
      if (localeCtx?.localeSwitchFailed) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        });
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const sortUserGroups = (getGroups: Group[]): Group[] => {
    const sortedGroups: Group[] = getGroups.sort((a: Group, b: Group) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      return 0;
    });
    return sortedGroups;
  };

  const LoadUserGroups = async () => {
    try {
      setLoading(true);
      const data = await userGroupsService.getUserGroups();
      let _userGroups = data.map((ug) => {
        ug.groupAdmins = ug.groupAdmins.filter((ga) => !ga.admin.isArchived);
        ug.groupMembers = ug.groupMembers.filter((gm) => !gm.member.isArchived);
        return ug;
      });
      setGroups(sortUserGroups(_userGroups));
    } catch (ex) {
      if (ex instanceof Error) {
        console.log(ex);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchValue(e.currentTarget.value);
  };

  const searchGroups = (group: Group) => {
    const searchVal = searchValue.trim().toLocaleLowerCase();
    if (group.name.toLocaleLowerCase().includes(searchVal)) {
      return true;
    }
    if (group.description?.toLocaleLowerCase().includes(searchVal)) {
      return true;
    }
    return false;
  };

  const toggleAddEditDialog = () => {
    setVisible(!visible);
    setEditGroup(undefined);
  };

  const onUserGroupUpserted = (isUpdated: boolean, upsertedGroup: Group) => {
    if (isUpdated) {
      const updatedGroups: Group[] = [];
      groups?.forEach((gp) => {
        if (gp.id === upsertedGroup.id) {
          updatedGroups.push(upsertedGroup);
        } else {
          updatedGroups.push(gp);
        }
      });
      setGroups(updatedGroups);
    } else {
      const updatedGroups = [...groups, upsertedGroup];
      setGroups(updatedGroups);
    }
  };

  const onEditHandler = (groupDetails: Group) => {
    setEditGroup(groupDetails);
    setVisible(true);
  };

  const onDeleteHandler = (groupId: number) => {
    Swal.fire({
      title: `${
        translationsLoading
          ? "Are you sure?"
          : fetchLabelKeyTranslation("WarningTitle", "Are you sure?")
      }`,
      text: `${
        translationsLoading
          ? "You won't be able to revert this!"
          : fetchLabelKeyTranslation(
              "WarningText",
              "You won't be able to revert this!"
            )
      }`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `${
        translationsLoading
          ? "Yes, delete it!"
          : fetchLabelKeyTranslation("ConfirmButtonText", "Yes, delete it!")
      }`,
      cancelButtonText: `${
        translationsLoading
          ? "Cancel"
          : fetchLabelKeyTranslation("CancelButton", "Cancel")
      }`,
    }).then((result) => {
      if (result.isConfirmed) {
        userGroupsService
          .deleteUserGroups(groupId)
          .then(() => {
            Swal.fire({
              icon: "success",
              title: `${
                translationsLoading
                  ? "User Group Deleted!"
                  : fetchLabelKeyTranslation(
                      "SuccessTitle",
                      "User Group Deleted!"
                    )
              }`,
              text: `${
                translationsLoading
                  ? "User group has been deleted."
                  : fetchLabelKeyTranslation(
                      "SuccessText",
                      "User group has been deleted."
                    )
              }`,
              confirmButtonText: `${
                translationsLoading
                  ? "OK"
                  : fetchLabelKeyTranslation("OKText", "OK")
              }`,
            });
            const updatedGroups = groups?.filter((gp) => gp.id !== groupId);
            setGroups(updatedGroups);
          })
          .catch((err) => {
            if (err instanceof Error) {
              Swal.fire({
                icon: "error",
                title: `${
                  translationsLoading
                    ? "Error"
                    : fetchLabelKeyTranslation("ErrorTitle", "Error")
                }`,
                text: `${
                  translationsLoading
                    ? "Could not delete user group."
                    : fetchLabelKeyTranslation(
                        "ErrorText",
                        "Could not delete user group."
                      )
                }`,
                confirmButtonText: `${
                  translationsLoading
                    ? "OK"
                    : fetchLabelKeyTranslation("OKText", "OK")
                }`,
              });
            }
          });
      }
    });
  };

  const handleAlphabetFilterChange = (value: string) => {
    setSelectedAlphabet(value);
    scrollToUserCard(value);
  };

  const scrollToUserCard = (value: string) => {
    if (value.trim() !== "") {
      let idValue = "groupCardId_" + value.trim().toLocaleLowerCase();
      var element = document.getElementById(idValue);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <>
      {!auth?.checkUserAccess(AccessPermissionEnum.ManageUsersAndGroups) ? (
        <UnauthorizedAccess />
      ) : (
        <>
          <Tooltip anchorElement={"target"} parentTitle={true} position={"top"}>
            <div className="contentPushArea boxUserGroups h-100">
              <div className="contentHead h-100">
                <div className="contentHeadTop d-flex justify-content-between align-items-center border-bottom-w-1 border-bottom-solid border-black-2 p-b-15">
                  <div className="contentHeadTitle fs-18">
                    {translationsLoading
                      ? "User Groups"
                      : fetchLabelKeyTranslation(
                          "UserGroupsTitle",
                          "User Groups"
                        )}
                  </div>
                  <div className="contentHeadAction">
                    <div className="buttons-container hov-transparent d-flex align-itmes-center p-0">
                      <div className="searchCol searchBox m-r-10">
                        <CustomSearchFieldTextInput
                          className="input-search"
                          placeholder={`${
                            translationsLoading
                              ? "Search…"
                              : fetchLabelKeyTranslation(
                                  "SearchBoxPlaceHolder",
                                  "Search…"
                                )
                          }`}
                          value={searchValue}
                          updateValue={setSearchValue}
                          onEscapeKeyFunc={true}
                          searchTextChangeHandler={handleSearchChange}
                        />
                      </div>
                      <Button
                        onClick={toggleAddEditDialog}
                        className={`btn bg-primary text-white fs-13 fw-normal ${
                          !auth?.checkUserAccess(
                            AccessPermissionEnum.ManageUsersAndGroups
                          )
                            ? "disabledBtn"
                            : ""
                        }`}
                        style={{ height: "29px", margin: "-1px 0 0 0" }}
                        title={`${
                          translationsLoading
                            ? "Add"
                            : fetchLabelKeyTranslation("AddButton", "Add")
                        }`}
                        disabled={
                          !auth?.checkUserAccess(
                            AccessPermissionEnum.ManageUsersAndGroups
                          )
                        }
                      >
                        {translationsLoading
                          ? "Add"
                          : fetchLabelKeyTranslation("AddButton", "Add")}
                      </Button>
                      {visible && (
                        <UpsertUserGroupsDialog
                          onCloseDialog={toggleAddEditDialog}
                          data={editGroup}
                          onUserGroupUpserted={onUserGroupUpserted}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="cardWordFilter">
                  <div className="wordFilterTitle">All</div>
                  <div className="wordFilterBox">
                    {alphabetFilter.map((e) => {
                      const isSeleted =
                        selectedAlphabet.toLocaleLowerCase() ===
                        e.toLocaleLowerCase();
                      return (
                        <div
                          className={`wordFilterLetter ${
                            isSeleted && "activeLetter"
                          }`}
                          onClick={() => handleAlphabetFilterChange(e)}
                          key={e}
                        >
                          {e}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="contentBody">
                  <div className="cardBrand">
                    <div className="cardListArea">
                      <div className="row">
                        {loading ? (
                          <div className="col-md-12 d-flex justify-content-center align-items-center">
                            <Loader type={"infinite-spinner"} />
                          </div>
                        ) : groups &&
                          groups.filter((g) => searchGroups(g)).length > 0 ? (
                          groups
                            .filter((g) => searchGroups(g))
                            .map((group) => {
                              let idValue;
                              if (
                                group.name &&
                                group.name !== "" &&
                                group.name.length > 0
                              ) {
                                idValue =
                                  "groupCardId_" +
                                  group.name.charAt(0).toLocaleLowerCase();
                              }
                              return (
                                <div className="col col-md-3" id={idValue}>
                                  <div className="cardListBlock">
                                    <div className="cardRows">
                                      <div className="cardColumn">
                                        <div className="colUser">
                                          <div className="tblUsr float-left w-100">
                                            <div className="topUsrAreaPic w-100">
                                              <div className="topUsrAreaPic-tx w-100">
                                                <span
                                                  style={{ maxWidth: "95%" }}
                                                  className="text-black-13 fs-13 font-weight-semi cursor-default text-decoration-none float-left w-100 line-height-2 text-truncate"
                                                  title="User Group Name"
                                                >
                                                  {group.name}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="lblGroupArea float-left w-100">
                                          <div className="userRowLbl p-t-5 p-b-1">
                                            <div className="userColLbl">
                                              <div className="userLabels float-left w-100 fs-12 text-black-9">
                                                {translationsLoading
                                                  ? "Administrators"
                                                  : fetchLabelKeyTranslation(
                                                      "AdminTitle",
                                                      "Administrators"
                                                    )}
                                              </div>
                                              <div className="userCircle">
                                                <CustomUserList
                                                  userList={group.groupAdmins.map(
                                                    (el) => el.admin
                                                  )}
                                                  maxDisplay={4}
                                                />
                                              </div>
                                            </div>
                                            <div className="userColLbl">
                                              <div className="userLabels float-right text-right w-100 fs-12 text-black-9">
                                                {translationsLoading
                                                  ? "Members"
                                                  : fetchLabelKeyTranslation(
                                                      "MemberTitle",
                                                      "Members"
                                                    )}
                                              </div>
                                              <div className="userCircle">
                                                <CustomUserList
                                                  userList={group.groupMembers.map(
                                                    (el) => el.member
                                                  )}
                                                  maxDisplay={4}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="minTextLabel text-truncate">
                                            <span className="float-left text-black-9">
                                              {translationsLoading
                                                ? "Description"
                                                : fetchLabelKeyTranslation(
                                                    "DescriptionTitle",
                                                    "Description"
                                                  )}
                                            </span>
                                            <span className="mx-th-tag p-l-3">
                                              <div
                                                className="mx-td-spn line-height-2 dotTextInner"
                                                style={{
                                                  wordBreak: "break-word",
                                                }}
                                              >
                                                {group?.description?.slice(
                                                  0,
                                                  200
                                                )}
                                              </div>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="blockIconButton">
                                      <span className="iBtn">
                                        <Button
                                          className={`iconBtn btnBlock-a`}
                                          style={{ marginRight: "-5px" }}
                                          onClick={() => onEditHandler(group)}
                                          disabled={
                                            !auth?.checkUserAccess(
                                              AccessPermissionEnum.ManageUsersAndGroups
                                            )
                                          }
                                          title={`${
                                            translationsLoading
                                              ? "Edit"
                                              : fetchLabelKeyTranslation(
                                                  "EditBtnText",
                                                  "Edit"
                                                )
                                          }`}
                                        >
                                          <i className="bi bi-pencil fs-17"></i>
                                        </Button>
                                        <Button
                                          className={`iconBtn btnBlock-a`}
                                          onClick={() =>
                                            onDeleteHandler(group.id)
                                          }
                                          disabled={
                                            !auth?.checkUserAccess(
                                              AccessPermissionEnum.ManageUsersAndGroups
                                            )
                                          }
                                          title={`${
                                            translationsLoading
                                              ? "Delete"
                                              : fetchLabelKeyTranslation(
                                                  "DeleteBtnText",
                                                  "Delete"
                                                )
                                          }`}
                                        >
                                          <i className="bi bi-trash3 fs-17"></i>
                                        </Button>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                        ) : (
                          <div className="col-md-12 p-l-5">
                            <div className="dashedBox p-10 m-b-20 float-left w-100 radius-6 border-black-3 border-w-2 border-dashed d-flex align-items-center justify-content-center">
                              <span className="fs-13 text-black-9 p-t-20 p-b-20">
                                {translationsLoading
                                  ? "No Records Available."
                                  : fetchLabelKeyTranslation(
                                      "NoRecordText",
                                      "No Records Available."
                                    )}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Tooltip>
        </>
      )}
    </>
  );
};

export default UserGroups;
