import { TinyUser } from "../types/user";
import { axiosWithAuth, axiosWithoutAuth } from "../utils/customAxios";
import {
  SharedResource,
  SharedResourceDetail,
  SharedResourceUser,
} from "../types/shared-resource";

const sharedResourceService = {
  share: async (
    appUrl: string,
    resourceUrl: string,
    duration: number,
    internalUsers: TinyUser[],
    externalUsers: string[],
    resources: SharedResourceDetail[],
    resourceFilters?: string
  ): Promise<SharedResource> => {
    try {
      const sharedUsers: SharedResourceUser[] = [];
      internalUsers.forEach((u) => {
        sharedUsers.push({
          id: 0,
          isExternalUser: false,
          sharedUserId: u.id,
          email: u.email,
        });
      });
      externalUsers.forEach((e) => {
        sharedUsers.push({
          id: 0,
          isExternalUser: true,
          email: e,
        });
      });

      const resource: SharedResource = {
        id: 0,
        appUrl,
        resourceUrl,
        resourceFilters,
        resources,
        sharedUsers,
        shareDuration: duration * 86400, // in days
      };

      const response = await axiosWithAuth.post("/sharedresource", resource);
      const sharedResource: SharedResource = response.data;
      return sharedResource;
    } catch (err) {
      throw err;
    }
  },

  verifySharedToken: async (token: string): Promise<boolean> => {
    try {
      const response = await axiosWithoutAuth.post("/verifysharedtoken", {
        token,
      });
      const valid: boolean = response.data;
      return valid;
    } catch (err) {
      throw err;
    }
  },

  createGuestSharedResourceToken: async (
    sharedResourceId: number,
    sharedByUser: TinyUser,
    guestUserEmail: string,
    token: string
  ): Promise<string> => {
    try {
      const response = await axiosWithoutAuth.post("/createguestsharedtoken", {
        sharedResourceId,
        sharedByUser,
        guestUserEmail,
        sharedToken: token,
      });

      const guestTokenResponse: { token: string } = response.data;

      return guestTokenResponse.token;
    } catch (err) {
      throw err;
    }
  },

  createInternalSharedResourceToken: async (
    sharedResourceId: number,
    sharedByUser: TinyUser,
    token: string
  ): Promise<string> => {
    const response = await axiosWithAuth.post("/createinternalsharedtoken", {
      sharedResourceId,
      sharedByUser,
      sharedToken: token,
    });

    const guestTokenResponse: { token: string } = response.data;

    return guestTokenResponse.token;
  },
};

export default sharedResourceService;
