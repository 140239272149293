export const IsValidString = (str?: string): boolean => {
  if (str === undefined || str === null || str.trim() === "") {
    return false;
  } else {
    return true;
  }
};

export const getInitials = (input: string): string => {
  const words = input.split(" ");
  const res = words
    .map((w) => w[0])
    .join("")
    .substring(0, 2);

  return res;
};

export const convertToPascalCase = (input: string): string => {
  const words = input.split(" ");
  const res = words
    .map((w) => {
      if (w.trim() !== "") {
        return w[0].toLocaleUpperCase() + w.substring(1);
      }
    })
    .join(" ");

  return res;
};

export const convertKeyToPascalCase = (key: string) => {
  const result = key
    .replace(/([A-Z]+)/g, " $1")
    .replace(/([A-Z][a-z])/g, " $1");
  console.log(result);
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};
