import { Link } from "react-router-dom";
import { CallDetail } from "../../types/call";
import { GridCellProps } from "@progress/kendo-react-grid";
import { formatDateTime } from "../../utils/dateTimeUtils";
import { formatSecondsToTime } from "../../utils/dateTimeUtils";
import { useEffect, useState } from "react";
import CustomUserGridInfo from "./grid/CustomUserGridInfo";
import CallDirection from "./CallDirection";
import { IsValidString } from "../../utils/stringUtils";
import CustomSentiment from "./CustomSentiment";
import { RecordingTopic } from "../../types/recording";
import ShareDialog from "../ShareDialog";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Button } from "@progress/kendo-react-buttons";
import useAuth from "../../hooks/useAuth";
import { AccessPermissionEnum } from "../../enums";
import { Dictionary } from "../../types/Dictionary";
import { SweetAlertOptions } from "sweetalert2";
import useSwal from "../../hooks/useSwal";
import useLocale from "../../hooks/useLocale";

interface CallListingForMobileProps extends GridCellProps {
  dataItem: CallDetail;
  handlePlayerExpand: (callDetail: CallDetail) => void;
}

const CallListingForMobile = (props: CallListingForMobileProps) => {
  const auth = useAuth();
  const swal = useSwal();
  const localeCtx = useLocale();
  const routeUrl = `/calldetail/${props.dataItem.platformCallDetailId}?recordingId=${props.dataItem.recordings[0].id}`;
  var flagColor = props.dataItem.colorCategory
    ? IsValidString(props.dataItem.colorCategory.hexCode)
      ? props.dataItem.colorCategory.hexCode
      : "#ccc"
    : "#ccc";

  const [expanded, setExpanded] = useState<boolean>(false);
  const [shareDialogVisible, setShareDialogVisible] = useState<boolean>(false);
  const [translationsLoading, setTranslationsLoading] =
  useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(localeCtx?.selectedLocale?.current.componentTranslations["calls"]);

  useEffect(() => {
    if (!localeCtx?.selectedLocale?.current.componentTranslations["calls"]) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations("calls");
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations["calls"]
      );
      localeCtx?.setPreviousAppLocale("calls");
      if (localeCtx?.localeSwitchFailed) {
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        };
        await swal.fire(swalOptions);
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const toggleShareDialogHandler = () => {
    setShareDialogVisible((prev) => !prev);
  };

  const onCloseShareDialog = () => {
    setShareDialogVisible(false);
  };

  

  return (
    <td className="listMobile">
      <div className="listRowMobile float-left p-0">
        <div className="float-left w-100">
          <CustomUserGridInfo
            userInfo={props.dataItem.secondParty}
            label={`${
              translationsLoading
                ? "Participant"
                : fetchLabelKeyTranslation(
                    "CallsGridRemotePartyColLabel",
                    "Participant"
                  )
            }`}
          />
          <div className="miniTextTime float-left w-100 p-t-6 p-l-50">
            <div className="listLabelRow d-flex justify-content-start w-100 float-left line-height-1">
              <div className="listLabelCol">
                <span className="fs-11 font-weight-semi p-r-3 text-black-12">
                  {`${
                    translationsLoading
                      ? "Date/Time"
                      : fetchLabelKeyTranslation(
                          "CallsGridDateTimeColLabel",
                          "Date/Time"
                        )
                  }`}:
                </span>
              </div>
              <div className="listLabelCol">
                <span className="fs-11 text-black-10">
                  {formatDateTime(
                    props.dataItem.callTime,
                    "MMM DD, YYYY, HH:mm"
                  )}
                </span>
              </div>
            </div>
            <div className="listLabelRow d-flex justify-content-start w-100 float-left line-height-1">
              <div className="listLabelCol">
                <span className="fs-11 font-weight-semi p-r-3 text-black-12">
                  {`${
                    translationsLoading
                      ? "Duration"
                      : fetchLabelKeyTranslation(
                          "CallsGridDurationColLabel",
                          "Duration"
                        )
                  }`}:
                </span>
              </div>
              <div className="listLabelCol">
                <span className="fs-11 text-default text-black-11">
                  {formatSecondsToTime(props.dataItem.callDuration)}
                </span>
              </div>
            </div>
            <div className="hideShowLabel fs-12 text-primary text-decoration-underline">
              <a
                onClick={() => {
                  setExpanded(!expanded);
                }}
                className="hideShowLink"
              >
                {expanded ? "Less" : "More"} Details
              </a>
            </div>
          </div>
        </div>
        {expanded && (
          <div className="viewMoreData float-left w-100">
            <div className="viewMoreDataInr float-left w-100">
              <CustomUserGridInfo
                userInfo={props.dataItem.firstParty}
                label={`${
                  translationsLoading
                    ? "Recorded User"
                    : fetchLabelKeyTranslation(
                        "CallsGridFirstPartyColLabel",
                        "Recorded User"
                      )
                }`}
              />
              <div className="listLabel">
                <div className="listLabelRow w-100 float-left border-top-solid border-bottom-solid border-w-1 border-black-1 m-t-10 p-t-7 p-b-7">
                  <span className="spanLabel d-flex align-items-center p-r-15">
                    <span className="fs-13 font-weight-semi p-r-5">
                      {`${
                        translationsLoading
                          ? "Call Direction"
                          : fetchLabelKeyTranslation(
                              "CallsGridCallDirectionColLabel",
                              "Call Direction"
                            )
                      }`}:
                    </span>
                    <CallDirection
                      callDirection={props.dataItem.callDirection}
                      callType={props.dataItem.callType}
                      iconSize={14}
                    />
                  </span>
                  <span className="spanLabel d-flex align-items-center p-r-15">
                    <span className="fs-13 font-weight-semi p-r-5">
                      {`${
                        translationsLoading
                          ? "Flag"
                          : fetchLabelKeyTranslation(
                              "CallsGridFlagColLabel",
                              "Flag"
                            )
                      }`}:
                    </span>
                    <span className="fs-14" style={{ color: flagColor }}>
                      <i
                        className={`bi bi-flag${
                          props.dataItem.colorCategory ? "-fill" : ""
                        }`}
                      ></i>
                    </span>
                  </span>
                  <span className="spanLabel d-flex align-items-center p-r-15">
                    <span className="fs-13 font-weight-semi p-r-5">
                      {`${
                        translationsLoading
                          ? "Eval"
                          : fetchLabelKeyTranslation(
                              "CallsGridEvalColLabel",
                              "Eval"
                            )
                      }`}:
                    </span>
                    {props.dataItem.isEvaluated ? (
                      <span className="fs-14 tx-green" title="Evaluated">
                        <i className="bi bi-check-circle"></i>
                      </span>
                    ) : (
                      <span className="fs-14 tx-dull" title="Not Evaluated">
                        <i className="bi bi-x-circle"></i>
                      </span>
                    )}
                  </span>
                  <span className="spanLabel d-flex align-items-center">
                    <span className="fs-13 font-weight-semi p-r-5">
                      {`${
                        translationsLoading
                          ? "Sentiment"
                          : fetchLabelKeyTranslation(
                              "CallsGridSentimentColLabel",
                              "Sentiment"
                            )
                      }`}:
                    </span>
                    <CustomSentiment
                      sentiment={props.dataItem.recordings[0]?.sentiment}
                      iconSize={14}
                    />
                  </span>
                </div>
                <div className="listLabelRow d-flex justify-content-start w-100 float-left border-bottom-solid border-w-1 border-black-1 p-t-3 p-b-3">
                  <div className="listLabelCol">
                    <span className="fs-13 font-weight-semi p-r-3">
                      {`${
                        translationsLoading
                          ? "Platform"
                          : fetchLabelKeyTranslation(
                              "CallsGridPlatformColLabel",
                              "Platform"
                            )
                      }`}:
                    </span>
                  </div>
                  <div className="listLabelCol">
                    <span className="fs-13 text-default">
                      {props.dataItem.platform.name}
                    </span>
                  </div>
                </div>
              </div>
              <div className="iconlabel float-left w-100 p-b-10 p-t-10">
                <span className="spanLabel d-flex align-items-baseline w-100">
                  <span
                    className="fs-13 font-weight-semi p-r-3"
                    style={{ minWidth: "35px" }}
                  >
                    {`${
                      translationsLoading
                        ? "Outcome"
                        : fetchLabelKeyTranslation(
                            "CallsGridOutcomeColLabel",
                            "Outcome"
                          )
                    }`}:
                  </span>
                  <span className="fs-13 text-default line-height-1">
                    <span
                      className="mx-th-tag"
                      style={{ minWidth: "80px", maxWidth: "160px" }}
                    >
                      <div className="mx-td-spn" style={{ maxWidth: "70vw" }}>
                        {props.dataItem.callResult?.name ?? ""}
                      </div>
                    </span>
                  </span>
                </span>
              </div>
              <div className="topics float-left w-100 p-t-5 d-flex">
                <div className="fs-13 font-weight-semi p-r-5">
                  {`${
                    translationsLoading
                      ? "Topics"
                      : fetchLabelKeyTranslation(
                          "CallsGridTopicsColLabel",
                          "Topics"
                        )
                  }`}:
                </div>
                <div className="mx-th-tag ">
                  <div className="mx-td-spn">
                    <div className="chipLable">
                      <div className="keywordRow">
                        {props.dataItem.recordings[0]?.topics?.map(
                          (topic: RecordingTopic, index: number) => (
                            <div className="keywordTrk" key={index}>
                              {topic.topicName.name}
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="iconMobileDetail">
          {shareDialogVisible && (
            <ShareDialog
              onClose={onCloseShareDialog}
              resourceObjId={props.dataItem.id}
              recordingPath={routeUrl}
            />
          )}
          <Tooltip anchorElement={"target"} position={"top"} parentTitle={true}>
            <span className="iBtn d-flex justify-content-end">
              <span className="m-r-10" title="View Detail">
                <Link
                  to={routeUrl}
                  state={props.dataItem}
                  className="btn-link text-primary p-0 m-0 fs-20"
                >
                  <i className="bi bi-justify-left"></i>
                </Link>
              </span>
              <Button
                className={`radius-50 iBtn-bg d-flex align-items-center justify-conter m-r-3 ${
                  auth?.checkUserAccess(AccessPermissionEnum.ShareCalls)
                    ? "iconBtn"
                    : "disabledIconBtn"
                }
                  `}
                onClick={toggleShareDialogHandler}
                disabled={!auth?.checkUserAccess(AccessPermissionEnum.ShareCalls)}
              >
                <span
                  className="fs-15 line-height-1 text-primary"
                  title="Share"
                >
                  <i className="bi bi-share"></i>
                </span>
              </Button>
              <Button
                className={`radius-50 iBtn-bg d-flex align-items-center justify-conter m-r-3 iconBtn`}
                onClick={() => props.handlePlayerExpand(props.dataItem)}
              >
                <span className="fs-15 line-height-1 text-primary" title="Play">
                  <i className="bi bi-play"></i>
                </span>
              </Button>
            </span>
          </Tooltip>
        </div>
      </div>
    </td>
  );
};

export default CallListingForMobile;
