import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { AUTH_BASE_URL, AUTH_CLIENT_ID } from "../../constants";
import authService from "../../services/auth.service";
import LoadingOverlay from "../../components/LoadingOverlay";
import { AxiosError } from "axios";
import useBranding from "../../hooks/useBranding";
import defaultLogo from "../../assets/images/logo.png";
import { Error } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import { appUrl } from "../..";

const Login: React.FC = () => {
  const brandingCtx = useBranding();
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();

  const from =
    location.state?.from?.pathname + location.state?.from?.search || "/home";

  const username = searchParams.get("username");
  const appToken = searchParams.get("appToken");
  const language = searchParams.get("language");
  

  useEffect(() => {
    const originalRedirectUri = searchParams.get("originalRedirectUri");
    if (brandingCtx?.branding) {
      let authAppBaseUrl = AUTH_BASE_URL;
      
      if (brandingCtx?.branding?.authAppConfiguration?.authClientAppUrl) {
        authAppBaseUrl =
          brandingCtx.branding.authAppConfiguration.authClientAppUrl;
      }
      let authAppClientId = AUTH_CLIENT_ID;
      if (brandingCtx?.branding?.authAppConfiguration?.authAppClientId) {
        authAppClientId =
          brandingCtx.branding.authAppConfiguration.authAppClientId;
      }

      const authAppLoginUrl = `${authAppBaseUrl}/login?clientId=${authAppClientId}
      &originalRedirectUri=${originalRedirectUri??from}`;
      if (username && appToken) {
        setLoading(true);
        authService
          .login({ username, appToken })
          .then((token) => {
            if (token && token.trim().length > 0) {
              console.log("Token: ", token);
              auth?.setUserAuthToken(token); 
              navigate(originalRedirectUri ?? `/home?language=${language}`, { replace: true });
            }
          })
          .catch((err) => {
            auth?.setUserAuthToken(undefined);
            console.error(err);
            if (err instanceof AxiosError) {
              const errMsg = err.response?.data.error;
              setError(errMsg);
            }

            //window.location.replace(authAppLoginUrl);
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (auth?.accessToken) {
        navigate(from, { replace: true });
      } else {
        window.location.replace(authAppLoginUrl);
      }
    }
  }, [brandingCtx?.brandingLoaded]);

  const onErrorHandler = async () => {
    let authAppBaseUrl = AUTH_BASE_URL;
    if (brandingCtx?.branding?.authAppConfiguration.authClientAppUrl) {
      authAppBaseUrl =
        brandingCtx.branding.authAppConfiguration.authClientAppUrl;
    }
    let authAppClientId = AUTH_CLIENT_ID;
    if (brandingCtx?.branding?.authAppConfiguration.authAppClientId) {
      authAppClientId =
        brandingCtx.branding.authAppConfiguration.authAppClientId;
    }

    try {
      window.location.replace(
        `${authAppBaseUrl}/logout?clientId=${authAppClientId}&redirectUri=${appUrl}/login`
      );
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="loginFull float-left w-100 h-100">
      <div className="h-100 p-l-15 p-r-15">
        <div className="row m-b-20 h-100">
          <div className="col-md-12 h-100">
            {loading && (
              <LoadingOverlay
                customStyle={{ position: "fixed", marginTop: "55px" }}
                themeColor={"light"}
                size={"medium"}
                loadingText={"Redirecting..."}
              />
            )}
            {!loading && error && (
              <div className="loginBg h-100 p-l-15 p-r-15">
                <div className="row m-b-20 h-100">
                  <div className="col-md-12 h-100">
                    <div className="formGroup h-100">
                      <div
                        className="formInrG cardEffect"
                        style={{ position: "relative" }}
                      >
                        <div className="hdrTrk-logo text-center p-t-5 p-b-15">
                          <a className="navbar-logo" href="" title="Logo">
                            <img
                              src={
                                brandingCtx?.branding?.logos.find(
                                  (l) => l.name === "HeaderPrimaryLogo"
                                )?.logoImageUrl ?? defaultLogo
                              }
                              alt="Logo"
                            />
                          </a>
                        </div>
                        <Error className="fs-18">
                          {/* {"Something went wrong! Please try again later"} */}
                          {error}
                        </Error>
                        <div className="text-center p-t-5 p-b-15">
                          <Button
                            className={`btn bg-primary text-white `}
                            onClick={onErrorHandler}
                          >
                            Back to Login Page
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
