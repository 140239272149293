import { TinyUser } from "../../types/user";
import {
  formatUsernameToPascalCase,
  getRandomProfileColor,
} from "../../utils/profileUtils";
import { IsValidString } from "../../utils/stringUtils";

interface ICustomUserInfo {
  userInfo: TinyUser;
  requiresBorder?: boolean;
  customPnlCss?: string;
  customDataHeaderCss?: string;
  customDataBodyCss?: string;
  customDataHeaderLabel?: string;
  customDataBodyLabel?: string;
}

const CustomUserInfo: React.FC<ICustomUserInfo> = ({
  userInfo,
  requiresBorder,
  customPnlCss,
  customDataHeaderCss,
  customDataBodyCss,
  customDataHeaderLabel,
  customDataBodyLabel,
}) => {
  const name = formatUsernameToPascalCase(
    userInfo.firstName ? userInfo.firstName : "",
    userInfo.lastName ? userInfo.lastName : ""
  ).trim();

  var isImageAvailable = IsValidString(userInfo.image);
  var isUserNameAvailable = IsValidString(name);
  var isEmailAvailable = IsValidString(userInfo.email);

  const color = getRandomProfileColor(userInfo.id);
  const userIcon = userInfo.image ? (
    <div className="contact-list-icon-img">
      <img src={require("../../assets/images/user-img-2.jpg")} />
    </div>
  ) : name !== "" ? (
    <span>
      {name
        .split(" ")
        .map((n: string) => n[0])
        .join("")}
    </span>
  ) : (
    <span className="fs-16">
      <i className="bi bi-telephone"></i>
    </span>
  );

  var header = isUserNameAvailable ? name
    : isEmailAvailable
    ? userInfo.email
    : userInfo.phoneNo;
  var content = isUserNameAvailable
    ? isEmailAvailable
      ? userInfo.email
      : userInfo.phoneNo
    : "";

  return (
    <div
      className={
        customPnlCss
          ? customPnlCss
          : `liDetail m-b-15 d-flex align-items-center ${
              requiresBorder
                ? "p-l-10 m-l-30 border-left-solid border-w-3 border-black-2"
                : "m-r-10 p-r-10"
            }`
      }
    >
      <div className="liDetail-user tblUsr">
        <div className="topUsrAreaPic">
          <div className="topUsrAreaPic-i">
            <div className={`contact-list-icon ${color}`}>
              <div className="contact-list-icon-txt">{userIcon}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="liDetail-data">
        <div
          className={
            customDataHeaderCss
              ? customDataHeaderCss
              : "liDetail-p fs-14 text-black-13"
          }
        >
          {header}
        </div>
        <div
          className={
            customDataBodyCss
              ? customDataBodyCss
              : `liDetail-h font-weight-bold text-black-10 fs-${name ? 16 : 18}`
          }
        >
          {content}
        </div>
      </div>
    </div>
  );
};

export default CustomUserInfo;
