import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Loader } from "@progress/kendo-react-indicators";
import React, { useEffect, useRef, useState } from "react";
import CustomFormTextArea from "../../../../components/custom/form/CustomFormTextArea";
import CustomInput from "../../../../components/custom/form/CustomInput";
import CustomMultiSelect from "../../../../components/custom/form/CustomMultiSelect";
import { Group } from "../../../../types/user-groups/Group";
import { GroupMember } from "../../../../types/user-groups/GroupMember";
import { GroupAdmin } from "../../../../types/user-groups/GroupAdmin";
import { TinyUser } from "../../../../types/user/TinyUser";
import { ListItemProps } from "@progress/kendo-react-dropdowns";
import CustomUserGridInfo from "../../../../components/custom/grid/CustomUserGridInfo";
import userGroupsService from "../../../../services/usergroups.service";
import useMasterData from "../../../../hooks/useMasterData";
import { Dictionary } from "../../../../types/Dictionary";
import useLocale from "../../../../hooks/useLocale";
import useSwal from "../../../../hooks/useSwal";
import LoadingOverlay from "../../../../components/LoadingOverlay";

interface UpsertUserGroupDialogProps {
  data?: Group;
  onCloseDialog: () => void;
  onUserGroupUpserted: (isUpdated: boolean, upsertedUserGroup: Group) => void;
}

const UpsertUserGroupsDialog: React.FC<UpsertUserGroupDialogProps> = ({
  data,
  onCloseDialog,
  onUserGroupUpserted,
}) => {
  const masterData = useMasterData();
  const formRef = useRef<any>();
  const Swal = useSwal();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [buttonStateTracker, setButtonStateTracker] = useState<boolean>(true);

  const localeCtx = useLocale();
  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations(
        "UpsertUserGroupsDialog"
      );
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations[
          "UpsertUserGroupsDialog"
        ]
      );
      localeCtx?.setPreviousAppLocale("UpsertUserGroupsDialog");
      if (localeCtx?.localeSwitchFailed) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        });
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };
  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(
    localeCtx?.selectedLocale?.current.componentTranslations[
      "UpsertUserGroupsDialog"
    ]
  );

  const listNoDataRender = (element: React.ReactElement<HTMLDivElement>) => {
    const noData = (
      <h4 style={{ fontSize: "1em" }}>{`${
        translationsLoading
          ? "No data found"
          : fetchLabelKeyTranslation("NodatafoundMsg", "No data found")
      }`}</h4>
    );
    return React.cloneElement(element, { ...element.props }, noData);
  };

  const nameValidator = (value: string) => {
    if (!value) {
      return " ";
      // return `${
      //   translationsLoading
      //     ? "User group name cannot be empty"
      //     : fetchLabelKeyTranslation(
      //         "NameValidatorEmpty",
      //         "User group name cannot be empty"
      //       )
      // }`;
    }
    if (value.length > 100) {
      return `${
        translationsLoading
          ? "User group name length cannot be more than 100!"
          : fetchLabelKeyTranslation(
              "NameValidatorLength",
              "User group name length cannot be more than 100!"
            )
      }`;
    }
    return "";
  };

  const descriptionValidator = (value: string) => {
    // if (!value) {
    //   return "User group description cannot be empty";
    // }
    if (value.length > 1000) {
      return `${
        translationsLoading
          ? "Description length cannot be more than 1000!"
          : fetchLabelKeyTranslation(
              "DescriptionValidatorLength",
              "Description length cannot be more than 1000!"
            )
      }`;
    }
    return "";
  };

  const multiSelectValidator = (value: TinyUser[]) => {
    if (!value || value.length === 0) {
      return " ";
      // return `${
      //   translationsLoading
      //     ? "Group users cannot be empty"
      //     : fetchLabelKeyTranslation(
      //         "MultiSelectValidator",
      //         "Group users cannot be empty"
      //       )
      // }`;
    }
    return "";
  };

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
        "UpsertUserGroupsDialog"
      ]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  const submitHandler = () => {
    const form = formRef.current as Form;
    const group: Group = {
      id: data?.id ?? 0,
      name: form.values.groupName,
      description: form.values.description,
      groupAdmins: form.values.admins.map((el: TinyUser) => {
        return { id: 0, admin: el } as GroupAdmin;
      }),
      groupMembers: form.values.members.map((el: TinyUser) => {
        return { id: 0, member: el } as GroupMember;
      }),
    };
    if (form.isFormValid(form.errors)) {
      setLoading(true);
      userGroupsService
        .upsertUserGroup(group)
        .then((upsertedGroup) => {
          Swal.fire({
            icon: "success",
            title: `User Group ${
              data
                ? `${
                    translationsLoading
                      ? "Updated"
                      : fetchLabelKeyTranslation("UpdatedMsg", "Updated")
                  }`
                : `${
                    translationsLoading
                      ? "Added"
                      : fetchLabelKeyTranslation("AddedMsg", "Added")
                  }`
            }`,
          });
          onUserGroupUpserted(data ? true : false, upsertedGroup);
          onCloseDialog();
        })
        .catch((err) => {
          if (err.response.data.error === "Name already exists.") {
            setError(
              `${
                translationsLoading
                  ? "User Group with same name already exists."
                  : fetchLabelKeyTranslation(
                      "InvalidNameTitle",
                      "User Group with same name already exists."
                    )
              }`
            );
          } else {
            setError(
              `${
                translationsLoading
                  ? "Something went wrong! Please try again"
                  : fetchLabelKeyTranslation(
                      "SomethingError",
                      "Something went wrong! Please try again"
                    )
              }`
            );
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setError(
        `${
          translationsLoading
            ? "Please check all the fields!"
            : fetchLabelKeyTranslation(
                "CheckError",
                "Please check all the fields!"
              )
        }`
      );
    }
  };

  const compareState = () => {
    const form = formRef.current as Form;

    if (
      data?.name == form.values.groupName &&
      data?.description == form.values.description &&
      compareArrayAdmin(
        data?.groupAdmins ? data?.groupAdmins.slice() : [],
        form.values.admins.slice()
      ) &&
      compareArrayMember(
        data?.groupMembers ? data?.groupMembers.slice() : [],
        form.values.members.slice()
      )
    ) {
      setButtonStateTracker(true);
    } else {
      setButtonStateTracker(false);
    }
  };

  const compareArrayAdmin = (array1: any[], array2: any[]): boolean => {
    array1.sort(function (a, b) {
      if (a.admin.email > b.admin.email) return 1;
      else return -1;
    });

    array2.sort(function (a, b) {
      if (a.email > b.email) return 1;
      else return -1;
    });

    if (array1.length != array2.length) return false;
    else {
      for (let i = 0; i < array1.length; i++) {
        if (array1[i].admin.email != array2[i].email) return false;
      }
      return true;
    }
  };

  const compareArrayMember = (array1: any[], array2: any[]): boolean => {
    array1.sort(function (a, b) {
      if (a.member.email > b.member.email) return 1;
      else return -1;
    });

    array2.sort(function (a, b) {
      if (a.email > b.email) return 1;
      else return -1;
    });

    if (array1.length != array2.length) return false;
    else {
      for (let i = 0; i < array1.length; i++) {
        if (array1[i].member.email != array2[i].email) return false;
      }
      return true;
    }
  };

  const itemRenderUser = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const userInfo: TinyUser = {
      id: itemProps.dataItem.id,
      loginUserId: itemProps.dataItem.loginUserId,
      firstName: itemProps.dataItem.firstName,
      lastName: itemProps.dataItem.lastName,
      email: itemProps.dataItem.email,
      phoneNo: itemProps.dataItem.phoneNo,
      image: itemProps.dataItem.image,
      isArchived: itemProps.dataItem.isArchived,
    };
    const checkedclass = itemProps.selected ? "liChecked" : "";
    const itemChildren = (
      <span className={checkedclass}>
        <CustomUserGridInfo userInfo={userInfo} selected={itemProps.selected} />
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <Form
      ref={formRef}
      initialValues={{
        groupName: data?.name ?? "",
        description: data?.description ?? "",
        admins: data?.groupAdmins ? data.groupAdmins.map((el) => el.admin) : [],
        members: data?.groupMembers
          ? data.groupMembers.map((el) => el.member)
          : [],
      }}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ maxWidth: 650 }}>
          <Dialog
            title={
              data
                ? `${
                    translationsLoading
                      ? "Edit User Group"
                      : fetchLabelKeyTranslation(
                          "EditUserGroupTitle",
                          "Edit User Group"
                        )
                  }`
                : `${
                    translationsLoading
                      ? "Add User Group"
                      : fetchLabelKeyTranslation(
                          "AddUserGroupTitle",
                          "Add User Group"
                        )
                  }`
            }
            onClose={onCloseDialog}
          >
            {loading && (
              <LoadingOverlay
                customStyle={{ position: "fixed", marginTop: "55px" }}
                themeColor={"light"}
                size={"medium"}
              />
            )}
            <div className="formAdd" style={{ marginBottom: "0px" }}>
              {error && <span className="tx-red">{error}</span>}
              <div className="formBoxRow p-t-5 p-b-5">
                <div className="formBoxLabel fs-14">
                  {`${
                    translationsLoading
                      ? "Group Name"
                      : fetchLabelKeyTranslation("GroupNameTitle", "Group Name")
                  }`}{" "}
                  *
                </div>
                <div className="formBoxAction">
                  <div className="formInput">
                    <Field
                      id="groupName"
                      name="groupName"
                      style={{ height: "32px" }}
                      value={formRenderProps.valueGetter("groupName")}
                      placeholder={`${
                        translationsLoading
                          ? "Enter user group name…"
                          : fetchLabelKeyTranslation(
                              "GroupNamePC",
                              "Enter user group name…"
                            )
                      }`}
                      component={CustomInput}
                      validator={nameValidator}
                      onChange={compareState}
                    />
                  </div>
                </div>
              </div>
              <div className="formBoxRow p-t-5 p-b-5">
                <div className="formBoxLabel fs-14">{`${
                  translationsLoading
                    ? "Description"
                    : fetchLabelKeyTranslation(
                        "DescriptionTitle",
                        "Description"
                      )
                }`}</div>
                <div className="formBoxAction">
                  <div className="formInput">
                    <Field
                      id={"description"}
                      name={"description"}
                      style={{
                        minWidth: "250px",
                        height: "auto",
                        minHeight: "70px",
                      }}
                      max={1000}
                      autoSize={true}
                      rows={3}
                      showTextLimitHint={false}
                      placeholder={`${
                        translationsLoading
                          ? "Enter user group description.."
                          : fetchLabelKeyTranslation(
                              "DescriptionPC",
                              "Enter user group description.."
                            )
                      }`}
                      value={formRenderProps.valueGetter("description")}
                      component={CustomFormTextArea}
                      validator={descriptionValidator}
                      onChange={compareState}
                    />
                  </div>
                </div>
              </div>
              <div className="formBoxRow p-t-5 p-b-15">
                <div className="formBoxLabel fs-14">
                  {`${
                    translationsLoading
                      ? "Administrators"
                      : fetchLabelKeyTranslation("AdminTitle", "Administrators")
                  }`}{" "}
                  *
                </div>
                <div className="formBoxAction">
                  <div className="formInput">
                    <Field
                      id="admins"
                      name="admins"
                      placeholder={`${
                        translationsLoading
                          ? "Select group administrator/s..."
                          : fetchLabelKeyTranslation(
                              "AdminPC",
                              "Select group administrator/s..."
                            )
                      }`}
                      listNoDataRender={listNoDataRender}
                      data={masterData?.users.filter((u) => !u.isArchived)}
                      itemRender={itemRenderUser}
                      value={formRenderProps.valueGetter("admins")}
                      component={CustomMultiSelect}
                      validator={multiSelectValidator}
                      textField="email"
                      dataItemKey="id"
                      filtering={true}
                      sorting={true}
                      sortField={"email"}
                      onChange={(e) => {
                        const tempAdmins: GroupAdmin[] = [];
                        e.value.forEach((user: TinyUser) => {
                          const adminFind = data?.groupAdmins.find(
                            (admin: GroupAdmin) =>
                              admin.admin.email === user.email
                          );
                          if (adminFind) {
                            tempAdmins.push({
                              id: adminFind.id,
                              admin: adminFind.admin,
                            });
                          } else {
                            tempAdmins.push({ id: 0, admin: user });
                          }
                        });

                        compareState();
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="formBoxRow p-t-5 p-b-15">
                <div className="formBoxLabel fs-14">
                  {`${
                    translationsLoading
                      ? "Members"
                      : fetchLabelKeyTranslation("MemberTitle", "Members")
                  }`}{" "}
                  *
                </div>
                <div className="formBoxAction">
                  <div className="formInput">
                    <Field
                      id="members"
                      name="members"
                      placeholder={`${
                        translationsLoading
                          ? "Select Group Members…"
                          : fetchLabelKeyTranslation(
                              "MemberPC",
                              "Select Group Members…"
                            )
                      }`}
                      listNoDataRender={listNoDataRender}
                      data={masterData?.users.filter((u) => !u.isArchived)}
                      itemRender={itemRenderUser}
                      value={formRenderProps.valueGetter("members")}
                      component={CustomMultiSelect}
                      validator={multiSelectValidator}
                      textField="email"
                      dataItemKey="id"
                      filtering={true}
                      sorting={true}
                      sortField={"email"}
                      onChange={(e) => {
                        const tempMembers: GroupMember[] = [];
                        e.value.forEach((user: TinyUser) => {
                          const memberFind = data?.groupMembers.find(
                            (member: GroupMember) =>
                              member.member.email === user.email
                          );
                          if (memberFind) {
                            tempMembers.push({
                              id: memberFind.id,
                              member: memberFind.member,
                            });
                          } else {
                            tempMembers.push({ id: 0, member: user });
                          }
                        });

                        compareState();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <DialogActionsBar>
              <Button className={`btn bg-black-5`} onClick={onCloseDialog}>
                {`${
                  translationsLoading
                    ? "Cancel"
                    : fetchLabelKeyTranslation("CancelButton", "Cancel")
                }`}
              </Button>
              <Button
                className={`btn bg-primary text-white ${
                  !formRenderProps.valid || buttonStateTracker
                    ? "disabledBtn"
                    : ""
                }`}
                onClick={submitHandler}
                disabled={!formRenderProps.valid || buttonStateTracker}
              >
                {loading ? (
                  <Loader
                    themeColor={"primary"}
                    size={"small"}
                    type={"infinite-spinner"}
                  />
                ) : (
                  `${
                    translationsLoading
                      ? "Save"
                      : fetchLabelKeyTranslation("SaveButtonText", "Save")
                  }`
                )}
              </Button>
            </DialogActionsBar>
          </Dialog>
        </FormElement>
      )}
    />
  );
};

export default UpsertUserGroupsDialog;
