import { Button } from "@progress/kendo-react-buttons";
import { useContext } from "react";
import { AccessPermissionEnum } from "../../enums/accessPermissionEnum";
import useAuth from "../../hooks/useAuth";
import {
  ScorecardEvaluationDetails,
  ScorecardEvaluationQuestion,
  ShortScorcardType,
} from "../../pages/call-details/RecordingScorecardTab";
import { RecordingScorecard } from "../../types/recording/RecordingScorecard";
import RecordingScorecardGrid from "./RecordingScorecardGrid";
import CustomUserInfo from "../custom/CustomUserInfo";

interface RecordingScorecardEvalutationProps {
  recordingId: number;
  scorecard: ShortScorcardType;
  onShowDialog: () => void;
  isEvaluated: boolean;
  questions?: ScorecardEvaluationQuestion[];
  onEvaluationSave: (e: RecordingScorecard) => void;
  evaluationDetails?: ScorecardEvaluationDetails;
  error?: string;
  shared?: boolean;
  fetchKeyTranslation: (key: string, defaultVal: string) => string;
  translationLoading: boolean;
  edited: boolean;
  setEdited: (e: boolean) => void;
  sendFetchRecordingScorecardRequest: () => void;
  setEvaluating: (e: boolean) => void;
}

const RecordingScorecardEvalutation: React.FC<
  RecordingScorecardEvalutationProps
> = ({
  recordingId,
  scorecard,
  onShowDialog,
  isEvaluated,
  questions,
  onEvaluationSave,
  evaluationDetails,
  error,
  shared,
  fetchKeyTranslation,
  translationLoading,
  edited,
  setEdited,
  sendFetchRecordingScorecardRequest,
  setEvaluating,
}) => {
  const auth = useAuth();
  const scoreColor = evaluationDetails?.score
    ? evaluationDetails.score > 3
      ? "tx-green"
      : evaluationDetails.score > 1.5
      ? "tx-amber"
      : "tx-red"
    : "";

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="gridActionGroup d-flex justify-content-between">
            <div className="gridAction m-b-15">
              <div className="actionLabel">
                <div className="fs-14 p-b-2 text-black-9">{`${
                  translationLoading
                    ? "Evaluation Scorecard Name"
                    : fetchKeyTranslation(
                        "TextSCName",
                        "Evaluation Scorecard Name"
                      )
                }`}</div>
                {scorecard.id !== -1 && (
                  <Button
                    className={`btn btn-link ${
                      !auth?.checkUserAccess(
                        AccessPermissionEnum.EvaluateCalls
                      ) ||
                      (!auth?.accessToken && shared === true)
                        ? "disabledBtn"
                        : ""
                    }`}
                    onClick={onShowDialog}
                    disabled={
                      !auth?.checkUserAccess(
                        AccessPermissionEnum.EvaluateCalls
                      ) ||
                      (!auth?.accessToken && shared === true)
                    }
                  >
                    <span className="fs-16 font-weight-semi">
                      {scorecard.text}
                      <i className="bi bi-pencil"></i>
                    </span>
                  </Button>
                )}
                {scorecard.id === -1 && (
                  <div className="d-flex align-items-center justify-content-center">
                    <span>
                      <i className="bi bi-exclamation-triangle-fill tx-amber"></i>{" "}
                      {"N/A"}
                    </span>
                  </div>
                )}
              </div>
            </div>
            {isEvaluated &&
              evaluationDetails &&
              scorecard.id === evaluationDetails.id && (
                <div className="gridAction m-b-15 text-right d-flex">
                  <div className="actionLabel p-r-10 border-right-solid border-w-3 border-black-2">
                    <div className="fs-14 p-b-2 text-black-9">{`${
                      translationLoading
                        ? ""
                        : fetchKeyTranslation("TextScore", "Score")
                    }`}</div>
                    <div
                      className={`fs-15 m-t-5 font-weight-semi ${scoreColor}`}
                    >
                      {evaluationDetails.score &&
                        ((evaluationDetails.score / 5) * 100).toFixed(2)}
                      %
                    </div>
                  </div>
                  <div className="actionLabel m-l-30 p-r-10 border-right-solid border-w-3 border-black-2">
                    <div className="fs-14 p-b-2 text-black-9">{`${
                      translationLoading
                        ? "Evaluated at"
                        : fetchKeyTranslation("TextEvaluatedAt", "Evaluated at")
                    }`}</div>
                    <div className="fs-15 m-t-5 font-weight-semi">
                      {evaluationDetails.evaluatedAt}
                    </div>
                  </div>
                  <div className="actionLabel m-l-20 p-r-10 border-right-solid border-w-3 border-black-2">
                    <div className="fs-14 p-b-2 text-black-9">{`${
                      translationLoading
                        ? "Evaluated By"
                        : fetchKeyTranslation("TextEvaluatedBy", "Evaluated By")
                    }`}</div>
                    <div className="fs-15 font-weight-semi">
                      {evaluationDetails.evaluatedBy && (
                        <CustomUserInfo
                          userInfo={evaluationDetails.evaluatedBy}
                          customPnlCss={
                            "liDetail d-flex align-items-center p-r-5"
                          }
                          customDataHeaderCss={
                            "liDetail-h d-flex font-semi-bold text-balck-10 fs-14"
                          }
                          customDataBodyCss={
                            "liDetail-p fs-13 text-black-8 line-height-1"
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
      <RecordingScorecardGrid
        scorecard={scorecard}
        recordingId={recordingId}
        questions={questions}
        onEvaluationSave={onEvaluationSave}
        error={error}
        shared={shared}
        fetchKeyTranslation={fetchKeyTranslation}
        translationLoading={translationLoading}
        edited={edited}
        setEdited={setEdited}
        sendFetchRecordingScorecardRequest={sendFetchRecordingScorecardRequest}
        isEvaluated={isEvaluated}
        setEvaluating={setEvaluating}
      />
    </>
  );
};

export default RecordingScorecardEvalutation;
