import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { useContext, useEffect, useState } from "react";
import "./RecordingScorecardTab.scss";
import { RecordingScorecard } from "../../types/recording/RecordingScorecard";
import ScorecardSelectDialog from "../../components/recording-scorecards/ScorecardSelectDialog";
import RecordingScorecardEvalutation from "../../components/recording-scorecards/RecordingScorecardEvaluation";
import { formatUsernameToPascalCase } from "../../utils/profileUtils";
import { formatDateTime } from "../../utils/dateTimeUtils";
import { AccessPermissionEnum } from "../../enums/accessPermissionEnum";
import useAuth from "../../hooks/useAuth";
import recordingsService from "../../services/recordings.service";
import scorecardService from "../../services/scorecard.service";
import useLocale from "../../hooks/useLocale";
import { Dictionary } from "../../types/Dictionary";
import { Scorecard } from "../../types/scorecard";
import useSwal from "../../hooks/useSwal";
import { SweetAlertOptions } from "sweetalert2";
import { TinyUser } from "../../types/user";

export interface ScorecardEvaluationQuestion {
  id: number;
  question: string;
  category: string;
  rating: number;
}

export interface ScorecardEvaluationDetails {
  id?: number;
  score?: number;
  evaluatedBy?: TinyUser;
  evaluatedAt?: string;
}

export type ShortScorcardType = {
  id: number;
  text: string;
  isArchived?: boolean;
  position?: string;
};

interface RecordingScorecardTabProps {
  isCallEvaluated: boolean;
  recordingId: number;
  shared?: boolean;
  onScorecardEvaluated: () => void;
  allocatedScorecards?: Scorecard[];
  baseLicenseId: number;
}

const RecordingScorecardTab: React.FC<RecordingScorecardTabProps> = ({
  isCallEvaluated,
  recordingId,
  shared,
  onScorecardEvaluated,
  allocatedScorecards,
  baseLicenseId
}) => {
  const auth = useAuth();
  const swal = useSwal();
  const localeCtx = useLocale();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const toggleShowDialog = () => {
    setShowDialog(!showDialog);
  };
  const [selectedScorecard, setSelectedScorecard] = useState<ShortScorcardType>(
    { id: -1, text: "--Select--", isArchived: true }
  );
  const [evaluating, setEvaluating] = useState<boolean>(isCallEvaluated);
  const [evalQuestions, setEvalQuestions] = useState<
    ScorecardEvaluationQuestion[] | undefined
  >();
  const [evalDetails, setEvalDetails] = useState<
    ScorecardEvaluationDetails | undefined
  >();
  const [error, setError] = useState<string | undefined>();

  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(
    localeCtx?.selectedLocale?.current.componentTranslations[
      "RecordingScorecard"
    ]
  );
  const [evaluatedScorecardQuestions, setEvaluatedScorecardQuestions] =
    useState<ScorecardEvaluationQuestion[] | undefined>();

  const [edited, setEdited] = useState<boolean>(false);

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations(
        "RecordingScorecard"
      );
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations[
          "RecordingScorecard"
        ]
      );
      localeCtx?.setPreviousAppLocale("RecordingScorecard");
      if (localeCtx?.localeSwitchFailed) {
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        };
        swal.fire(swalOptions);
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
        "RecordingScorecard"
      ]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const sendFetchRecordingScorecardRequest = async () => {
    try {
      const data = await recordingsService.fetchRecordingScorecard(
        recordingId,
        shared === true
      );
      const questions: ScorecardEvaluationQuestion[] = [];
      data.scoreDetails?.forEach((d) => {
        questions.push({
          id: d.question.id!,
          question: d.question.questionText,
          category: d.scorecardGroupName,
          rating: d.questionScore,
        });
      });
      setEvalQuestions(questions);
      setEvaluatedScorecardQuestions(questions);
      setSelectedScorecard({
        id: data.scorecardName.id,
        text: data.scorecardName.name ? data.scorecardName.name : "",
        isArchived: data.scorecardName.isArchived ? true : false,
      });
      setEvaluating(true);
      setEvalDetails({
        id: data.scorecardName.id,
        score: data.score,
        evaluatedBy: data.scoredBy,
        evaluatedAt: data.scoredAt
          ? formatDateTime(data.scoredAt, "MMM DD, YYYY, HH:mm")
          : "",
      });
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      }
    }
  };

  const sendFetchScorecardRequest = (id: number) => {
    scorecardService
      .fetchScorecardById(id, shared === true)
      .then((data) => {
        const questions: ScorecardEvaluationQuestion[] = [];
        data.questionGroups?.forEach((g) => {
          g.questions.forEach((q) =>
            questions.push({
              id: q.id!,
              question: q.questionText,
              category: g.scorecardQuestionGroupName,
              rating: 0,
            })
          );
        });
        setEvalQuestions(questions);
      })
      .catch((err) => {
        if (err instanceof Error) {
          setError(err.message);
        }
      });
  };

  useEffect(() => {
    if (isCallEvaluated) {
      sendFetchRecordingScorecardRequest();
    }
  }, [isCallEvaluated]);

  const selectScorecardHandler = (scorecard: ShortScorcardType) => {
    if (scorecard.id !== -1) {
      setSelectedScorecard(scorecard);
      setEvaluating(true);
      if (evalDetails && scorecard.id === evalDetails.id) {
        setEvalQuestions(evaluatedScorecardQuestions);
        setEdited(false);
      } else {
        sendFetchScorecardRequest(scorecard.id);
        setEdited(true);
      }
    } else {
      setEvaluating(false);
    }
    setShowDialog(!showDialog);
  };

  const onCloseDialog = () => {
    setShowDialog(false);
  };

  const onShowDialog = () => {
    setShowDialog(true);
  };

  const onEvaluatationSave = (scorecard: RecordingScorecard) => {
    const questions: ScorecardEvaluationQuestion[] = [];
    scorecard.scoreDetails?.forEach((d) => {
      questions.push({
        id: d.question.id!,
        question: d.question.questionText,
        category: d.scorecardGroupName,
        rating: d.questionScore,
      });
    });
    setEvalQuestions(questions);
    setEvaluatedScorecardQuestions(questions);
    setEvaluating(true);
    setEvalDetails({
      id: scorecard.scorecardName.id,
      score: Number(scorecard.score.toPrecision(4)),
      evaluatedBy: scorecard.scoredBy,
      evaluatedAt: scorecard.scoredAt
        ? formatDateTime(scorecard.scoredAt, "MMM DD, YYYY, HH:mm")
        : "",
    });
    onScorecardEvaluated();
  };

  return (
    <>
      {showDialog && (
        <ScorecardSelectDialog
          selectedScorecard={selectedScorecard}
          onCloseDialog={onCloseDialog}
          onScorecardSelect={selectScorecardHandler}
          fetchKeyTranslation={fetchLabelKeyTranslation}
          translationLoading={translationsLoading}
          allocatedScorecards={allocatedScorecards}
          baseLicenseId={baseLicenseId}
        />
      )}
      {!evaluating && (
        <div className="row">
          <div className="col-md-12">
            <div className="selectScorecard">
              <div className="selectScorecardSelect">
                <Button
                  className={`btn bg-primary line-height-1 text-white p-r-1 ${
                    !auth?.checkUserAccess(
                      AccessPermissionEnum.EvaluateCalls
                    ) ||
                    (!auth?.accessToken && shared === true)
                      ? "disabledBtn"
                      : ""
                  }`}
                  onClick={toggleShowDialog}
                  style={{ height: "40px", width: "200px" }}
                  disabled={
                    !auth?.checkUserAccess(
                      AccessPermissionEnum.EvaluateCalls
                    ) ||
                    (!auth?.accessToken && shared === true)
                  }
                >
                  {`${
                    translationsLoading
                      ? "Evaluate"
                      : fetchLabelKeyTranslation("TextEvaluate", "Evaluate")
                  }`}
                  <i
                    className="bi bi-plus fs-24"
                    title={`${
                      translationsLoading
                        ? "Evaluate"
                        : fetchLabelKeyTranslation("TextEvaluate", "Evaluate")
                    }`}
                  ></i>
                </Button>
                <span className="text-black-9 text-center p-10">
                  {`${
                    translationsLoading
                      ? "Please click on the Evaluate button to select the scorecard"
                      : fetchLabelKeyTranslation(
                          "StartEvalMsg",
                          "Please click on the Evaluate button to select the scorecard"
                        )
                  }`}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {evaluating && (
        <RecordingScorecardEvalutation
          scorecard={selectedScorecard}
          onShowDialog={onShowDialog}
          isEvaluated={isCallEvaluated}
          questions={evalQuestions}
          onEvaluationSave={onEvaluatationSave}
          recordingId={recordingId}
          evaluationDetails={evalDetails}
          error={error}
          shared={shared}
          fetchKeyTranslation={fetchLabelKeyTranslation}
          translationLoading={translationsLoading}
          edited={edited}
          setEdited={setEdited}
          sendFetchRecordingScorecardRequest={
            sendFetchRecordingScorecardRequest
          }
          setEvaluating={setEvaluating}
        />
      )}
    </>
  );
};

export default React.memo(RecordingScorecardTab);
